import { ActionType } from "typesafe-actions";
import { epochTime } from '../../utils/date'
import { actionTypes } from '../actions'
import {CategoryMap} from "../../types/models/category";
import * as categoryActionFactories from '../actions/category/factories';

export interface CategoryState {
	data: CategoryMap | null;
	updatedAt: number;
	fetchCategoriesError: string | null;
}

type CategoryActions = ActionType<typeof categoryActionFactories>;

const defaultState: CategoryState = {
	data: null,
	updatedAt: 0,
	fetchCategoriesError: null
}

function categoryReducer(state: CategoryState = defaultState, action: CategoryActions) {
	switch (action.type) {
		case actionTypes.category.SET_CATEGORIES:
			return Object.assign({}, state, {
				data: action.payload,
				updatedAt: epochTime()
			})

		case actionTypes.category.SET_FETCH_CATEGORIES_ERROR:
			return Object.assign({}, state, {
				fetchCategoriesError: action.payload,
			})
		default:
			return state
	}
}

export default categoryReducer