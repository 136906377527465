import {createStore, applyMiddleware, Middleware} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {createWrapper} from 'next-redux-wrapper';
import {makeReducer} from './reducers/reducers'
import rootSaga from './sagas'

const bindMiddleware = (middlewares: Middleware[]) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middlewares))
  }
  return applyMiddleware(...middlewares)
}

type StoreType = ReturnType<typeof makeStore>;
let publicStore: StoreType;

const makeStore = () => {
	const sagaMiddleware = createSagaMiddleware()
	const reducers = makeReducer();
	const store = createStore(
		reducers, 
		bindMiddleware([sagaMiddleware])
	)
	sagaMiddleware.run(rootSaga)
	publicStore = store
	return store
}

export const wrapper = createWrapper(makeStore, {
	debug: false
});

export const getStore = () => publicStore