import '../styles/global.scss'

import React from 'react'
import { useStore } from 'react-redux'
import countries from 'i18n-iso-countries'
import {AppProps} from "next/app";
import {useRouter} from "next/router";
import {Montserrat} from 'next/font/google';
import { wrapper } from '../store/store'
import { actions } from '../store/actions'
import * as fbq from '../lib/fpixel'
import * as gtag from '../lib/gtag'

countries.registerLocale(require("i18n-iso-countries/langs/en.json"))
const montserrat = Montserrat({
  subsets: ["latin"]
});

function App({ Component, pageProps }: AppProps) {
  const store = useStore()
  const router = useRouter();

  React.useEffect(() => {
  	store.dispatch(actions.user.fetchMe())
    store.dispatch(actions.config.fetchAll())
  }, [])

  React.useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = (url: string) => {
      if (fbq.FB_PIXEL_ID) {
        fbq.pageview()
      }

      if (gtag.GA_TRACKING_ID) {
        gtag.pageview(url)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  
  return <main className={montserrat.className}>
    <Component {...pageProps} />
  </main>;
}


export default wrapper.withRedux(App)