import {
  authEndpoint,
  authHeadersEndpoint,
  serverlessEndpoint,
  serviceEndpoint,
  serviceHeadersEndpoint,
} from './axios_provider'
import {
  GetProductCategoriesResponse, GetProductFlagsResponse,
  GetRecentlyViewedResponse, ProductSearchExternalResponse,
  SearchProductsResponse
} from "../types/api/responses/product";
import {CartItemUpdate} from "../types/models/cart_item";
import {CreateCartItem} from "../types/api/requests/cart_item";
import {UserAddressUpdate} from "../types/models/user_address";
import {UserProfileUpdate} from "../types/models/user";
import {
  GetOrderDisputeInfoResponse,
  GetOrderDisputeInfoV1Response,
  GetOrderMessagesResponse, GetOrderPaymentInfoResponse,
  GetOrderResponse, UpdateOrderChatStatusResponse
} from "../types/api/responses/order";
import {GetPopupResponse} from "../types/api/responses/popup";
import {GetAllNotificationResponse} from "../types/api/responses/notification";
import {
  GetAllRegionsResponse,
  GetDefaultRegionResponse,
  GetEnabledRegionsResponse, GetPreferredRegionResponse
} from "../types/api/responses/region";
import {GetAllConfigResponse} from "../types/api/responses/config";
import {GetAllWishlist} from "../types/api/responses/wishlist";
import {GetAllMerchantResponse} from "../types/api/responses/merchant";
import {AddCartItemResponse, GetCartItemsResponse} from "../types/api/responses/cart_item";
import {MeResponse, UpdateProfileResponse} from "../types/api/responses/auth";
import {
  GetHomeProductGroupsResponse,
  GetHomeProductsByCategoryResponse
} from "../types/api/responses/home_product_group";
import {GetUsernamesResponse} from "../types/api/responses/user";
import {
  CreateExternalQuotationItemResponse,
  CreateInternalQuotationItemResponse, GetExternalQuotationItemsResponse,
  GetQuotationItemsResponse
} from "../types/api/responses/quotation_item";
import {QuotationItemUpdate} from "../types/models/quotation_item";
import {
  CreateCheckoutSessionResponse,
  EstimateCostsResponse,
  GetCheckoutSessionResponse
} from "../types/api/responses/checkout";
import {CreateCartItemFromProduct, CreateExternalQuotationRequestBody} from "../types/api/requests/quotation_item";
import {GetAllBannerResponse} from "../types/api/responses/banner";
import {PayCardRequestBody} from "../types/api/requests/stripe";
import {
  DeleteCardPaymentMethodsResponse,
  GetCardPaymentMethodsResponse,
  PayCardResponse
} from "../types/api/responses/stripe";
import {ProductSearchExternalRequestBody} from "../types/api/requests/product";

export default {
  contact: {
    submit(recaptchaToken: string, email: string, name: string, phoneNumber: string, message: string) {
      return serverlessEndpoint.post('/contact_us', {
        email,
        name,
        phoneNumber,
        message,
        recaptchaToken,
      })
    },
  },
  product: {
    searchExternal(link: string) {
      const data: ProductSearchExternalRequestBody = {
        link
      };
      return serviceEndpoint.post<ProductSearchExternalResponse>('/product/searchExternal', data);
    },
    checkPending(link: string) {
      return serviceEndpoint.get('/product/checkPending', {
        params: {
          link,
        }
      })
    },
    search(params: URLSearchParams) {
      return serviceEndpoint.get<SearchProductsResponse>('/product/search', {
        params,
      })
    },
    one(productKey: string) {
      return serviceEndpoint.get('/product/one/' + productKey)
    },
    getProductFlags(paramName: string) {
      return serviceEndpoint.get<GetProductFlagsResponse>(`/product/${paramName}/flags`);
    },
    categories() {
      return serviceEndpoint.get<GetProductCategoriesResponse>('/product/categories')
    },
    recentlyViewed() {
      return serviceEndpoint.get<GetRecentlyViewedResponse>('/product/recently_viewed')
    },

  },
  auth: {
    ping() {
      return authEndpoint.get('/ping')
    },
    login(email: string, password: string, rememberme: boolean) {
      return authEndpoint.post('/auth/login', {
        email,
        password,
        rememberme,
      })
    },
    forgotPassword(email: string) {
      return authEndpoint.post('/auth/forgotPassword', {
        email,
      })
    },
    resetPassword(uid: string, resetPasswordToken: string, password: string) {
      return authEndpoint.post('/auth/resetPassword', {
        uid,
        resetPasswordToken,
        password
      })
    },
    register(email: string, password: string, firstName: string, lastName: string, phoneNumber: string, country: string | null, birthDate: number | null, gender: 'M' | 'F' | null, acceptTerms: boolean) {
      return authEndpoint.post('/auth/register', {
        email,
        password,
        firstName,
        lastName,
        phoneNumber,
        country,
        birthDate,
        gender,
        acceptTerms,
      })
    },
    verifyEmail(code: string) {
      return authEndpoint.post('/auth/verifyEmail', {
        code,
      })
    },
    signOut() {
      return authHeadersEndpoint.delete('/auth/logout')
    },
    refreshAccessToken() {
      return authEndpoint.post('/auth/refreshAccessToken')
    },
    changePassword(currentPassword: string, newPassword: string) {
      return authHeadersEndpoint.post('/auth/changePassword', {
        currentPassword,
        newPassword
      })
    },
    getMe() {
      return authEndpoint.get<MeResponse>('/auth/me')
    },
    resendVerifyCode(email: string) {
      return authEndpoint.post('/auth/resendVerifyCode', {
        email
      })
    }
  },
  tfa: {
    requestReset() {
      return authHeadersEndpoint.post('/tfa/requestReset')
    },
    reset(resetTfaToken: string) {
      return authHeadersEndpoint.post('/tfa/reset', {
        resetTfaToken,
      })
    },
    setup() {
      return authHeadersEndpoint.post('/tfa/setup')
    },
    verify(token: string) {
      return authHeadersEndpoint.post('/tfa/verify', {
        token,
      })
    },
    skipSetup() {
      return authHeadersEndpoint.post('/tfa/skip_setup')
    },
    isTfaEnabled() {
      return authHeadersEndpoint.get('/tfa/is_enabled')
    },
    disableTfa(token: string) {
      return authHeadersEndpoint.post('/tfa/disable_tfa', {
        token,
      })
    },
  },
  quotation: {
    requestRemote(productUrl: string, productName: string, productQuantity: string | number, instructions: string, imageUrl: string) {
      return serviceHeadersEndpoint.post('/quotation/request', {
        from: 'r',
        productUrl,
        productName,
        productQuantity,
        instructions,
        imageUrl,
      })
    },
    requestLocal(productRef: string, variantPath: string, productQuantity: string) {
      return serviceHeadersEndpoint.post('/quotation/request', {
        from: 'l',
        productRef,
        variantPath,
        productQuantity,
      })
    },
    getPageOgp(url: string) {
      return serverlessEndpoint.post('opengraph', {
        url,
      })
    },
    getAll(filter: any) {
      return serviceHeadersEndpoint.get('/quotation', {
        params: {
          filter,
        }
      })
    }
  },
  cart: {
    get() {
      return serviceHeadersEndpoint.get<GetCartItemsResponse>('/cart')
    },
    delete(id: string) {
      return serviceHeadersEndpoint.delete(`/cart/${id}`)
    },
    put(id: string, update: CartItemUpdate) {
      return serviceHeadersEndpoint.put(`/cart/${id}`, update)
    },
    post(cartItem: CreateCartItem) {
      return serviceHeadersEndpoint.post<AddCartItemResponse>('/cart', cartItem)
    },
  },
  user: {
    updateProfile(profile: UserProfileUpdate) {
      return authHeadersEndpoint.post<UpdateProfileResponse>('/user/profile', profile)
    },
    getNames(userIds: string[]) {
      return authHeadersEndpoint.post<GetUsernamesResponse>('/user/names', {
        userIds
      });
    }
  },
  config: {
    getAll() {
      return serviceEndpoint.get<GetAllConfigResponse>('/config')
    },
  },
  checkout: {
    estimateCosts(checkoutSessionKey: string, voucherCode: string | null, paymentMethod: string, shippingAddressId: string, regionCountryCode: string) {
      return serviceHeadersEndpoint.get<EstimateCostsResponse>('/checkout/estimate_costs', {
        params: {
          checkoutSessionKey,
          voucherCode,
          paymentMethod,
          shippingAddressId,
          regionCountryCode
        }
      })
    },
    createOrder(checkoutSessionKey: string, voucherCode: string | null, paymentMethod: string, notes: string, billingAddressId: string, shippingAddressId: string, isDispute: boolean, regionCountryCode: string) {
      return serviceHeadersEndpoint.post('/checkout/create_order', {
        checkoutSessionKey,
        voucherCode,
        paymentMethod,
        notes,
        billingAddressId,
        shippingAddressId,
        isDispute,
        regionCountryCode
      })
    },
    estimateQuotationCosts(quotationIds: string[], voucherCode: string, paymentMethod: string) {
      return serviceHeadersEndpoint.get('/quotation_checkout/estimate_costs', {
        params: {
          quotationIds,
          paymentMethod,
          voucherCode,
        }
      })
    },
    createQuotationOrder(quotationIds: string[], voucherCode: string, paymentMethod: string, notes: string, billingAddressId: string, shippingAddressId: string) {
      return serviceHeadersEndpoint.post('/quotation_checkout/create_order', {
        quotationIds,
        voucherCode,
        paymentMethod,
        notes,
        billingAddressId,
        shippingAddressId,
      })
    },
    createSession(cartItemIds: string[], quotationItemIds: string[]) {
      return serviceHeadersEndpoint.post<CreateCheckoutSessionResponse>('/checkout/session', {
        cartItemIds,
        quotationItemIds
      });
    },
    getSession(key: string) {
      return serviceHeadersEndpoint.get<GetCheckoutSessionResponse>('/checkout/session/' + key)
    },
    deleteSession(key: string) {
      return serviceHeadersEndpoint.get('/checkout/session/' + key)
    }
  },
  voucher: {
    validate(code: string) {
      return serviceHeadersEndpoint.post('/voucher/validate', {
        code,
      })
    }
  },
  quotationOrder: {
    getAll() {
      return serviceHeadersEndpoint.get('/quotation_order')
    },
    get(orderRef: string) {
      return serviceHeadersEndpoint.get('/quotation_order/' + orderRef)
    },
  },
  order: {
    getAll() {
      return serviceHeadersEndpoint.get('/order')
    },
    get(orderRef: string) {
      return serviceHeadersEndpoint.get<GetOrderResponse>('/order/' + orderRef)
    },
    getDisputeInfo(orderRef: string) {
      return serviceHeadersEndpoint.get<GetOrderDisputeInfoResponse>(`/order/${orderRef}/dispute_info`);
    },
    getDisputeInfoV1(orderRef: string) {
      return serviceHeadersEndpoint.get<GetOrderDisputeInfoV1Response>(`/order/${orderRef}/dispute_info/v1`);
    },
    cancel(orderRef: string) {
      return serviceHeadersEndpoint.delete('/order/' + orderRef)
    },
    getMessages(orderRef: string, after?: string) {
      return serviceHeadersEndpoint.get<GetOrderMessagesResponse>(`/order/${orderRef}/messages`, {
        params: {
          after,
        }
      })
    },
    sendMessage(orderRef: string, message: string) {
      return serviceHeadersEndpoint.post(`/order/${orderRef}/messages`, {
        message,
      })
    },
    confirmReceipt(orderRef: string) {
      return serviceHeadersEndpoint.post(`/order/${orderRef}/confirm_receipt`)
    },
    paymentInfo(orderRef: string) {
      return serviceHeadersEndpoint.post<GetOrderPaymentInfoResponse>(`/order/${orderRef}/payment_info`)
    },
    paynowOrderPaid(orderRef: string) {
      return serviceHeadersEndpoint.post(`/order/${orderRef}/paynow_paid`)
    },
    updateChatStatus(orderRef: string) {
      return serviceHeadersEndpoint.post<UpdateOrderChatStatusResponse>(`/order/${orderRef}/update_chat_status`);
    }
  },
  popup: {
    get() {
      return serviceHeadersEndpoint.get<GetPopupResponse>('/popup');
    }
  },
  imagePage: {
    get(pagePath: string) {
      return serviceHeadersEndpoint.get('/image_page/' + pagePath)
    }
  },
  wishlist: {
    toggle(productId: string) {
      return serviceHeadersEndpoint.post(`/wishlist`, {
        productId
      })
    },
    getAll() {
      return serviceHeadersEndpoint.get<GetAllWishlist>('/wishlist')
    },
    check(productId: string) {
      return serviceHeadersEndpoint.get('/wishlist/' + productId)
    }
  },
  notifications: {
    getAll(collapsed = true, limit = 5, beforeTime?: number) {
      return serviceHeadersEndpoint.get<GetAllNotificationResponse>('/notification', {
        params: {
          collasped: (collapsed ? '1' : undefined),
          limit,
          beforeTime,
        }
      })
    },
  },
  homeProductGroups: {
    getAll() {
      return serviceEndpoint.get<GetHomeProductGroupsResponse>('/home_product_group')
    },
    getHomepageProductsByCategory(categoryId: number | string, limit: number) {
      return serviceEndpoint.get<GetHomeProductsByCategoryResponse>('/home_product_group/by_category', {
        params: {
          categoryId,
          limit,
        },
      })
    },
  },
  banner: {
    getAll() {
      return serviceEndpoint.get<GetAllBannerResponse>('/banner')
    }
  },
  merchant: {
    getAll() {
      return serviceEndpoint.get<GetAllMerchantResponse>('/merchant')
    },
    get(paramName: string) {
      return serviceEndpoint.get(`/merchant/${paramName}`)
    },
  },
  shippingLocation: {
    getEnabled() {
      return serviceEndpoint.get('/shipping_location/enabled')
    },
  },
  address: {
    getAddresses() {
      return serviceHeadersEndpoint.get('/address')
    },
    addAddress(address: UserAddressUpdate) {
      return serviceHeadersEndpoint.post('/address', address)
    },
    updateAddress(addressId: string, address: UserAddressUpdate) {
      return serviceHeadersEndpoint.put('/address/' + addressId, address)
    },
    removeAddress(addressId: string) {
      return serviceHeadersEndpoint.delete('/address/' + addressId)
    },
    setMainAddress(addressId: string) {
      return serviceHeadersEndpoint.post(`/address/${addressId}/main`)
    }
  },
  region: {
    getAll() {
      return serviceHeadersEndpoint.get<GetAllRegionsResponse>('/region')
    },
    default() {
      return serviceHeadersEndpoint.get<GetDefaultRegionResponse>('/region/default')
    },
    getEnabled() {
      return serviceEndpoint.get<GetEnabledRegionsResponse>('/region/enabled')
    },
    getPreferred() {
      return serviceHeadersEndpoint.get<GetPreferredRegionResponse>('/region/preferred')
    },
    setPreferred(countryCode: string) {
      return serviceHeadersEndpoint.post('/region/prefer', {
        countryCode
      })
    }
  },
  quotationItem: {
    getAll() {
      return serviceHeadersEndpoint.get<GetQuotationItemsResponse>('/quotation_item');
    },
    getAllExternal() {
      return serviceHeadersEndpoint.get<GetExternalQuotationItemsResponse>('/quotation_item/external');
    },
    createInternal(cartItemId: string) {
      return serviceHeadersEndpoint.post<CreateInternalQuotationItemResponse>('/quotation_item/internal', {
        cartItemId
      });
    },
    update(id: string, changes: QuotationItemUpdate) {
      return serviceHeadersEndpoint.put(`/quotation_item/${id}`, changes);
    },
    delete(id: string) {
      return serviceHeadersEndpoint.delete(`/quotation_item/${id}`);
    },
    createInternalFromProduct(body: CreateCartItemFromProduct) {
      return serviceHeadersEndpoint.post<CreateInternalQuotationItemResponse>('/quotation_item/internal_product', body);
    },
    createExternal(body: CreateExternalQuotationRequestBody) {
      return serviceHeadersEndpoint.post<CreateExternalQuotationItemResponse>('/quotation_item/external', body);
    }
  },
  payment: {
    stripeCard: {
      pay(body: PayCardRequestBody) {
        return serviceHeadersEndpoint.post<PayCardResponse>('/payment/stripe/card/pay', body);
      },
      getPaymentMethods() {
        return serviceHeadersEndpoint.get<GetCardPaymentMethodsResponse>('/payment/stripe/card/payment_methods');
      },
      deletePaymentMethod(paymentMethodId: string) {
        return serviceHeadersEndpoint.delete<DeleteCardPaymentMethodsResponse>('/payment/stripe/card/payment_methods/' + paymentMethodId);
      }
    }
  }
}