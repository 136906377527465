export function countItems<T>(arr: T[], value: T): number {
	let count = 0
	for (const item of arr) {
		if (item === value) {
			count++
		}
	}
	return count
}

export function oneOrManyAsArray<T>(oneOrMany: T[] | T): T[] {
	if (Array.isArray(oneOrMany)) {
		return oneOrMany;
	} else {
		return [oneOrMany];
	}
}