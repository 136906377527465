import {action} from "typesafe-actions";
import * as categoryActionTypes from './types';
import {CategoryMap} from "../../../types/models/category";

export function loadCategoriesAsync() {
  return action(categoryActionTypes.FETCH_CATEGORIES, null)
}

export function setCategories(categories: CategoryMap) {
  return action(categoryActionTypes.SET_CATEGORIES, categories)
}

export function setFetchCategoriesError(error: string) {
  return action(categoryActionTypes.SET_FETCH_CATEGORIES_ERROR, error)
}