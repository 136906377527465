import moment from 'moment'
import { DATE_FORMAT, DATETIME_FORMAT } from '../constants/date'


export function epochTime(): number {
	return Math.floor(Date.now() / 1000)
}

export function hasSecondsPassed(timePeriod: number, refSeconds: number) {
	const currentSeconds = epochTime()
	return currentSeconds - refSeconds >= timePeriod
}

export function formatDateFromSeconds(seconds: number) {
	return moment.unix(seconds).format(DATE_FORMAT)
}

export function formatDateTimeFromSeconds(seconds: number) {
	return moment.unix(seconds).format(DATETIME_FORMAT)
}

export function serializeDate(date: Date) {
	let serializedDate = 0
	serializedDate += date.getFullYear() * 10000
	serializedDate += (date.getMonth() + 1) * 100
	serializedDate += date.getDate()

	return serializedDate
}