import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Api from './api';
import { actions } from '../store/actions';
import {CategoryMap} from "../types/models/category";
import {RootState} from "../store/reducers/reducers";

export function useCategories() {
	const dispatch = useDispatch();
	const categories = useSelector((state: RootState) => state.category.data);

	React.useEffect(() => {
		const categoriesLoaded = !!categories;
		if (!categoriesLoaded) {
			dispatch(actions.category.loadCategoriesAsync());
		}
	}, [categories]);

	return categories;
}

export async function fetchCategories() {
	const response = await Api.product.categories();
	return response.data;
}

export interface WrappedCategory {
	name: string;
	children: WrappedCategoryMap;
	id: number;
	path: string;
	parent: WrappedCategory;
}

export interface WrappedCategoryMap {
	[categoryName: string]: WrappedCategory;
}

export class CategoriesWrapper {
	categories: WrappedCategoryMap;
	categoryPathMap: Record<string, WrappedCategory> = {};
	categoryIdMap: Record<number, WrappedCategory> = {};

	constructor(categories: CategoryMap) {
		this.categories = cloneDeep(categories) as WrappedCategoryMap;
		this.categoryPathMap = {};
		this.categoryIdMap = {};
		this._initialize();
	}

	_initialize(currentNode=this.categories, pathParts: string[] = []) {
		for (const [key, value] of Object.entries(currentNode)) {
			const currentPathParts = pathParts.concat(key);
			const currentPath = currentPathParts.join('.');

			value.path = currentPath;
			this.categoryPathMap[currentPath] = value;
			this.categoryIdMap[value.id] = value;
			if (pathParts.length > 0) {
				const parentPath = pathParts.join('.');
				value.parent = this.categoryPathMap[parentPath];
			}
			this._initialize(value.children, currentPathParts);
		}
	}

	getCategoryByPath(path: string) {
		return this.categoryPathMap[path];
	}

	getCategoryById(id: number) {
		return this.categoryIdMap[id];
	}

	getParentByPath(path: string) {
		const category = this.getCategoryByPath(path);
		if (!category) {
			return;
		}
		return category.parent;
	}

	getChildCategories(path: string) {
		if (path.length === 0) {
			return Object.values(this.categories);
		}

		const category = this.getCategoryByPath(path);
		if (!category) {
			return [];
		}

		return Object.values(category.children);
	}

	getCategoryParents(categoryId: number): WrappedCategory[] {
		let category = this.getCategoryById(categoryId);
		const parents: WrappedCategory[] = [];

		while (category) {
			parents.push(category.parent);
			category = category.parent;
		}

		return parents;
	}
}

export function useCategoriesWrapper() {
	const [wrapper, setWrapper] = React.useState<CategoriesWrapper | undefined>();
	const categories = useCategories();

	React.useEffect(() => {
		if (categories) {
			setWrapper(new CategoriesWrapper(categories));
		}
	}, [categories]);

	return wrapper;
}