import {createDinero} from "../service/currency_converter";
import {CartItem, CartItemWithProductForOrderV1} from "../types/models/cart_item";
import {Product} from "../types/models/product";
import Dinero from "dinero.js";
import {CartAndQuotationItemWrapper} from "../hooks/use_cart_and_quotation_items";
import {property} from "lodash";

function checkHasVariant(product: Pick<Product, 'variantPaths'>, variantPath?: string) {
  if (!variantPath) {
    return false;
  }

  const hasVariant = !!variantPath;
  const variantPathInfo = product.variantPaths[variantPath]
  return hasVariant && !!variantPathInfo;
}

export function getCartItemPricePerUnit(item: CartItem, product: Pick<Product, 'variantPaths' | 'priceCurrency' | 'price'>, countryCode: string) {
  const hasVariant = checkHasVariant(product, item.variantPath);

  if (hasVariant) {
    const variantPathInfo = product.variantPaths[item.variantPath]
    return Dinero({
      currency: product.priceCurrency as Dinero.Currency,
      amount: variantPathInfo.price[countryCode]
    })
  } else {
    return Dinero({
      currency: product.priceCurrency as Dinero.Currency,
      amount: product.price[countryCode]
    })
  }
}

export function getCartItemPrice(item: CartItem, product: Pick<Product, 'variantPaths' | 'priceCurrency' | 'price'>, countryCode: string) {
  return getCartItemPricePerUnit(item, product, countryCode).multiply(item.quantity);
}

export function getCartItemPricePerUnitV1(item: CartItemWithProductForOrderV1) {
  let hasVariant = item.variantPath !== ''
  const variantPathInfo = item.product.variantPaths[item.variantPath]
  hasVariant = hasVariant && !!variantPathInfo

  let productTotalAmount
  if (hasVariant) {
    productTotalAmount = createDinero(item.product.priceCurrency, variantPathInfo.price)
  } else {
    productTotalAmount = createDinero(item.product.priceCurrency, item.product.price)
  }

  return productTotalAmount;
}

export function getCartItemPriceV1(item: CartItemWithProductForOrderV1) {
  return getCartItemPricePerUnitV1(item).multiply(item.quantity);
}

export function findCartItemIndexById(cartItems: CartItem[], id: string) {
  return cartItems.findIndex(ci => ci._id === id);
}

export function findCartItemById(cartItems: CartItem[], id: string) {
  const index = findCartItemIndexById(cartItems, id);
  if (index === -1) {
    return undefined;
  }

  return cartItems[index];
}

export class ChecklistForCartOrQuotationItems {
  checkedCartItemIds: Set<string> = new Set();
  checkedQuotationItemIds: Set<string> = new Set();

  allChecked = false;

  setItems(items: CartAndQuotationItemWrapper[]) {
    let dirty = false;

    const clone = this._clone();
    const quotationItemIds = new Set<string>(items.filter(item => item.type !== 'cart').map(property('item._id')));
    const cartItemIds = new Set<string>(items.filter(item => item.type === 'cart').map(property('item._id')));

    for (const itemId of clone.checkedQuotationItemIds) {
      if (!quotationItemIds.has(itemId)) {
        clone.checkedQuotationItemIds.delete(itemId);
        dirty = true;
      }
    }

    for (const itemId of clone.checkedCartItemIds) {
      if (!cartItemIds.has(itemId)) {
        clone.checkedCartItemIds.delete(itemId);
        dirty = true;
      }
    }

    if (dirty) {
      return clone;
    }

    return this;
  }

  setCheck(isQuotation: boolean, itemId: string, checked: boolean) {
    if (checked) {
      return this.check(isQuotation, itemId);
    } else {
      return this.unCheck(isQuotation, itemId);
    }
  }

  check(isQuotation: boolean, itemId: string) {
    const clone = this._clone();
    const checkState = clone._getCheckState(isQuotation);
    checkState.add(itemId);
    return clone;
  }

  unCheck(isQuotation: boolean, itemId: string) {
    const clone = this._clone();
    const checkState = clone._getCheckState(isQuotation);
    checkState.delete(itemId);
    return clone;
  }

  getCheck(isQuotation: boolean, itemId: string) {
    const checkState = this._getCheckState(isQuotation);
    return checkState.has(itemId);
  }

  checkedCount() {
    return this.checkedCartItemIds.size + this.checkedQuotationItemIds.size;
  }

  _getCheckState(isQuotation: boolean) {
    if (isQuotation) {
      return this.checkedQuotationItemIds;
    } else {
      return this.checkedCartItemIds;
    }
  }

  _clone() {
    const clone = new ChecklistForCartOrQuotationItems();
    clone.checkedCartItemIds = new Set(this.checkedCartItemIds);
    clone.checkedQuotationItemIds = new Set(this.checkedQuotationItemIds);
    clone.allChecked = this.allChecked;

    return clone;
  }
}
