import {all, call, fork, put, select, take, takeLatest} from 'redux-saga/effects'
import Router from 'next/router'
import {fetchCategories} from '../service/category'
import {actions, actionTypes} from './actions'
import Api from '../service/api'
import {sagaGenericErrorHandling} from '../utils/error'
import {extractProperties} from '../utils/object'
import {CategoryMap} from "../types/models/category";
import {RootState} from "./reducers/reducers";
import {CartItemWithProductForCart} from "../types/models/cart_item";
import {AddCartItemResponse, GetCartItemsResponse} from "../types/api/responses/cart_item";
import {AxiosResponse} from "axios";
import {MeResponse, UpdateProfileResponse} from "../types/api/responses/auth";
import {GetAllConfigResponse} from "../types/api/responses/config";
import {GetDefaultRegionResponse, GetPreferredRegionResponse} from "../types/api/responses/region";
import {findCartItemById, findCartItemIndexById} from "../lib/cart_item";
import {CreateInternalQuotationItemResponse, GetQuotationItemsResponse} from "../types/api/responses/quotation_item";
import {QuotationItem} from "../types/models/quotation_item";
import {findQuotationItemById} from "../lib/quotation_item";
import {keyBy} from "lodash";
import * as gtag from "../lib/gtag";
import {clear as clearLoginRedirectPath} from "../service/post_login_redirect_path";

function* loadCategories() {
  try {
    const categories: CategoryMap = yield call(fetchCategories)
    yield put(actions.category.setCategories(categories))
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.category.setFetchCategoriesError)
  }
}

function* watchLoadCategories() {
  yield takeLatest(actionTypes.category.FETCH_CATEGORIES, loadCategories);
}

function *_addToCart(action: AddToCartAction | BuyNowAction, goToCart: boolean) {
  const {payload} = action
  const cart: CartItemWithProductForCart[] = yield select((state: RootState) => state.cart.data)
  try {
    const response: AxiosResponse<AddCartItemResponse> = yield call(Api.cart.post, payload);
    const {cartItem, product} = response.data;
    gtag.addToCart({
      type: 'cart',
      isQuotation: false,
      item: cartItem,
      product
    });

    if (cart) {
      const cartItemIndex = findCartItemIndexById(cart, cartItem._id);
      if (cartItemIndex === -1) {
        yield put(actions.cart.addItem(cartItem, product));
      } else {
        yield put(actions.cart.setProduct(product.uuid, product));
        yield put(actions.cart.setItem({
          quantity: cartItem.quantity
        }, cartItem._id));
      }
    } else {
      yield put(actions.cart.fetchCart())
    }

    if (goToCart) {
      yield call(Router.push, `/cart?itemId=${cartItem._id}`)
    }
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.cart.setAddItemError)
  }
}

type BuyNowAction = ReturnType<typeof actions.cart.buyNow>;
function* buyNow(action: BuyNowAction) {
  yield *_addToCart(action, true)
}

type AddToCartAction = ReturnType<typeof actions.cart.addItemAsync>
function* addToCart(action: AddToCartAction) {
  yield* _addToCart(action, false)
}

type RemoveCartItemAsyncAction = ReturnType<typeof actions.cart.removeItemAsync>;
function* removeFromCart(action: RemoveCartItemAsyncAction) {
  const id = action.payload;
  const cart: CartItemWithProductForCart[] = yield select((state: RootState) => state.cart.data);
  const cartItem = findCartItemById(cart, id);

  try {
    if (cartItem) {
      yield Api.cart.delete(id);
      yield put(actions.cart.removeItem(id));
    }
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.cart.setRemoveItemError);
  }
}

type SetCartItemMessageAsyncAction = ReturnType<typeof actions.cart.setItemMessageAsync>;
type SetCartItemQuantityAsyncAction= ReturnType<typeof actions.cart.setItemQuantityAsync>;
function* updateCartItem(action: SetCartItemMessageAsyncAction | SetCartItemQuantityAsyncAction) {
  const { set, id } = action.payload;
  const cart: CartItemWithProductForCart[] = yield select((state) => state.cart.data);
  const update = extractProperties(set, 'quantity', 'message');
  const cartItem = findCartItemById(cart, id);

  try {
    if (cartItem) {
      yield call(Api.cart.put, id, update)
      yield put(actions.cart.setItem(update, id))
    } else {
      yield put(actions.cart.removeItem(id));
    }
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.cart.setSetItemError)
  }
}

function* fetchCart() {
  try {
    const response: AxiosResponse<GetCartItemsResponse> = yield call(Api.cart.get)
    const {cartItems, products} = response.data;
    yield put(actions.cart.set(cartItems, keyBy(products, 'uuid')));
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.cart.setFetchCartError)
  }
}

function *fetchQuotationItems() {
  try {
    const response: AxiosResponse<GetQuotationItemsResponse> = yield call(Api.quotationItem.getAll);
    const {products} = response.data;
    yield put(actions.quotationItem.set(response.data.quotationItems, keyBy(products, 'uuid')));
  } catch(error: any) {
    yield sagaGenericErrorHandling(error, actions.quotationItem.setFetchQuotationItemsError)
  }
}

type AddQuotationFromCartItemAction = ReturnType<typeof actions.quotationItem.addQuotationFromCartItem>;
function *addInternalQuotationItemFromCartItem(action: AddQuotationFromCartItemAction) {
  const {cartItemId} = action.payload;
  try {
    const response: AxiosResponse<CreateInternalQuotationItemResponse> = yield call(Api.quotationItem.createInternal, cartItemId);
    const {quotationItem, product} = response.data;
    gtag.addToCart({
      type: 'quotation_internal',
      isQuotation: true,
      item: quotationItem,
      product
    });

    yield put(actions.quotationItem.addItem(quotationItem, product));
    yield put(actions.cart.removeItem(cartItemId));
  } catch(error: any) {
    yield sagaGenericErrorHandling(error, actions.quotationItem.setAddInternalItemError);
  }
}

type AddQuotationFromProductAction = ReturnType<typeof actions.quotationItem.addQuotationFromProduct>;
function *addInternalQuotationItemFromProduct(action: AddQuotationFromProductAction) {
  const {payload} = action;
  try {
    const response: AxiosResponse<CreateInternalQuotationItemResponse> = yield call(Api.quotationItem.createInternalFromProduct, payload);
    yield put(actions.quotationItem.addItem(response.data.quotationItem, response.data.product));
  } catch(error: any) {
    yield sagaGenericErrorHandling(error, actions.quotationItem.setAddInternalItemFromProductError);
  }
}

type SetQuotationItemInstructionAction = ReturnType<typeof actions.quotationItem.setItemInstructionsAsync>;
type SetQuotationItemQuantityAction = ReturnType<typeof actions.quotationItem.setItemQuantityAsync>;
function *updateQuotationItem(action: SetQuotationItemInstructionAction | SetQuotationItemQuantityAction) {
  const { set, id } = action.payload;
  const quotationItems: QuotationItem[] = yield select((state) => state.quotationItem.data);
  const update = extractProperties(set, 'quantity', 'instructions');
  const quotationItem = findQuotationItemById(quotationItems, id);

  try {
    if (quotationItem) {
      yield call(Api.quotationItem.update, id, update);
      yield put(actions.quotationItem.setItem(update, id));
    } else {
      yield put(actions.quotationItem.removeItem(id));
    }
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.quotationItem.setSetItemError)
  }
}


type RemoveQuotationItemAsyncAction = ReturnType<typeof actions.quotationItem.removeItemAsync>;
function *removeQuotationItem(action: RemoveQuotationItemAsyncAction) {
  const {id} = action.payload;
  const quotationItems: QuotationItem[] = yield select((state) => state.quotationItem.data);
  const quotationItem = findQuotationItemById(quotationItems, id);

  try {
    if (quotationItem) {
      yield Api.quotationItem.delete(id);
      yield put(actions.quotationItem.removeItem(id));
    }
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.quotationItem.setRemoveItemError);
  }
}

function* fetchMe() {
  try {
    const response: AxiosResponse<MeResponse> = yield call(Api.auth.getMe)
    yield put(actions.user.setMe(response.data))
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.user.setFetchMeError)
  }
}

function* updateProfile(action: ReturnType<typeof actions.user.updateProfile>) {
  try {
    const response: AxiosResponse<UpdateProfileResponse> = yield call(Api.user.updateProfile, action.payload)
    yield put(actions.user.setProfile(response.data.user))
  } catch (error: any) {
    yield put(actions.user.setUpdateProfileError(error));
  }
}

function* fetchConfigs() {
  try {
    const response: AxiosResponse<GetAllConfigResponse> = yield call(Api.config.getAll)
    yield put(actions.config.setAll(response.data))
  } catch (error: any) {
    yield sagaGenericErrorHandling(error, actions.config.setFetchAllError)
  }
}

function* signOutAsync() {
  try {
    yield Api.auth.signOut();
    yield put(actions.user.signOut());
    yield call(Router.push, '/');

    yield call(clearLoginRedirectPath);
  } catch (error) {
    console.error('saga signout error', error)
  }
}

function* fetchDefaultRegion() {
  try {
    const response: AxiosResponse<GetDefaultRegionResponse> = yield call(Api.region.default)
    yield put(actions.region.setDefaultRegion(response.data.region))
  } catch(error) {
    console.error('failed to fetch default region', error)
  }
}

function* fetchPreferredRegion() {
  try {
    const response: AxiosResponse<GetPreferredRegionResponse> = yield call(Api.region.getPreferred)
    yield put(actions.region.setPreferredRegion(response.data.countryCode))
  } catch(error) {
    console.error('failed to fetch current region', error)
  }
}

function* signInAsync(action: ReturnType<typeof actions.user.signInAsync>) {
  try {
    const {email, password, rememberMe} = action.payload;
    yield call(Api.auth.login, email, password, rememberMe);
    yield call(fetchMe)
  } catch (error: any) {
    yield put(actions.user.setSignInAsyncError(error))
  }
}

function* tfaVerifyAsync(action: ReturnType<typeof actions.user.tfaVerifyAsync>) {
  const code = action.payload;
  try {
    yield call(Api.tfa.verify, code);
    yield call(fetchMe);
  } catch (error: any) {
    yield put(actions.user.setTfaVerifyAsyncError(error));
  }

}

function* watchBuyNow() {
  yield takeLatest(actionTypes.cart.CART_BUY_NOW, buyNow)
}

function* watchAddToCart() {
  yield takeLatest(actionTypes.cart.CART_ADD_ITEM_ASYNC, addToCart)
}

function* watchRemoveFromCart() {
  yield takeLatest(actionTypes.cart.CART_REMOVE_ITEM_ASYNC, removeFromCart)
}

function* watchSetCartItemAsync() {
  yield takeLatest(actionTypes.cart.CART_SET_ITEM_ASYNC, updateCartItem)
}

function* watchFetchCart() {
  yield takeLatest(actionTypes.cart.SET_CART_ASYNC, fetchCart)
}

function* watchFetchQuotationItems() {
  yield takeLatest(actionTypes.quotationItems.FETCH_QUOTATION_ITEMS, fetchQuotationItems);
}

function* watchAddQuotationFromCartItem() {
  yield takeLatest(actionTypes.quotationItems.ADD_QUOTATION_ITEM_INTERNAL_ASYNC, addInternalQuotationItemFromCartItem);
}

function* watchAddQuotationFromProduct() {
  yield takeLatest(actionTypes.quotationItems.ADD_QUOTATION_ITEM_INTERNAL_FROM_PRODUCT_ASYNC, addInternalQuotationItemFromProduct);
}

function* watchSetQuotationItemAsync() {
  yield takeLatest(actionTypes.quotationItems.SET_QUOTATION_ITEM_ASYNC, updateQuotationItem);
}

function* watchRemoveQuotationItemAsync() {
  yield takeLatest(actionTypes.quotationItems.REMOVE_QUOTATION_ITEM_ASYNC, removeQuotationItem);
}

function* watchFetchProfile() {
  yield takeLatest(actionTypes.user.FETCH_ME, fetchMe)
}

function *watchUpdateProfile() {
  yield takeLatest(actionTypes.user.UPDATE_PROFILE, updateProfile)
}

function *watchFetchConfigCached() {
  yield fork(function*() {
    let lastTask
    while (true) {
      yield take(actionTypes.config.FETCH_ALL)
      if (lastTask) {
        continue
      }
      lastTask = yield call(fetchConfigs)
      lastTask = null
      let isConfigLoaded = yield select((state) => state.config.clientLoaded)
      if (isConfigLoaded) {
        console.log('cofig fetched ending watch')
        break
      }
    }
  })
}

function *watchFetchDefaultRegionCached() {
  yield fork(function*() {
    let lastTask
    while (true) {
      yield take(actionTypes.region.FETCH_DEFAULT_REGION)
      if (lastTask) {
        continue
      }
      lastTask = yield call(fetchDefaultRegion)
      lastTask = null
      let isLoaded = yield select((state) => state.region.defaultRegionLoaded)
      if (isLoaded) {
        console.log('fetch default region ending watch')
        break
      }
    }
  })
}

function *watchFetchPreferredRegionCached() {
  yield fork(function*() {
    let lastTask
    while (true) {
      yield take(actionTypes.region.FETCH_PREFERRED_REGION)
      if (lastTask) {
        continue
      }
      lastTask = yield call(fetchPreferredRegion)
      lastTask = null
    }
  })
}

function* watchSignOutAsync() {
  yield takeLatest(actionTypes.user.SIGN_OUT_ASYNC, signOutAsync)
}

function* watchSignInAsync() {
  yield takeLatest(actionTypes.user.SIGN_IN_ASYNC, signInAsync)
}

function* watchTfaVerifyAsync() {
  yield takeLatest(actionTypes.user.TFA_VERIFY_ASYNC, tfaVerifyAsync);
}

export default function* rootSaga() {
  yield all([
    watchLoadCategories(),
    watchBuyNow(),
    watchAddToCart(),
    watchRemoveFromCart(),
    watchSetCartItemAsync(),
    watchFetchCart(),
    watchFetchQuotationItems(),
    watchAddQuotationFromCartItem(),
    watchAddQuotationFromProduct(),
    watchSetQuotationItemAsync(),
    watchRemoveQuotationItemAsync(),
    watchFetchProfile(),
    watchUpdateProfile(),
    watchFetchConfigCached(),
    watchSignOutAsync(),
    watchSignInAsync(),
    watchTfaVerifyAsync(),
    watchFetchDefaultRegionCached(),
    watchFetchPreferredRegionCached()
  ])
}