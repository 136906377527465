import { actionTypes } from '../actions'
import * as regionActionFactories from '../actions/region/factories';
import {ActionType} from "typesafe-actions";

type RegionActions = ActionType<typeof regionActionFactories>;

interface RegionState {
	defaultRegion: string | null;
	defaultRegionLoaded: boolean;
	preferredRegion: string | null;
	preferredRegionLoaded: boolean;
	lastChosenRegion: string | null;
}

const defaultState: RegionState = {
	defaultRegion: null,
	defaultRegionLoaded: false,
	preferredRegion: null,
	preferredRegionLoaded: false,
	lastChosenRegion: null
}

function regionReducer(state: RegionState = defaultState, action: RegionActions) {
	switch (action.type) {
		case actionTypes.region.SET_PREFERRED_REGION:
			return Object.assign({}, state, {
				preferredRegion: action.payload,
				preferredRegionLoaded: true
			})
		case actionTypes.region.SET_DEFAULT_REGION:
			return Object.assign({}, state, {
				defaultRegion: action.payload,
				defaultRegionLoaded: true
			})
		case actionTypes.region.SET_LAST_CHOSEN_REGION:
			return Object.assign({}, state, {
				lastChosenRegion: action.payload
			})
		default:
			return state
	}
}

export default regionReducer