import { useSelector } from 'react-redux'
import Dinero, {Currency} from 'dinero.js'
import {currencyPrecision} from '../constants/currency_precision'
import {RootState} from "../store/reducers/reducers";

export function createDinero(currency: string, amount: number) {
    const precision = currencyPrecision[currency];
    return Dinero({
        currency: currency as Currency,
        amount,
        precision,
    })
}

export function formatMoney(currency: string, amount: number) {
    const precision = currencyPrecision[currency];
    const amountStr = amount.toString()
    const majorUnit = amountStr.substring(0, amountStr.length - precision)
    const minorUnit = amountStr.substring(amountStr.length - precision)
    const number = parseFloat(majorUnit + '.' + minorUnit)
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: currency }).format(number)
}

export function formatDinero(dineroAmount: Dinero.Dinero) {
    return formatMoney(dineroAmount.getCurrency(), dineroAmount.getAmount())
}

export function useAppCurrency() {
    return useSelector((state: RootState) => {
        const { data } = state.config;
        if (data) {
            return data['app_currency'];
        }

        return null;
    })
}