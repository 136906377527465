import Router from "next/router";
import {ParsedUrlQuery} from "querystring";
import * as PostLoginRedirectPath from "../service/post_login_redirect_path";

const isUrlAbsoluteRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
export function isUrlAbsolute(urlString: string): boolean {
	return isUrlAbsoluteRegex.test(urlString);
}

export function currentPagePathSearchHash() {
	return window.location.pathname + window.location.search + window.location.hash;
}

export function requestSignInForThisPage(postLoginRedirectPath?: string) {
	if (!postLoginRedirectPath) {
		postLoginRedirectPath = currentPagePathSearchHash();
	}
	PostLoginRedirectPath.save(postLoginRedirectPath);

	return Router.push('/sign_in');
}

export function convertQueryToParams(queries: ParsedUrlQuery): URLSearchParams {
	const params = new URLSearchParams();
	for (const [key, value] of Object.entries(queries)) {
		if (!value) {
			continue;
		}

		if (Array.isArray(value)) {
			for (const item of value) {
				params.append(key, item);
			}
			continue;
		}

		params.set(key, value);
	}
	return params;
}