import {action} from "typesafe-actions";
import * as regionActionTypes from './types';

export function setPreferredRegion(preferredRegion: string | null) {
  return action(regionActionTypes.SET_PREFERRED_REGION, preferredRegion)
}

export function setDefaultRegion(defaultRegion: string) {
  return action(regionActionTypes.SET_DEFAULT_REGION, defaultRegion)
}

export function fetchDefaultRegion() {
  return action(regionActionTypes.FETCH_DEFAULT_REGION)
}

export function fetchPreferredRegion() {
  return action(regionActionTypes.FETCH_PREFERRED_REGION)
}

export function setLastChosenRegion(lastChosenRegion: string) {
  return action(regionActionTypes.SET_LAST_CHOSEN_REGION, lastChosenRegion)
}