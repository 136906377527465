import { put } from 'redux-saga/effects'
import capitalize from 'lodash/capitalize'
import { has } from "lodash";
import {AxiosError} from "axios";
import { csFormat } from './string'
import errorCodeMessages from '../constants/localization/en/error_codes/'
import {ApiAuthErrorResponse, ApiErrorResponse} from "../types/api/general";

export function sagaGenericErrorHandling(error: AxiosError<ApiErrorResponse>, errorActionFn: (message: string) => any) {
	let message = 'Unknown error'
	if (error.response && error.response.data && error.response.data.error) {
		message = error.response.data.error;
	} else if (error.message) {
		message = error.message;
	}
	message = capitalize(message);
	return put(errorActionFn(message));
}

export function extractAxiosError(error: AxiosError<ApiErrorResponse> | Error) {
	if (isAxiosError(error) && error.response && error.response.data && error.response.data.error) {
		return capitalize(error.response.data.error);
	} else {
		return capitalize(error.message);
	}
}

export function isAxiosError(error: AxiosError | Error): error is AxiosError<ApiErrorResponse> {
	return has(error, 'response.data');
}

export function isAxiosAuthError(error: AxiosError | Error): error is AxiosError<ApiAuthErrorResponse> {
	return has(error, 'response.data.errorCode');
}

export function extractAuthError(error: AxiosError<ApiAuthErrorResponse> | Error) {
	if (isAxiosAuthError(error) && error.response) {
		const message = errorCodeMessages[error.response.data.errorCode];
		return csFormat(message, ...error.response.data.parameters);
	} else {
		return capitalize(error.message);
	}
}