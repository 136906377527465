export const SIGN_IN = 'SIGN_IN';
export const TFA_STEP = 'TFA_STEP';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_ME = 'SET_ME';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_ASYNC = 'SIGN_OUT_ASYNC';
export const FETCH_ME = 'FETCH_ME';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SET_FETCH_ME_ERROR = 'SET_FETCH_ME_ERROR';
export const SET_UPDATE_PROFILE_ERROR = 'SET_UPDATE_PROFILE_ERROR';
export const SET_SIGN_IN_ASYNC_ERROR = 'SET_SIGN_IN_ASYNC_ERROR';
export const SIGN_IN_ASYNC = 'SIGN_IN_ASYNC';
export const TFA_VERIFY_ASYNC = 'TFA_VERIFY_ASYNC';
export const TFA_VERIFY_ASYNC_ERROR = 'TFA_VERIFY_ASYNC_ERROR';