export default {
	'user/invalid_phone_number': 'Invalid Phone Number',
	'user/invalid_first_name': 'Invalid first name, length must be between 1 and 100',
	'user/invalid_last_name': 'Invalid last name, length must be between 1 and 100',
	'user/invalid_email': 'Invalid email',
	'user/invalid_password': `Invalid password,
Password must be at least 8 characters long
and at least 1 symbol or number
and at least 1 lower and 1 upper case letter`,
	'user/invalid_accept_terms': 'Please accept the terms and conditions',
	'user/not_found': 'The user you are looking for is not found',
	'user/max_addresses_reached': 'You have reached the maximum number address of {0} per user',
	'user/invalid_country': 'Invalid country',
	'user/invalid_birthdate': 'Invalid birthdate',
	'user/invalid_gender': 'Invalid gender',
}