import {AnyAction, combineReducers, Reducer} from 'redux'
import userReducer from './user'
import categoryReducer from './category'
import cartReducer from './cart'
import configReducer from './config'
import regionReducer from './region'
import {HYDRATE} from "next-redux-wrapper";
import {checkNested} from "../../utils/object";
import {epochTime} from "../../utils/date";
import quotationItemsReducer from "./quotation_items";

const combinedReducer = combineReducers({
	user: userReducer,
	category: categoryReducer,
	cart: cartReducer,
	config: configReducer,
	region: regionReducer,
	quotationItem: quotationItemsReducer
});
export type RootState = ReturnType<typeof combinedReducer>;

export function makeReducer() {
	return function(state: RootState, action: AnyAction) {
		if (action.type === HYDRATE) {
			if (checkNested(state, ['category', 'data']) && !state.category.data) {
				state.category.data = action.payload.category.data
				state.category.updatedAt = epochTime()
			}

			if (checkNested(state, ['region', 'defaultRegion']) && !state.region.defaultRegion) {
				state.region.defaultRegion = action.payload.region.defaultRegion
				state.region.defaultRegionLoaded = true
			}

			if (checkNested(state, ['config', 'data']) && !state.config.data) {
				state.config.data = action.payload.config.data
			}

			return state;
		} else {
			return combinedReducer(state, action)
		}
	} as Reducer<RootState>;
}
