import {Product, ProductPriceRange} from "../types/models/product";
import Dinero from "dinero.js";
import {currencyPrecision} from "../constants/currency_precision";
import {CartItem, CartItemProductForCart} from "../types/models/cart_item";
import addressFields from "../constants/address_fields";
import {SingleOrderV3} from "../types/models/order";
import {Popup} from "../types/models/popup";
import {CartAndQuotationItemWrapper} from "../hooks/use_cart_and_quotation_items";
import {QuotationItem, QuotationItemSources, QuotationItemStatuses} from "../types/models/quotation_item";
import {createDinero} from "../service/currency_converter";
import {keyBy} from "lodash";
import {isInternal} from "./quotation_item";
import {oneOrManyAsArray} from "../utils/array";

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

declare global {
  interface Window {
    gtag: typeof gtag;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (!GA_TRACKING_ID) return;

  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
/**
 * The following table lists the default Google Analytics Events,
 * their default categories, and default label types (if available).
 * For event names not listed in this table (e.g. arbitrary event names that you create),
 * the default category is "engagement" and the default label is "(not set)"
 */
interface GenericEvent {
  action: string;
  category: string;
  label: string;
  value: string;
}

export const event = ({action, category, label, value}: GenericEvent) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

type ProductForAnalytics = Pick<Product,
  'priceCurrency' |
  'price' |
  'paramName' |
  'name' |
  'sourceMarketPlace'> & Partial<ProductPriceRange>;

type ProductForAnalyticsWithVariants = ProductForAnalytics & Pick<Product, 'variants'>;

function extractPriceFromProduct(product: ProductForAnalytics, countryCode?: string) {
  let price: number | undefined = undefined;
  if (countryCode) {
    try {
      if ('priceRange' in product && product.priceRange) {
        price = Dinero({
          amount: product.priceRange[0][countryCode],
          currency: product.priceCurrency as Dinero.Currency,
          precision: currencyPrecision[product.priceCurrency as keyof typeof currencyPrecision]
        }).toUnit();
      } else {
        price = Dinero({
          amount: product.price[countryCode],
          currency: product.priceCurrency as Dinero.Currency,
          precision: currencyPrecision[product.priceCurrency as keyof typeof currencyPrecision]
        }).toUnit();
      }
    } catch (e) {
      price = undefined;
    }
  }

  return price;
}


function createECommerceProductParams(product: ProductForAnalytics[] | ProductForAnalytics, countryCode?: string) {
  const products = oneOrManyAsArray(product);

  const items = products.map(p => {
    return getECommerceParamsProductItem(p, countryCode);
  });
  let total = undefined;
  if (items.every(p => !!p.price)) {
    total = items.reduce((sum, ci) => sum + ci.price!, 0);
  }

  if (products.length === 0) {
    return {
      currency: 'SGD',
      value: 0,
      items: []
    };
  } else {
    return {
      currency: products[0].priceCurrency,
      value: total,
      items: items
    };
  }
}

function getECommerceParamsProductItem(product: ProductForAnalytics, countryCode?: string) {
  const price = extractPriceFromProduct(product, countryCode);
  return {
    item_id: product.paramName,
    item_name: product.name,
    affiliation: product.sourceMarketPlace,
    currency: product.priceCurrency,
    price
  };
}

function getECommerceParamsQuotationItem(quotationItem: QuotationItem, countryCode?: string, product?: ProductForAnalytics) {
  if (quotationItem.productSource === QuotationItemSources.internal) {
    if (!product) {
      throw new Error('product is not found for quotation item');
    }

    return getECommerceParamsProductItem(product, countryCode);
  } else {
    const externalItem = quotationItem as QuotationItem<QuotationItemStatuses, QuotationItemSources.external>;

    let price = createDinero('SGD', 0);
    if (externalItem.status === QuotationItemStatuses.resolved) {
      const externalResolvedItem = quotationItem as QuotationItem<QuotationItemStatuses.resolved, QuotationItemSources.external>;
      price = createDinero(externalResolvedItem.statusData.basePriceCurrency, externalResolvedItem.statusData.basePrice);
    }

    const data: ReturnType<typeof getECommerceParamsProductItem> = {
      item_id: quotationItem.productSource,
      item_name: quotationItem.name,
      affiliation: '3p',
      currency: price.getCurrency(),
      price: price.toUnit()
    };

    return data;
  }
}

function getECommerceParamsCartItem(cartItem: CartItem, product: ProductForAnalyticsWithVariants, countryCode?: string) {
  return Object.assign(getECommerceParamsProductItem(product, countryCode), {
    quantity: cartItem.quantity,
    item_variant: getCartItemVariantInfo(cartItem, product)
  });
}

function getECommerceParamsQuotationOrCartItem(wrappedItem: CartAndQuotationItemWrapper, countryCode?: string) {
  if (wrappedItem.type === 'quotation_internal') {
    return getECommerceParamsQuotationItem(wrappedItem.item, countryCode, wrappedItem.product);
  } else if (wrappedItem.type === 'quotation_external') {
    return getECommerceParamsQuotationItem(wrappedItem.item, countryCode);
  } else {
    return getECommerceParamsCartItem(wrappedItem.item, wrappedItem.product, countryCode);
  }
}

function createECommerceQuotationOrCartItemParams(wrappedItems: CartAndQuotationItemWrapper| CartAndQuotationItemWrapper[], countryCode?: string) {
  let items;
  if (Array.isArray(wrappedItems)) {
    items = wrappedItems.map(wrappedItem => {
      return getECommerceParamsQuotationOrCartItem(wrappedItem, countryCode);
    });
  } else {
    items = [getECommerceParamsQuotationOrCartItem(wrappedItems, countryCode)];
  }


  let total = undefined;
  if (items.every(ci => !!ci.price)) {
    total = items.reduce((sum, ci) => sum + ci.price!, 0);
  }

  if (items.length === 0) {
    return {
      currency: 'SGD',
      value: 0,
      items: []
    };
  } else {
    return {
      currency: items[0].currency,
      value: total,
      items: items
    };
  }
}

function getCountryCodeFromURL() {
  const {pathname} = window.location;
  if (pathname.length === 0) {
    return undefined;
  }

  const parts = pathname.split('/').slice(1);
  if (parts.length === 0) {
    return undefined;
  }

  const countryCode = parts[0];
  if (!addressFields.countries[countryCode]) {
    return undefined;
  }

  return countryCode;
}

function getCartItemVariantInfo(item: CartItem, product: ProductForAnalyticsWithVariants) {
  const {variants} = product;
  const {variantPath} = item;
  const steps = variantPath.split('/')

  if (steps.length !== variants.length) {
    return null;
  }

  const variantChoices = []
  for (let i = 0; i < steps.length; i++) {
    const variant = variants[i];
    const step = parseInt(steps[i]);

    variantChoices.push(`${variant.name}: ${variant.options[step]}`);
  }

  return variantChoices.join('; ');
}

export function viewItem(product: Product, countryCode?: string) {
  window.gtag('event', 'view_item', createECommerceProductParams(product, countryCode));
}

export function addToWishlist(product: Product, countryCode?: string) {
  window.gtag('event', 'add_to_wishlist', createECommerceProductParams(product, countryCode));
}

export function addToCart(wrappedItem: CartAndQuotationItemWrapper) {
  const countryCode = getCountryCodeFromURL();

  window.gtag('event', 'add_to_cart', createECommerceQuotationOrCartItemParams(wrappedItem, countryCode));
}

export function viewCart(wrappedItems: CartAndQuotationItemWrapper[], countryCode?: string) {
  window.gtag('event', 'view_cart', createECommerceQuotationOrCartItemParams(wrappedItems, countryCode));
}

export function removeFromCart(wrappedItem: CartAndQuotationItemWrapper, countryCode?: string) {
  window.gtag('event', 'remove_from_cart', createECommerceQuotationOrCartItemParams(wrappedItem, countryCode));
}

export function purchase(order: SingleOrderV3) {
  const productById = keyBy(order.productCaptures, 'uuid');
  const wrappedCartItems: CartAndQuotationItemWrapper[] = order.cartItems.map(item => {
    const itemProduct = productById[item.productId];

    return {
      type: 'cart',
      isQuotation: false,
      item,
      product: itemProduct as CartItemProductForCart
    };
  });

  const wrappedQuotationItems: CartAndQuotationItemWrapper[] = order.quotationItems.map(item => {
    if (isInternal(item)) {
      const itemProduct = productById[item.productSourceData.productId];
      return {
        type: 'quotation_internal',
        isQuotation: true,
        item,
        product: itemProduct as CartItemProductForCart
      }
    }
    return {
      type: 'quotation_external',
      isQuotation: true,
      item: item as QuotationItem<QuotationItemStatuses, QuotationItemSources.external>
    };
  });
  const params = createECommerceQuotationOrCartItemParams(wrappedCartItems.concat(wrappedQuotationItems), order.regionCountryCode);
  const eventData: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams = {
    ...params,
    transaction_id: order.orderRef
  };
  if (order.taxFee.amount !== 0) {
    eventData.tax = Dinero(order.taxFee).toUnit();
  }

  window.gtag('event', 'purchase', {
    ...params,
    transaction_id: order.orderRef
  });
}

export function beginCheckout(wrappedItems: CartAndQuotationItemWrapper[], countryCode?: string) {
  window.gtag('event', 'begin_checkout', createECommerceQuotationOrCartItemParams(wrappedItems, countryCode));
}

export function addShippingInfo(cartItems: CartAndQuotationItemWrapper[], countryCode?: string) {
  window.gtag('event', 'add_shipping_info', createECommerceQuotationOrCartItemParams(cartItems, countryCode));
}

export function search(term: string) {
  window.gtag('event', 'search', {
    search_term: term
  });
}

export function selectItem(item: ProductForAnalytics, countryCode?: string) {
  window.gtag('event', 'select_item', createECommerceProductParams(item, countryCode));
}

export function popupShown(popup: Popup) {
  window.gtag('event', 'popup_shown', {
    event_category: 'popup',
    popup_link: popup.link,
    popup_name: popup.name,
    non_interaction: true,
  });
}

export function popupClicked(popup: Popup) {
  window.gtag('event', 'popup_clicked', {
    event_category: 'popup',
    popup_link: popup.link,
    popup_name: popup.name,
    transport_type: 'beacon',
  });
}