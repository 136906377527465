import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import _ from "lodash";
import Api from './api'
import { getStore } from '../store/store'
import { actions } from '../store/actions'
import {ApiAuthErrorResponse} from "../types/api/general";
import {replaceAll} from "../utils/string";

const AUTH_BASE_URL = process.env.NEXT_PUBLIC_AUTH_SERVICE_BASE_URL
const SERVICE_BASE_URL = process.env.NEXT_PUBLIC_STORE_SERVICE_BASE_URL
const SERVERLESS_FNS_BASE_URL = process.env.NEXT_PUBLIC_SERVERLESS_FNS_BASE_URL
let MESSAGING_BASE_URL = 'http://localhost:3007';
if (process.env.NEXT_PUBLIC_MESSAGING_SERVICE_BASE_URL) {
  MESSAGING_BASE_URL = replaceAll(process.env.NEXT_PUBLIC_MESSAGING_SERVICE_BASE_URL, 'ws:', 'http:');
  MESSAGING_BASE_URL = replaceAll(MESSAGING_BASE_URL, 'wss:', 'https:');
}

let refreshTokenPromise: Promise<AxiosResponse> | null = null


async function createRetryRequestAfterRefreshToken(requestPromise: Promise<AxiosResponse>, retryReqConfig: AxiosRequestConfig) {
  await requestPromise
  console.log('refreshed token', retryReqConfig.url)
  return axios(retryReqConfig)
} 

async function autoRefreshTokenErrorInterceptor(error: AxiosError<ApiAuthErrorResponse>) {
  if (!error.response || error.response.status !== 401) {
    return Promise.reject(error);
  }

  // try to refresh token
  
  const errorReqConfig = error.response.config
  if (!refreshTokenPromise) {
    console.log('trying to refresh token', errorReqConfig.url)

    const promise = Api.auth.refreshAccessToken()
      .catch(err => {
        // console.log(err)
        const store = getStore()
        if (store) {
          store.dispatch(actions.user.signOutAsync())
        } else {
          console.log('store is falsy', store)
        }
        return Promise.reject(err)
      })
      .finally(() => {
        refreshTokenPromise = null
      })
    refreshTokenPromise = createRetryRequestAfterRefreshToken(promise, errorReqConfig)
    return refreshTokenPromise
  } else {
    return createRetryRequestAfterRefreshToken(refreshTokenPromise, errorReqConfig)
  }
}

export const authEndpoint = axios.create({
  baseURL: AUTH_BASE_URL,
  withCredentials: true,
  withXSRFToken: true
})

export const authHeadersEndpoint = axios.create({
  baseURL: AUTH_BASE_URL,
  withCredentials: true,
  withXSRFToken: true
})
authHeadersEndpoint.interceptors.response.use(
  _.identity,
  autoRefreshTokenErrorInterceptor
)

export const serviceHeadersEndpoint = axios.create({
  baseURL: SERVICE_BASE_URL,
  withCredentials: true,
  withXSRFToken: true
})
serviceHeadersEndpoint.interceptors.response.use(
  _.identity,
  autoRefreshTokenErrorInterceptor
)

export const serviceEndpoint = serviceHeadersEndpoint;

export const serverlessEndpoint = axios.create({
  baseURL: SERVERLESS_FNS_BASE_URL,
});

export const messagingEndpoint = axios.create({
  baseURL: MESSAGING_BASE_URL,
  withCredentials: true,
  withXSRFToken: true
})
serviceHeadersEndpoint.interceptors.response.use(
  _.identity,
  autoRefreshTokenErrorInterceptor
);