import {action} from "typesafe-actions";
import {UserProfile, UserProfileUpdate} from "../../../types/models/user";
import {MeResponse} from "../../../types/api/responses/auth";
import * as userActionTypes from "./types";
import {AxiosError} from "axios";

export function signIn() {
  return action(userActionTypes.SIGN_IN, null);
}

export function tfaStep() {
  return action(userActionTypes.TFA_STEP, null)
}

export function setProfile(profile: UserProfile) {
  return action(userActionTypes.SET_PROFILE, profile)
}

export function setMe(meData: MeResponse) {
  return action(userActionTypes.SET_ME, meData)
}

export function signOut() {
  return action(userActionTypes.SIGN_OUT, null)
}

export function fetchMe() {
  return action(userActionTypes.FETCH_ME, null)
}

export function updateProfile(profileUpdate: UserProfileUpdate) {
  return action(userActionTypes.UPDATE_PROFILE, profileUpdate);
}

export function setFetchMeError(error: string | null) {
  return action(userActionTypes.SET_FETCH_ME_ERROR, error)
}

export function setUpdateProfileError(error: AxiosError | Error | null) {
  return action(userActionTypes.SET_UPDATE_PROFILE_ERROR, error)
}

export function signOutAsync() {
  return action(userActionTypes.SIGN_OUT_ASYNC, null)
}

export function setSignInAsyncError(error: AxiosError | Error | null) {
  return action(userActionTypes.SET_SIGN_IN_ASYNC_ERROR, error)
}

export function signInAsync(email: string, password: string, rememberMe: boolean) {
  return action(userActionTypes.SIGN_IN_ASYNC, {
    email,
    password,
    rememberMe
  })
}

export function tfaVerifyAsync(code: string) {
  return action(userActionTypes.TFA_VERIFY_ASYNC, code);
}

export function setTfaVerifyAsyncError(error: AxiosError | Error | null) {
  return action(userActionTypes.TFA_VERIFY_ASYNC_ERROR, error);
}