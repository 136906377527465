import {ActionType} from "typesafe-actions";
import {QuotationItem} from "../../types/models/quotation_item";
import * as quotationItemsActionFactories from "../actions/quotation_items/factories";
import * as actions from "../actions/quotation_items/types";
import {CartItemProductForCart} from "../../types/models/cart_item";

type QuotationItemActions = ActionType<typeof quotationItemsActionFactories>;

export interface QuotationItemsState {
  data: QuotationItem[];
  productById: Record<string, CartItemProductForCart>,
  dataLoaded: boolean;
  addInternalItemError: string | null;
  addInternalItemFromProductError: string | null;
  removeItemError: string | null;
  setItemError: string | null;
  fetchItemError: string | null;
}
const defaultState: QuotationItemsState = {
  data: [],
  productById: {},
  dataLoaded: false,
  addInternalItemError: null,
  addInternalItemFromProductError: null,
  removeItemError: null,
  setItemError: null,
  fetchItemError: null,
}

export default function quotationItemsReducer(state: QuotationItemsState = defaultState, action: QuotationItemActions) {
  switch (action.type) {
    case actions.SET_QUOTATION_ITEMS: {
      const {quotationItems, products} = action.payload;
      return {
        ...state,
        dataLoaded: true,
        data: quotationItems,
        productById: products
      };
    }
    case actions.REMOVE_QUOTATION_ITEM: {
      const index = state.data.findIndex(item => item._id === action.payload.id);
      if (index === -1) {
        return state;
      }

      const copy = state.data.slice();
      copy.splice(index, 1);
      return {
        ...state,
        data: copy
      };
    }
    case actions.ADD_QUOTATION_ITEM: {
      const newData = state.data.slice();
      newData.push(action.payload.quotationItem);


      if (action.payload.product) {
        // internal quotation item
        const newProductById = {
          ...state.productById,
          [action.payload.product.uuid]: action.payload.product
        };

        return {
          ...state,
          data: newData,
          productById: newProductById
        };
      } else {
        return {
          ...state,
          data: newData
        };
      }

    }
    case actions.SET_QUOTATION_ITEM_PRODUCT: {
      const {productId, product} = action.payload;
      const newProductById = {...state.productById};
      if (product) {
        newProductById[productId] = product;
      } else {
        delete newProductById[productId];
      }

      return {
        ...state,
        productById: newProductById
      };
    }
    case actions.SET_QUOTATION_ITEM:
      const index = state.data.findIndex(item => item._id === action.payload.id);
      if (index === -1) {
        return state;
      }

      const copy = state.data.slice();
      copy[index] = {
        ...copy[index],
        ...action.payload.update
      };
      return {
        ...state,
        data: copy
      };
    case actions.SET_ADD_INTERNAL_QUOTATION_ITEM_ERROR:
      return {
        ...state,
        addInternalItemError: action.payload
      };
    case actions.SET_ADD_INTERNAL_QUOTATION_ITEM_FROM_PRODUCT_ERROR:
      return {
        ...state,
        addInternalItemFromProductError: action.payload
      };
    case actions.SET_REMOVE_QUOTATION_ITEM_ERROR:
      return {
        ...state,
        removeItemError: action.payload
      };
    case actions.SET_SET_QUOTATION_ITEM_ERROR:
      return {
        ...state,
        setItemError: action.payload
      };
    case actions.SET_FETCH_QUOTATION_ITEMS_ERROR:
      return {
        ...state,
        fetchItemError: action.payload
      };
    default:
      return state;
  }
}