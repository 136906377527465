import {UserAddress} from "../types/models/user_address";
import * as _ from 'lodash';

export interface Country {
  countryId: string;
  displayValue: string;
  stateDisplayValue: null | string;
  cityDisplayValue: null | string;
  postalCodeRequired: boolean;
  stateRequired: boolean;
  postalCodeRegex: null | string;
  displayFormat: string;
  states: Record<string, CountryState>;
}

export interface CountryState {
  stateValue: string;
  displayValue: string;
}

interface CountryAndState {
  countries: {
    [countryCode: string]: Country;
  };
}

// source: https://github.com/Shopify/quilt/tree/main/packages/address-mocks/src/fixtures

const countriesAndStates: CountryAndState = {
  "countries": {
    "AF": {
      "countryId": "AF",
      "displayValue": "Afghanistan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^\\d{4}$"
    },
    "AX": {
      "countryId": "AX",
      "displayValue": "├àland Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^\\d{5}$"
    },
    "AL": {
      "countryId": "AL",
      "displayValue": "Albania",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "DZ": {
      "countryId": "DZ",
      "displayValue": "Algeria",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "AD": {
      "countryId": "AD",
      "displayValue": "Andorra",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "AD\\d{3}"
    },
    "AO": {
      "countryId": "AO",
      "displayValue": "Angola",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "AI": {
      "countryId": "AI",
      "displayValue": "Anguilla",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(AI-2640)"
    },
    "AG": {
      "countryId": "AG",
      "displayValue": "Antigua & Barbuda",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "AR": {
      "countryId": "AR",
      "displayValue": "Argentina",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "B": {
          "displayValue": "Buenos Aires Province",
          "stateValue": "B"
        },
        "K": {
          "displayValue": "Catamarca",
          "stateValue": "K"
        },
        "H": {
          "displayValue": "Chaco",
          "stateValue": "H"
        },
        "U": {
          "displayValue": "Chubut",
          "stateValue": "U"
        },
        "C": {
          "displayValue": "Buenos Aires (Autonomous City)",
          "stateValue": "C"
        },
        "X": {
          "displayValue": "C├│rdoba",
          "stateValue": "X"
        },
        "W": {
          "displayValue": "Corrientes",
          "stateValue": "W"
        },
        "E": {
          "displayValue": "Entre R├¡os",
          "stateValue": "E"
        },
        "P": {
          "displayValue": "Formosa",
          "stateValue": "P"
        },
        "Y": {
          "displayValue": "Jujuy",
          "stateValue": "Y"
        },
        "L": {
          "displayValue": "La Pampa",
          "stateValue": "L"
        },
        "F": {
          "displayValue": "La Rioja",
          "stateValue": "F"
        },
        "M": {
          "displayValue": "Mendoza",
          "stateValue": "M"
        },
        "N": {
          "displayValue": "Misiones",
          "stateValue": "N"
        },
        "Q": {
          "displayValue": "Neuqu├®n",
          "stateValue": "Q"
        },
        "R": {
          "displayValue": "R├¡o Negro",
          "stateValue": "R"
        },
        "A": {
          "displayValue": "Salta",
          "stateValue": "A"
        },
        "J": {
          "displayValue": "San Juan",
          "stateValue": "J"
        },
        "D": {
          "displayValue": "San Luis",
          "stateValue": "D"
        },
        "Z": {
          "displayValue": "Santa Cruz",
          "stateValue": "Z"
        },
        "S": {
          "displayValue": "Santa Fe",
          "stateValue": "S"
        },
        "G": {
          "displayValue": "Santiago del Estero",
          "stateValue": "G"
        },
        "V": {
          "displayValue": "Tierra del Fuego",
          "stateValue": "V"
        },
        "T": {
          "displayValue": "Tucum├ín",
          "stateValue": "T"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "([A-Z]\\d{4}[A-Z]{3})|([A-Z]\\d{4})"
    },
    "AM": {
      "countryId": "AM",
      "displayValue": "Armenia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(\\d{4})|(\\d{6})"
    },
    "AW": {
      "countryId": "AW",
      "displayValue": "Aruba",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "AC": {
      "countryId": "AC",
      "displayValue": "Ascension Island",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^[Aa][Ss][Cc][Nn]\\s{0,1}[1][Zz][Zz]$"
    },
    "AU": {
      "countryId": "AU",
      "displayValue": "Australia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province} {zip}_{country}_{phone}",
      "cityDisplayValue": "Suburb",
      "stateDisplayValue": "State/territory",
      "stateRequired": true,
      "states": {
        "ACT": {
          "displayValue": "Australian Capital Territory",
          "stateValue": "ACT"
        },
        "NSW": {
          "displayValue": "New South Wales",
          "stateValue": "NSW"
        },
        "NT": {
          "displayValue": "Northern Territory",
          "stateValue": "NT"
        },
        "QLD": {
          "displayValue": "Queensland",
          "stateValue": "QLD"
        },
        "SA": {
          "displayValue": "South Australia",
          "stateValue": "SA"
        },
        "TAS": {
          "displayValue": "Tasmania",
          "stateValue": "TAS"
        },
        "VIC": {
          "displayValue": "Victoria",
          "stateValue": "VIC"
        },
        "WA": {
          "displayValue": "Western Australia",
          "stateValue": "WA"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "AT": {
      "countryId": "AT",
      "displayValue": "Austria",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "AZ": {
      "countryId": "AZ",
      "displayValue": "Azerbaijan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(AZ)(\\d{4})|(AZ )(\\d{4})"
    },
    "BS": {
      "countryId": "BS",
      "displayValue": "Bahamas",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "BH": {
      "countryId": "BH",
      "displayValue": "Bahrain",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{3}\\d?"
    },
    "BD": {
      "countryId": "BD",
      "displayValue": "Bangladesh",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "BB": {
      "countryId": "BB",
      "displayValue": "Barbados",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "BB\\d{5}"
    },
    "BY": {
      "countryId": "BY",
      "displayValue": "Belarus",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "BE": {
      "countryId": "BE",
      "displayValue": "Belgium",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "BZ": {
      "countryId": "BZ",
      "displayValue": "Belize",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "BJ": {
      "countryId": "BJ",
      "displayValue": "Benin",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "BM": {
      "countryId": "BM",
      "displayValue": "Bermuda",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[A-Z]{2} \\d{2}"
    },
    "BT": {
      "countryId": "BT",
      "displayValue": "Bhutan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "BO": {
      "countryId": "BO",
      "displayValue": "Bolivia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "BA": {
      "countryId": "BA",
      "displayValue": "Bosnia & Herzegovina",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "BW": {
      "countryId": "BW",
      "displayValue": "Botswana",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "BV": {
      "countryId": "BV",
      "displayValue": "Bouvet Island",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": null
    },
    "BR": {
      "countryId": "BR",
      "displayValue": "Brazil",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "State",
      "stateRequired": true,
      "states": {
        "AC": {
          "displayValue": "Acre",
          "stateValue": "AC"
        },
        "AL": {
          "displayValue": "Alagoas",
          "stateValue": "AL"
        },
        "AP": {
          "displayValue": "Amap├í",
          "stateValue": "AP"
        },
        "AM": {
          "displayValue": "Amazonas",
          "stateValue": "AM"
        },
        "BA": {
          "displayValue": "Bahia",
          "stateValue": "BA"
        },
        "CE": {
          "displayValue": "Cear├í",
          "stateValue": "CE"
        },
        "DF": {
          "displayValue": "Federal District",
          "stateValue": "DF"
        },
        "ES": {
          "displayValue": "Esp├¡rito Santo",
          "stateValue": "ES"
        },
        "GO": {
          "displayValue": "Goi├ís",
          "stateValue": "GO"
        },
        "MA": {
          "displayValue": "Maranh├úo",
          "stateValue": "MA"
        },
        "MT": {
          "displayValue": "Mato Grosso",
          "stateValue": "MT"
        },
        "MS": {
          "displayValue": "Mato Grosso do Sul",
          "stateValue": "MS"
        },
        "MG": {
          "displayValue": "Minas Gerais",
          "stateValue": "MG"
        },
        "PA": {
          "displayValue": "Par├í",
          "stateValue": "PA"
        },
        "PB": {
          "displayValue": "Para├¡ba",
          "stateValue": "PB"
        },
        "PR": {
          "displayValue": "Paran├í",
          "stateValue": "PR"
        },
        "PE": {
          "displayValue": "Pernambuco",
          "stateValue": "PE"
        },
        "PI": {
          "displayValue": "Piau├¡",
          "stateValue": "PI"
        },
        "RN": {
          "displayValue": "Rio Grande do Norte",
          "stateValue": "RN"
        },
        "RS": {
          "displayValue": "Rio Grande do Sul",
          "stateValue": "RS"
        },
        "RJ": {
          "displayValue": "Rio de Janeiro",
          "stateValue": "RJ"
        },
        "RO": {
          "displayValue": "Rond├┤nia",
          "stateValue": "RO"
        },
        "RR": {
          "displayValue": "Roraima",
          "stateValue": "RR"
        },
        "SC": {
          "displayValue": "Santa Catarina",
          "stateValue": "SC"
        },
        "SP": {
          "displayValue": "S├úo Paulo",
          "stateValue": "SP"
        },
        "SE": {
          "displayValue": "Sergipe",
          "stateValue": "SE"
        },
        "TO": {
          "displayValue": "Tocantins",
          "stateValue": "TO"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "[0-9]{5}-[0-9]{3}"
    },
    "IO": {
      "countryId": "IO",
      "displayValue": "British Indian Ocean Territory",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(BB9D 1ZZ)"
    },
    "VG": {
      "countryId": "VG",
      "displayValue": "British Virgin Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(VG11)[0-6][0]"
    },
    "BN": {
      "countryId": "BN",
      "displayValue": "Brunei",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[A-Z]{2}\\d{4}"
    },
    "BG": {
      "countryId": "BG",
      "displayValue": "Bulgaria",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "BF": {
      "countryId": "BF",
      "displayValue": "Burkina Faso",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{4}"
    },
    "BI": {
      "countryId": "BI",
      "displayValue": "Burundi",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "KH": {
      "countryId": "KH",
      "displayValue": "Cambodia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5,6}"
    },
    "CM": {
      "countryId": "CM",
      "displayValue": "Cameroon",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "CA": {
      "countryId": "CA",
      "displayValue": "Canada",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "AB": {
          "displayValue": "Alberta",
          "stateValue": "AB"
        },
        "BC": {
          "displayValue": "British Columbia",
          "stateValue": "BC"
        },
        "MB": {
          "displayValue": "Manitoba",
          "stateValue": "MB"
        },
        "NB": {
          "displayValue": "New Brunswick",
          "stateValue": "NB"
        },
        "NL": {
          "displayValue": "Newfoundland and Labrador",
          "stateValue": "NL"
        },
        "NT": {
          "displayValue": "Northwest Territories",
          "stateValue": "NT"
        },
        "NS": {
          "displayValue": "Nova Scotia",
          "stateValue": "NS"
        },
        "NU": {
          "displayValue": "Nunavut",
          "stateValue": "NU"
        },
        "ON": {
          "displayValue": "Ontario",
          "stateValue": "ON"
        },
        "PE": {
          "displayValue": "Prince Edward Island",
          "stateValue": "PE"
        },
        "QC": {
          "displayValue": "Quebec",
          "stateValue": "QC"
        },
        "SK": {
          "displayValue": "Saskatchewan",
          "stateValue": "SK"
        },
        "YT": {
          "displayValue": "Yukon",
          "stateValue": "YT"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "[A-Z][0-9][A-Z] [0-9][A-Z][0-9]"
    },
    "CV": {
      "countryId": "CV",
      "displayValue": "Cape Verde",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "BQ": {
      "countryId": "BQ",
      "displayValue": "Caribbean Netherlands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "KY": {
      "countryId": "KY",
      "displayValue": "Cayman Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[K][Y][0-9]{1}[-]([0-9]){4}"
    },
    "CF": {
      "countryId": "CF",
      "displayValue": "Central African Republic",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "TD": {
      "countryId": "TD",
      "displayValue": "Chad",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "CL": {
      "countryId": "CL",
      "displayValue": "Chile",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "AP": {
          "displayValue": "Arica y Parinacota",
          "stateValue": "AP"
        },
        "TA": {
          "displayValue": "Tarapac├í",
          "stateValue": "TA"
        },
        "AN": {
          "displayValue": "Antofagasta",
          "stateValue": "AN"
        },
        "AT": {
          "displayValue": "Atacama",
          "stateValue": "AT"
        },
        "CO": {
          "displayValue": "Coquimbo",
          "stateValue": "CO"
        },
        "VS": {
          "displayValue": "Valpara├¡so",
          "stateValue": "VS"
        },
        "RM": {
          "displayValue": "Santiago Metropolitan",
          "stateValue": "RM"
        },
        "LI": {
          "displayValue": "Libertador General Bernardo OÔÇÖHiggins",
          "stateValue": "LI"
        },
        "ML": {
          "displayValue": "Maule",
          "stateValue": "ML"
        },
        "NB": {
          "displayValue": "├æuble",
          "stateValue": "NB"
        },
        "BI": {
          "displayValue": "B├¡o B├¡o",
          "stateValue": "BI"
        },
        "AR": {
          "displayValue": "Araucan├¡a",
          "stateValue": "AR"
        },
        "LR": {
          "displayValue": "Los R├¡os",
          "stateValue": "LR"
        },
        "LL": {
          "displayValue": "Los Lagos",
          "stateValue": "LL"
        },
        "AI": {
          "displayValue": "Ays├®n",
          "stateValue": "AI"
        },
        "MA": {
          "displayValue": "Magallanes Region",
          "stateValue": "MA"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{7}"
    },
    "CN": {
      "countryId": "CN",
      "displayValue": "China",
      "displayFormat": "{firstName} {lastName}_{company}_{address1} {address2} {city}_{zip} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "AH": {
          "displayValue": "Anhui",
          "stateValue": "AH"
        },
        "BJ": {
          "displayValue": "Beijing",
          "stateValue": "BJ"
        },
        "CQ": {
          "displayValue": "Chongqing",
          "stateValue": "CQ"
        },
        "FJ": {
          "displayValue": "Fujian",
          "stateValue": "FJ"
        },
        "GS": {
          "displayValue": "Gansu",
          "stateValue": "GS"
        },
        "GD": {
          "displayValue": "Guangdong",
          "stateValue": "GD"
        },
        "GX": {
          "displayValue": "Guangxi",
          "stateValue": "GX"
        },
        "GZ": {
          "displayValue": "Guizhou",
          "stateValue": "GZ"
        },
        "HI": {
          "displayValue": "Hainan",
          "stateValue": "HI"
        },
        "HE": {
          "displayValue": "Hebei",
          "stateValue": "HE"
        },
        "HL": {
          "displayValue": "Heilongjiang",
          "stateValue": "HL"
        },
        "HA": {
          "displayValue": "Henan",
          "stateValue": "HA"
        },
        "HB": {
          "displayValue": "Hubei",
          "stateValue": "HB"
        },
        "HN": {
          "displayValue": "Hunan",
          "stateValue": "HN"
        },
        "NM": {
          "displayValue": "Inner Mongolia",
          "stateValue": "NM"
        },
        "JS": {
          "displayValue": "Jiangsu",
          "stateValue": "JS"
        },
        "JX": {
          "displayValue": "Jiangxi",
          "stateValue": "JX"
        },
        "JL": {
          "displayValue": "Jilin",
          "stateValue": "JL"
        },
        "LN": {
          "displayValue": "Liaoning",
          "stateValue": "LN"
        },
        "NX": {
          "displayValue": "Ningxia",
          "stateValue": "NX"
        },
        "QH": {
          "displayValue": "Qinghai",
          "stateValue": "QH"
        },
        "SN": {
          "displayValue": "Shaanxi",
          "stateValue": "SN"
        },
        "SD": {
          "displayValue": "Shandong",
          "stateValue": "SD"
        },
        "SH": {
          "displayValue": "Shanghai",
          "stateValue": "SH"
        },
        "SX": {
          "displayValue": "Shanxi",
          "stateValue": "SX"
        },
        "SC": {
          "displayValue": "Sichuan",
          "stateValue": "SC"
        },
        "TJ": {
          "displayValue": "Tianjin",
          "stateValue": "TJ"
        },
        "XJ": {
          "displayValue": "Xinjiang",
          "stateValue": "XJ"
        },
        "YZ": {
          "displayValue": "Tibet",
          "stateValue": "YZ"
        },
        "YN": {
          "displayValue": "Yunnan",
          "stateValue": "YN"
        },
        "ZJ": {
          "displayValue": "Zhejiang",
          "stateValue": "ZJ"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "CX": {
      "countryId": "CX",
      "displayValue": "Christmas Island",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(6798)"
    },
    "CC": {
      "countryId": "CC",
      "displayValue": "Cocos (Keeling) Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(6799)"
    },
    "CO": {
      "countryId": "CO",
      "displayValue": "Colombia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "DC": {
          "displayValue": "Capital District",
          "stateValue": "DC"
        },
        "AMA": {
          "displayValue": "Amazonas",
          "stateValue": "AMA"
        },
        "ANT": {
          "displayValue": "Antioquia",
          "stateValue": "ANT"
        },
        "ARA": {
          "displayValue": "Arauca",
          "stateValue": "ARA"
        },
        "ATL": {
          "displayValue": "Atl├íntico",
          "stateValue": "ATL"
        },
        "BOL": {
          "displayValue": "Bol├¡var",
          "stateValue": "BOL"
        },
        "BOY": {
          "displayValue": "Boyac├í",
          "stateValue": "BOY"
        },
        "CAL": {
          "displayValue": "Caldas",
          "stateValue": "CAL"
        },
        "CAQ": {
          "displayValue": "Caquet├í",
          "stateValue": "CAQ"
        },
        "CAS": {
          "displayValue": "Casanare",
          "stateValue": "CAS"
        },
        "CAU": {
          "displayValue": "Cauca",
          "stateValue": "CAU"
        },
        "CES": {
          "displayValue": "Cesar",
          "stateValue": "CES"
        },
        "CHO": {
          "displayValue": "Choc├│",
          "stateValue": "CHO"
        },
        "COR": {
          "displayValue": "C├│rdoba",
          "stateValue": "COR"
        },
        "CUN": {
          "displayValue": "Cundinamarca",
          "stateValue": "CUN"
        },
        "GUA": {
          "displayValue": "Guain├¡a",
          "stateValue": "GUA"
        },
        "GUV": {
          "displayValue": "Guaviare",
          "stateValue": "GUV"
        },
        "HUI": {
          "displayValue": "Huila",
          "stateValue": "HUI"
        },
        "LAG": {
          "displayValue": "La Guajira",
          "stateValue": "LAG"
        },
        "MAG": {
          "displayValue": "Magdalena",
          "stateValue": "MAG"
        },
        "MET": {
          "displayValue": "Meta",
          "stateValue": "MET"
        },
        "NAR": {
          "displayValue": "Nari├▒o",
          "stateValue": "NAR"
        },
        "NSA": {
          "displayValue": "Norte de Santander",
          "stateValue": "NSA"
        },
        "PUT": {
          "displayValue": "Putumayo",
          "stateValue": "PUT"
        },
        "QUI": {
          "displayValue": "Quind├¡o",
          "stateValue": "QUI"
        },
        "RIS": {
          "displayValue": "Risaralda",
          "stateValue": "RIS"
        },
        "SAP": {
          "displayValue": "San Andr├®s & Providencia",
          "stateValue": "SAP"
        },
        "SAN": {
          "displayValue": "Santander",
          "stateValue": "SAN"
        },
        "SUC": {
          "displayValue": "Sucre",
          "stateValue": "SUC"
        },
        "TOL": {
          "displayValue": "Tolima",
          "stateValue": "TOL"
        },
        "VAC": {
          "displayValue": "Valle del Cauca",
          "stateValue": "VAC"
        },
        "VAU": {
          "displayValue": "Vaup├®s",
          "stateValue": "VAU"
        },
        "VID": {
          "displayValue": "Vichada",
          "stateValue": "VID"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "KM": {
      "countryId": "KM",
      "displayValue": "Comoros",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "CG": {
      "countryId": "CG",
      "displayValue": "Congo - Brazzaville",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "CD": {
      "countryId": "CD",
      "displayValue": "Congo - Kinshasa",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "CK": {
      "countryId": "CK",
      "displayValue": "Cook Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "CR": {
      "countryId": "CR",
      "displayValue": "Costa Rica",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "HR": {
      "countryId": "HR",
      "displayValue": "Croatia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-5]\\d{4}"
    },
    "CW": {
      "countryId": "CW",
      "displayValue": "Cura├ºao",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "CY": {
      "countryId": "CY",
      "displayValue": "Cyprus",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{3}"
    },
    "CZ": {
      "countryId": "CZ",
      "displayValue": "Czechia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-7][0-9]{2} [0-9]{2}|[1-7][0-9]{4}"
    },
    "CI": {
      "countryId": "CI",
      "displayValue": "C├┤te dÔÇÖIvoire",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "DK": {
      "countryId": "DK",
      "displayValue": "Denmark",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "DJ": {
      "countryId": "DJ",
      "displayValue": "Djibouti",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "DM": {
      "countryId": "DM",
      "displayValue": "Dominica",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "DO": {
      "countryId": "DO",
      "displayValue": "Dominican Republic",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "EC": {
      "countryId": "EC",
      "displayValue": "Ecuador",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "EG": {
      "countryId": "EG",
      "displayValue": "Egypt",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{province}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Governorate",
      "stateRequired": true,
      "states": {
        "SU": {
          "displayValue": "6th of October",
          "stateValue": "SU"
        },
        "SHR": {
          "displayValue": "Al Sharqia",
          "stateValue": "SHR"
        },
        "ALX": {
          "displayValue": "Alexandria",
          "stateValue": "ALX"
        },
        "ASN": {
          "displayValue": "Aswan",
          "stateValue": "ASN"
        },
        "AST": {
          "displayValue": "Asyut",
          "stateValue": "AST"
        },
        "BH": {
          "displayValue": "Beheira",
          "stateValue": "BH"
        },
        "BNS": {
          "displayValue": "Beni Suef",
          "stateValue": "BNS"
        },
        "C": {
          "displayValue": "Cairo",
          "stateValue": "C"
        },
        "DK": {
          "displayValue": "Dakahlia",
          "stateValue": "DK"
        },
        "DT": {
          "displayValue": "Damietta",
          "stateValue": "DT"
        },
        "FYM": {
          "displayValue": "Faiyum",
          "stateValue": "FYM"
        },
        "GH": {
          "displayValue": "Gharbia",
          "stateValue": "GH"
        },
        "GZ": {
          "displayValue": "Giza",
          "stateValue": "GZ"
        },
        "HU": {
          "displayValue": "Helwan",
          "stateValue": "HU"
        },
        "IS": {
          "displayValue": "Ismailia",
          "stateValue": "IS"
        },
        "KFS": {
          "displayValue": "Kafr el-Sheikh",
          "stateValue": "KFS"
        },
        "LX": {
          "displayValue": "Luxor",
          "stateValue": "LX"
        },
        "MT": {
          "displayValue": "Matrouh",
          "stateValue": "MT"
        },
        "MN": {
          "displayValue": "Minya",
          "stateValue": "MN"
        },
        "MNF": {
          "displayValue": "Monufia",
          "stateValue": "MNF"
        },
        "WAD": {
          "displayValue": "New Valley",
          "stateValue": "WAD"
        },
        "SIN": {
          "displayValue": "North Sinai",
          "stateValue": "SIN"
        },
        "PTS": {
          "displayValue": "Port Said",
          "stateValue": "PTS"
        },
        "KB": {
          "displayValue": "Qalyubia",
          "stateValue": "KB"
        },
        "KN": {
          "displayValue": "Qena",
          "stateValue": "KN"
        },
        "BA": {
          "displayValue": "Red Sea",
          "stateValue": "BA"
        },
        "SHG": {
          "displayValue": "Sohag",
          "stateValue": "SHG"
        },
        "JS": {
          "displayValue": "South Sinai",
          "stateValue": "JS"
        },
        "SUZ": {
          "displayValue": "Suez",
          "stateValue": "SUZ"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "SV": {
      "countryId": "SV",
      "displayValue": "El Salvador",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "GQ": {
      "countryId": "GQ",
      "displayValue": "Equatorial Guinea",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "ER": {
      "countryId": "ER",
      "displayValue": "Eritrea",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "EE": {
      "countryId": "EE",
      "displayValue": "Estonia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "SZ": {
      "countryId": "SZ",
      "displayValue": "Eswatini",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "([A-Z]\\d{3})"
    },
    "ET": {
      "countryId": "ET",
      "displayValue": "Ethiopia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "FK": {
      "countryId": "FK",
      "displayValue": "Falkland Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(FIQQ 1ZZ)"
    },
    "FO": {
      "countryId": "FO",
      "displayValue": "Faroe Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{3}"
    },
    "FJ": {
      "countryId": "FJ",
      "displayValue": "Fiji",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "FI": {
      "countryId": "FI",
      "displayValue": "Finland",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "FR": {
      "countryId": "FR",
      "displayValue": "France",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "GF": {
      "countryId": "GF",
      "displayValue": "French Guiana",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^973\\d{2}$"
    },
    "PF": {
      "countryId": "PF",
      "displayValue": "French Polynesia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "((987)\\d{2})"
    },
    "TF": {
      "countryId": "TF",
      "displayValue": "French Southern Territories",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "GA": {
      "countryId": "GA",
      "displayValue": "Gabon",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "GM": {
      "countryId": "GM",
      "displayValue": "Gambia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "GE": {
      "countryId": "GE",
      "displayValue": "Georgia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "DE": {
      "countryId": "DE",
      "displayValue": "Germany",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "GH": {
      "countryId": "GH",
      "displayValue": "Ghana",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "GI": {
      "countryId": "GI",
      "displayValue": "Gibraltar",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(GX11 1AA)"
    },
    "GR": {
      "countryId": "GR",
      "displayValue": "Greece",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(\\d{3}) \\d{2}|\\d{5}"
    },
    "GL": {
      "countryId": "GL",
      "displayValue": "Greenland",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "39\\d{2}"
    },
    "GD": {
      "countryId": "GD",
      "displayValue": "Grenada",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "GP": {
      "countryId": "GP",
      "displayValue": "Guadeloupe",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^971\\d{2}$"
    },
    "GT": {
      "countryId": "GT",
      "displayValue": "Guatemala",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "AVE": {
          "displayValue": "Alta Verapaz",
          "stateValue": "AVE"
        },
        "BVE": {
          "displayValue": "Baja Verapaz",
          "stateValue": "BVE"
        },
        "CMT": {
          "displayValue": "Chimaltenango",
          "stateValue": "CMT"
        },
        "CQM": {
          "displayValue": "Chiquimula",
          "stateValue": "CQM"
        },
        "EPR": {
          "displayValue": "El Progreso",
          "stateValue": "EPR"
        },
        "ESC": {
          "displayValue": "Escuintla",
          "stateValue": "ESC"
        },
        "GUA": {
          "displayValue": "Guatemala",
          "stateValue": "GUA"
        },
        "HUE": {
          "displayValue": "Huehuetenango",
          "stateValue": "HUE"
        },
        "IZA": {
          "displayValue": "Izabal",
          "stateValue": "IZA"
        },
        "JAL": {
          "displayValue": "Jalapa",
          "stateValue": "JAL"
        },
        "JUT": {
          "displayValue": "Jutiapa",
          "stateValue": "JUT"
        },
        "PET": {
          "displayValue": "Pet├®n",
          "stateValue": "PET"
        },
        "QUE": {
          "displayValue": "Quetzaltenango",
          "stateValue": "QUE"
        },
        "QUI": {
          "displayValue": "Quich├®",
          "stateValue": "QUI"
        },
        "RET": {
          "displayValue": "Retalhuleu",
          "stateValue": "RET"
        },
        "SAC": {
          "displayValue": "Sacatep├®quez",
          "stateValue": "SAC"
        },
        "SMA": {
          "displayValue": "San Marcos",
          "stateValue": "SMA"
        },
        "SRO": {
          "displayValue": "Santa Rosa",
          "stateValue": "SRO"
        },
        "SOL": {
          "displayValue": "Solol├í",
          "stateValue": "SOL"
        },
        "SUC": {
          "displayValue": "Suchitep├®quez",
          "stateValue": "SUC"
        },
        "TOT": {
          "displayValue": "Totonicap├ín",
          "stateValue": "TOT"
        },
        "ZAC": {
          "displayValue": "Zacapa",
          "stateValue": "ZAC"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "GG": {
      "countryId": "GG",
      "displayValue": "Guernsey",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(GY)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}"
    },
    "GN": {
      "countryId": "GN",
      "displayValue": "Guinea",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{3}"
    },
    "GW": {
      "countryId": "GW",
      "displayValue": "Guinea-Bissau",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "GY": {
      "countryId": "GY",
      "displayValue": "Guyana",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "HT": {
      "countryId": "HT",
      "displayValue": "Haiti",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(HT)(\\d{4})|(HT) (\\d{4})"
    },
    "HM": {
      "countryId": "HM",
      "displayValue": "Heard & McDonald Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(7151)"
    },
    "HN": {
      "countryId": "HN",
      "displayValue": "Honduras",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "HK": {
      "countryId": "HK",
      "displayValue": "Hong Kong SAR",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province} {country}_{phone}",
      "cityDisplayValue": "District",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "HK": {
          "displayValue": "Hong Kong Island",
          "stateValue": "HK"
        },
        "KL": {
          "displayValue": "Kowloon",
          "stateValue": "KL"
        },
        "NT": {
          "displayValue": "New Territories",
          "stateValue": "NT"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "(999077)"
    },
    "HU": {
      "countryId": "HU",
      "displayValue": "Hungary",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{3}"
    },
    "IS": {
      "countryId": "IS",
      "displayValue": "Iceland",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{2}"
    },
    "IN": {
      "countryId": "IN",
      "displayValue": "India",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "State",
      "stateRequired": true,
      "states": {
        "AN": {
          "displayValue": "Andaman and Nicobar Islands",
          "stateValue": "AN"
        },
        "AP": {
          "displayValue": "Andhra Pradesh",
          "stateValue": "AP"
        },
        "AR": {
          "displayValue": "Arunachal Pradesh",
          "stateValue": "AR"
        },
        "AS": {
          "displayValue": "Assam",
          "stateValue": "AS"
        },
        "BR": {
          "displayValue": "Bihar",
          "stateValue": "BR"
        },
        "CH": {
          "displayValue": "Chandigarh",
          "stateValue": "CH"
        },
        "CG": {
          "displayValue": "Chhattisgarh",
          "stateValue": "CG"
        },
        "DN": {
          "displayValue": "Dadra and Nagar Haveli",
          "stateValue": "DN"
        },
        "DD": {
          "displayValue": "Daman and Diu",
          "stateValue": "DD"
        },
        "DL": {
          "displayValue": "Delhi",
          "stateValue": "DL"
        },
        "GA": {
          "displayValue": "Goa",
          "stateValue": "GA"
        },
        "GJ": {
          "displayValue": "Gujarat",
          "stateValue": "GJ"
        },
        "HR": {
          "displayValue": "Haryana",
          "stateValue": "HR"
        },
        "HP": {
          "displayValue": "Himachal Pradesh",
          "stateValue": "HP"
        },
        "JK": {
          "displayValue": "Jammu and Kashmir",
          "stateValue": "JK"
        },
        "JH": {
          "displayValue": "Jharkhand",
          "stateValue": "JH"
        },
        "KA": {
          "displayValue": "Karnataka",
          "stateValue": "KA"
        },
        "KL": {
          "displayValue": "Kerala",
          "stateValue": "KL"
        },
        "LA": {
          "displayValue": "Ladakh",
          "stateValue": "LA"
        },
        "LD": {
          "displayValue": "Lakshadweep",
          "stateValue": "LD"
        },
        "MP": {
          "displayValue": "Madhya Pradesh",
          "stateValue": "MP"
        },
        "MH": {
          "displayValue": "Maharashtra",
          "stateValue": "MH"
        },
        "MN": {
          "displayValue": "Manipur",
          "stateValue": "MN"
        },
        "ML": {
          "displayValue": "Meghalaya",
          "stateValue": "ML"
        },
        "MZ": {
          "displayValue": "Mizoram",
          "stateValue": "MZ"
        },
        "NL": {
          "displayValue": "Nagaland",
          "stateValue": "NL"
        },
        "OR": {
          "displayValue": "Odisha",
          "stateValue": "OR"
        },
        "PY": {
          "displayValue": "Puducherry",
          "stateValue": "PY"
        },
        "PB": {
          "displayValue": "Punjab",
          "stateValue": "PB"
        },
        "RJ": {
          "displayValue": "Rajasthan",
          "stateValue": "RJ"
        },
        "SK": {
          "displayValue": "Sikkim",
          "stateValue": "SK"
        },
        "TN": {
          "displayValue": "Tamil Nadu",
          "stateValue": "TN"
        },
        "TS": {
          "displayValue": "Telangana",
          "stateValue": "TS"
        },
        "TR": {
          "displayValue": "Tripura",
          "stateValue": "TR"
        },
        "UP": {
          "displayValue": "Uttar Pradesh",
          "stateValue": "UP"
        },
        "UK": {
          "displayValue": "Uttarakhand",
          "stateValue": "UK"
        },
        "WB": {
          "displayValue": "West Bengal",
          "stateValue": "WB"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{5}"
    },
    "ID": {
      "countryId": "ID",
      "displayValue": "Indonesia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "AC": {
          "displayValue": "Aceh",
          "stateValue": "AC"
        },
        "BA": {
          "displayValue": "Bali",
          "stateValue": "BA"
        },
        "BB": {
          "displayValue": "BangkaÔÇôBelitung Islands",
          "stateValue": "BB"
        },
        "BT": {
          "displayValue": "Banten",
          "stateValue": "BT"
        },
        "BE": {
          "displayValue": "Bengkulu",
          "stateValue": "BE"
        },
        "GO": {
          "displayValue": "Gorontalo",
          "stateValue": "GO"
        },
        "JK": {
          "displayValue": "Jakarta",
          "stateValue": "JK"
        },
        "JA": {
          "displayValue": "Jambi",
          "stateValue": "JA"
        },
        "JB": {
          "displayValue": "West Java",
          "stateValue": "JB"
        },
        "JT": {
          "displayValue": "Central Java",
          "stateValue": "JT"
        },
        "JI": {
          "displayValue": "East Java",
          "stateValue": "JI"
        },
        "KB": {
          "displayValue": "West Kalimantan",
          "stateValue": "KB"
        },
        "KS": {
          "displayValue": "South Kalimantan",
          "stateValue": "KS"
        },
        "KT": {
          "displayValue": "Central Kalimantan",
          "stateValue": "KT"
        },
        "KI": {
          "displayValue": "East Kalimantan",
          "stateValue": "KI"
        },
        "KU": {
          "displayValue": "North Kalimantan",
          "stateValue": "KU"
        },
        "KR": {
          "displayValue": "Riau Islands",
          "stateValue": "KR"
        },
        "LA": {
          "displayValue": "Lampung",
          "stateValue": "LA"
        },
        "MA": {
          "displayValue": "Maluku",
          "stateValue": "MA"
        },
        "MU": {
          "displayValue": "North Maluku",
          "stateValue": "MU"
        },
        "SU": {
          "displayValue": "North Sumatra",
          "stateValue": "SU"
        },
        "NB": {
          "displayValue": "West Nusa Tenggara",
          "stateValue": "NB"
        },
        "NT": {
          "displayValue": "East Nusa Tenggara",
          "stateValue": "NT"
        },
        "PA": {
          "displayValue": "Papua",
          "stateValue": "PA"
        },
        "PB": {
          "displayValue": "West Papua",
          "stateValue": "PB"
        },
        "RI": {
          "displayValue": "Riau",
          "stateValue": "RI"
        },
        "SS": {
          "displayValue": "South Sumatra",
          "stateValue": "SS"
        },
        "SR": {
          "displayValue": "West Sulawesi",
          "stateValue": "SR"
        },
        "SN": {
          "displayValue": "South Sulawesi",
          "stateValue": "SN"
        },
        "ST": {
          "displayValue": "Central Sulawesi",
          "stateValue": "ST"
        },
        "SG": {
          "displayValue": "Southeast Sulawesi",
          "stateValue": "SG"
        },
        "SA": {
          "displayValue": "North Sulawesi",
          "stateValue": "SA"
        },
        "SB": {
          "displayValue": "West Sumatra",
          "stateValue": "SB"
        },
        "YO": {
          "displayValue": "Yogyakarta",
          "stateValue": "YO"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{4}"
    },
    "IQ": {
      "countryId": "IQ",
      "displayValue": "Iraq",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "IE": {
      "countryId": "IE",
      "displayValue": "Ireland",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "County",
      "stateRequired": true,
      "states": {
        "CW": {
          "displayValue": "Carlow",
          "stateValue": "CW"
        },
        "CN": {
          "displayValue": "Cavan",
          "stateValue": "CN"
        },
        "CE": {
          "displayValue": "Clare",
          "stateValue": "CE"
        },
        "CO": {
          "displayValue": "Cork",
          "stateValue": "CO"
        },
        "DL": {
          "displayValue": "Donegal",
          "stateValue": "DL"
        },
        "D": {
          "displayValue": "Dublin",
          "stateValue": "D"
        },
        "G": {
          "displayValue": "Galway",
          "stateValue": "G"
        },
        "KY": {
          "displayValue": "Kerry",
          "stateValue": "KY"
        },
        "KE": {
          "displayValue": "Kildare",
          "stateValue": "KE"
        },
        "KK": {
          "displayValue": "Kilkenny",
          "stateValue": "KK"
        },
        "LS": {
          "displayValue": "Laois",
          "stateValue": "LS"
        },
        "LM": {
          "displayValue": "Leitrim",
          "stateValue": "LM"
        },
        "LK": {
          "displayValue": "Limerick",
          "stateValue": "LK"
        },
        "LD": {
          "displayValue": "Longford",
          "stateValue": "LD"
        },
        "LH": {
          "displayValue": "Louth",
          "stateValue": "LH"
        },
        "MO": {
          "displayValue": "Mayo",
          "stateValue": "MO"
        },
        "MH": {
          "displayValue": "Meath",
          "stateValue": "MH"
        },
        "MN": {
          "displayValue": "Monaghan",
          "stateValue": "MN"
        },
        "OY": {
          "displayValue": "Offaly",
          "stateValue": "OY"
        },
        "RN": {
          "displayValue": "Roscommon",
          "stateValue": "RN"
        },
        "SO": {
          "displayValue": "Sligo",
          "stateValue": "SO"
        },
        "TA": {
          "displayValue": "Tipperary",
          "stateValue": "TA"
        },
        "WD": {
          "displayValue": "Waterford",
          "stateValue": "WD"
        },
        "WH": {
          "displayValue": "Westmeath",
          "stateValue": "WH"
        },
        "WX": {
          "displayValue": "Wexford",
          "stateValue": "WX"
        },
        "WW": {
          "displayValue": "Wicklow",
          "stateValue": "WW"
        }
      },
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "IM": {
      "countryId": "IM",
      "displayValue": "Isle of Man",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(IM)([0-9][0-9A-HJKPS-UW]?|[A-HK-Y][0-9][0-9ABEHMNPRV-Y]?) [0-9][ABD-HJLNP-UW-Z]{2}"
    },
    "IL": {
      "countryId": "IL",
      "displayValue": "Israel",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{7}"
    },
    "IT": {
      "countryId": "IT",
      "displayValue": "Italy",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "AG": {
          "displayValue": "Agrigento",
          "stateValue": "AG"
        },
        "AL": {
          "displayValue": "Alessandria",
          "stateValue": "AL"
        },
        "AN": {
          "displayValue": "Ancona",
          "stateValue": "AN"
        },
        "AO": {
          "displayValue": "Aosta",
          "stateValue": "AO"
        },
        "AR": {
          "displayValue": "Arezzo",
          "stateValue": "AR"
        },
        "AP": {
          "displayValue": "Ascoli Piceno",
          "stateValue": "AP"
        },
        "AT": {
          "displayValue": "Asti",
          "stateValue": "AT"
        },
        "AV": {
          "displayValue": "Avellino",
          "stateValue": "AV"
        },
        "BA": {
          "displayValue": "Bari",
          "stateValue": "BA"
        },
        "BT": {
          "displayValue": "Barletta-Andria-Trani",
          "stateValue": "BT"
        },
        "BL": {
          "displayValue": "Belluno",
          "stateValue": "BL"
        },
        "BN": {
          "displayValue": "Benevento",
          "stateValue": "BN"
        },
        "BG": {
          "displayValue": "Bergamo",
          "stateValue": "BG"
        },
        "BI": {
          "displayValue": "Biella",
          "stateValue": "BI"
        },
        "BO": {
          "displayValue": "Bologna",
          "stateValue": "BO"
        },
        "BZ": {
          "displayValue": "South Tyrol",
          "stateValue": "BZ"
        },
        "BS": {
          "displayValue": "Brescia",
          "stateValue": "BS"
        },
        "BR": {
          "displayValue": "Brindisi",
          "stateValue": "BR"
        },
        "CA": {
          "displayValue": "Cagliari",
          "stateValue": "CA"
        },
        "CL": {
          "displayValue": "Caltanissetta",
          "stateValue": "CL"
        },
        "CB": {
          "displayValue": "Campobasso",
          "stateValue": "CB"
        },
        "CI": {
          "displayValue": "Carbonia-Iglesias",
          "stateValue": "CI"
        },
        "CE": {
          "displayValue": "Caserta",
          "stateValue": "CE"
        },
        "CT": {
          "displayValue": "Catania",
          "stateValue": "CT"
        },
        "CZ": {
          "displayValue": "Catanzaro",
          "stateValue": "CZ"
        },
        "CH": {
          "displayValue": "Chieti",
          "stateValue": "CH"
        },
        "CO": {
          "displayValue": "Como",
          "stateValue": "CO"
        },
        "CS": {
          "displayValue": "Cosenza",
          "stateValue": "CS"
        },
        "CR": {
          "displayValue": "Cremona",
          "stateValue": "CR"
        },
        "KR": {
          "displayValue": "Crotone",
          "stateValue": "KR"
        },
        "CN": {
          "displayValue": "Cuneo",
          "stateValue": "CN"
        },
        "EN": {
          "displayValue": "Enna",
          "stateValue": "EN"
        },
        "FM": {
          "displayValue": "Fermo",
          "stateValue": "FM"
        },
        "FE": {
          "displayValue": "Ferrara",
          "stateValue": "FE"
        },
        "FI": {
          "displayValue": "Florence",
          "stateValue": "FI"
        },
        "FG": {
          "displayValue": "Foggia",
          "stateValue": "FG"
        },
        "FC": {
          "displayValue": "Forl├¼-Cesena",
          "stateValue": "FC"
        },
        "FR": {
          "displayValue": "Frosinone",
          "stateValue": "FR"
        },
        "GE": {
          "displayValue": "Genoa",
          "stateValue": "GE"
        },
        "GO": {
          "displayValue": "Gorizia",
          "stateValue": "GO"
        },
        "GR": {
          "displayValue": "Grosseto",
          "stateValue": "GR"
        },
        "IM": {
          "displayValue": "Imperia",
          "stateValue": "IM"
        },
        "IS": {
          "displayValue": "Isernia",
          "stateValue": "IS"
        },
        "AQ": {
          "displayValue": "LÔÇÖAquila",
          "stateValue": "AQ"
        },
        "SP": {
          "displayValue": "La Spezia",
          "stateValue": "SP"
        },
        "LT": {
          "displayValue": "Latina",
          "stateValue": "LT"
        },
        "LE": {
          "displayValue": "Lecce",
          "stateValue": "LE"
        },
        "LC": {
          "displayValue": "Lecco",
          "stateValue": "LC"
        },
        "LI": {
          "displayValue": "Livorno",
          "stateValue": "LI"
        },
        "LO": {
          "displayValue": "Lodi",
          "stateValue": "LO"
        },
        "LU": {
          "displayValue": "Lucca",
          "stateValue": "LU"
        },
        "MC": {
          "displayValue": "Macerata",
          "stateValue": "MC"
        },
        "MN": {
          "displayValue": "Mantua",
          "stateValue": "MN"
        },
        "MS": {
          "displayValue": "Massa and Carrara",
          "stateValue": "MS"
        },
        "MT": {
          "displayValue": "Matera",
          "stateValue": "MT"
        },
        "VS": {
          "displayValue": "Medio Campidano",
          "stateValue": "VS"
        },
        "ME": {
          "displayValue": "Messina",
          "stateValue": "ME"
        },
        "MI": {
          "displayValue": "Milan",
          "stateValue": "MI"
        },
        "MO": {
          "displayValue": "Modena",
          "stateValue": "MO"
        },
        "MB": {
          "displayValue": "Monza and Brianza",
          "stateValue": "MB"
        },
        "NA": {
          "displayValue": "Naples",
          "stateValue": "NA"
        },
        "NO": {
          "displayValue": "Novara",
          "stateValue": "NO"
        },
        "NU": {
          "displayValue": "Nuoro",
          "stateValue": "NU"
        },
        "OG": {
          "displayValue": "Ogliastra",
          "stateValue": "OG"
        },
        "OT": {
          "displayValue": "Olbia-Tempio",
          "stateValue": "OT"
        },
        "OR": {
          "displayValue": "Oristano",
          "stateValue": "OR"
        },
        "PD": {
          "displayValue": "Padua",
          "stateValue": "PD"
        },
        "PA": {
          "displayValue": "Palermo",
          "stateValue": "PA"
        },
        "PR": {
          "displayValue": "Parma",
          "stateValue": "PR"
        },
        "PV": {
          "displayValue": "Pavia",
          "stateValue": "PV"
        },
        "PG": {
          "displayValue": "Perugia",
          "stateValue": "PG"
        },
        "PU": {
          "displayValue": "Pesaro and Urbino",
          "stateValue": "PU"
        },
        "PE": {
          "displayValue": "Pescara",
          "stateValue": "PE"
        },
        "PC": {
          "displayValue": "Piacenza",
          "stateValue": "PC"
        },
        "PI": {
          "displayValue": "Pisa",
          "stateValue": "PI"
        },
        "PT": {
          "displayValue": "Pistoia",
          "stateValue": "PT"
        },
        "PN": {
          "displayValue": "Pordenone",
          "stateValue": "PN"
        },
        "PZ": {
          "displayValue": "Potenza",
          "stateValue": "PZ"
        },
        "PO": {
          "displayValue": "Prato",
          "stateValue": "PO"
        },
        "RG": {
          "displayValue": "Ragusa",
          "stateValue": "RG"
        },
        "RA": {
          "displayValue": "Ravenna",
          "stateValue": "RA"
        },
        "RC": {
          "displayValue": "Reggio Calabria",
          "stateValue": "RC"
        },
        "RE": {
          "displayValue": "Reggio Emilia",
          "stateValue": "RE"
        },
        "RI": {
          "displayValue": "Rieti",
          "stateValue": "RI"
        },
        "RN": {
          "displayValue": "Rimini",
          "stateValue": "RN"
        },
        "RM": {
          "displayValue": "Rome",
          "stateValue": "RM"
        },
        "RO": {
          "displayValue": "Rovigo",
          "stateValue": "RO"
        },
        "SA": {
          "displayValue": "Salerno",
          "stateValue": "SA"
        },
        "SS": {
          "displayValue": "Sassari",
          "stateValue": "SS"
        },
        "SV": {
          "displayValue": "Savona",
          "stateValue": "SV"
        },
        "SI": {
          "displayValue": "Siena",
          "stateValue": "SI"
        },
        "SR": {
          "displayValue": "Syracuse",
          "stateValue": "SR"
        },
        "SO": {
          "displayValue": "Sondrio",
          "stateValue": "SO"
        },
        "TA": {
          "displayValue": "Taranto",
          "stateValue": "TA"
        },
        "TE": {
          "displayValue": "Teramo",
          "stateValue": "TE"
        },
        "TR": {
          "displayValue": "Terni",
          "stateValue": "TR"
        },
        "TO": {
          "displayValue": "Turin",
          "stateValue": "TO"
        },
        "TP": {
          "displayValue": "Trapani",
          "stateValue": "TP"
        },
        "TN": {
          "displayValue": "Trentino",
          "stateValue": "TN"
        },
        "TV": {
          "displayValue": "Treviso",
          "stateValue": "TV"
        },
        "TS": {
          "displayValue": "Trieste",
          "stateValue": "TS"
        },
        "UD": {
          "displayValue": "Udine",
          "stateValue": "UD"
        },
        "VA": {
          "displayValue": "Varese",
          "stateValue": "VA"
        },
        "VE": {
          "displayValue": "Venice",
          "stateValue": "VE"
        },
        "VB": {
          "displayValue": "Verbano-Cusio-Ossola",
          "stateValue": "VB"
        },
        "VC": {
          "displayValue": "Vercelli",
          "stateValue": "VC"
        },
        "VR": {
          "displayValue": "Verona",
          "stateValue": "VR"
        },
        "VV": {
          "displayValue": "Vibo Valentia",
          "stateValue": "VV"
        },
        "VI": {
          "displayValue": "Vicenza",
          "stateValue": "VI"
        },
        "VT": {
          "displayValue": "Viterbo",
          "stateValue": "VT"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "JM": {
      "countryId": "JM",
      "displayValue": "Jamaica",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(JM)[A-Z]{3}\\d{2}"
    },
    "JP": {
      "countryId": "JP",
      "displayValue": "Japan",
      "displayFormat": "{country} ÒÇÆ{zip}_{province} {city}_{address1}_{address2}_{company}_{lastName} {firstName}µºÿ_{phone}",
      "cityDisplayValue": "City/ward/town/village",
      "stateDisplayValue": "Prefecture",
      "stateRequired": true,
      "states": {
        "JP-01": {
          "displayValue": "Hokkaido",
          "stateValue": "JP-01"
        },
        "JP-02": {
          "displayValue": "Aomori",
          "stateValue": "JP-02"
        },
        "JP-03": {
          "displayValue": "Iwate",
          "stateValue": "JP-03"
        },
        "JP-04": {
          "displayValue": "Miyagi",
          "stateValue": "JP-04"
        },
        "JP-05": {
          "displayValue": "Akita",
          "stateValue": "JP-05"
        },
        "JP-06": {
          "displayValue": "Yamagata",
          "stateValue": "JP-06"
        },
        "JP-07": {
          "displayValue": "Fukushima",
          "stateValue": "JP-07"
        },
        "JP-08": {
          "displayValue": "Ibaraki",
          "stateValue": "JP-08"
        },
        "JP-09": {
          "displayValue": "Tochigi",
          "stateValue": "JP-09"
        },
        "JP-10": {
          "displayValue": "Gunma",
          "stateValue": "JP-10"
        },
        "JP-11": {
          "displayValue": "Saitama",
          "stateValue": "JP-11"
        },
        "JP-12": {
          "displayValue": "Chiba",
          "stateValue": "JP-12"
        },
        "JP-13": {
          "displayValue": "Tokyo",
          "stateValue": "JP-13"
        },
        "JP-14": {
          "displayValue": "Kanagawa",
          "stateValue": "JP-14"
        },
        "JP-15": {
          "displayValue": "Niigata",
          "stateValue": "JP-15"
        },
        "JP-16": {
          "displayValue": "Toyama",
          "stateValue": "JP-16"
        },
        "JP-17": {
          "displayValue": "Ishikawa",
          "stateValue": "JP-17"
        },
        "JP-18": {
          "displayValue": "Fukui",
          "stateValue": "JP-18"
        },
        "JP-19": {
          "displayValue": "Yamanashi",
          "stateValue": "JP-19"
        },
        "JP-20": {
          "displayValue": "Nagano",
          "stateValue": "JP-20"
        },
        "JP-21": {
          "displayValue": "Gifu",
          "stateValue": "JP-21"
        },
        "JP-22": {
          "displayValue": "Shizuoka",
          "stateValue": "JP-22"
        },
        "JP-23": {
          "displayValue": "Aichi",
          "stateValue": "JP-23"
        },
        "JP-24": {
          "displayValue": "Mie",
          "stateValue": "JP-24"
        },
        "JP-25": {
          "displayValue": "Shiga",
          "stateValue": "JP-25"
        },
        "JP-26": {
          "displayValue": "Kyoto",
          "stateValue": "JP-26"
        },
        "JP-27": {
          "displayValue": "Osaka",
          "stateValue": "JP-27"
        },
        "JP-28": {
          "displayValue": "Hyogo",
          "stateValue": "JP-28"
        },
        "JP-29": {
          "displayValue": "Nara",
          "stateValue": "JP-29"
        },
        "JP-30": {
          "displayValue": "Wakayama",
          "stateValue": "JP-30"
        },
        "JP-31": {
          "displayValue": "Tottori",
          "stateValue": "JP-31"
        },
        "JP-32": {
          "displayValue": "Shimane",
          "stateValue": "JP-32"
        },
        "JP-33": {
          "displayValue": "Okayama",
          "stateValue": "JP-33"
        },
        "JP-34": {
          "displayValue": "Hiroshima",
          "stateValue": "JP-34"
        },
        "JP-35": {
          "displayValue": "Yamaguchi",
          "stateValue": "JP-35"
        },
        "JP-36": {
          "displayValue": "Tokushima",
          "stateValue": "JP-36"
        },
        "JP-37": {
          "displayValue": "Kagawa",
          "stateValue": "JP-37"
        },
        "JP-38": {
          "displayValue": "Ehime",
          "stateValue": "JP-38"
        },
        "JP-39": {
          "displayValue": "Kochi",
          "stateValue": "JP-39"
        },
        "JP-40": {
          "displayValue": "Fukuoka",
          "stateValue": "JP-40"
        },
        "JP-41": {
          "displayValue": "Saga",
          "stateValue": "JP-41"
        },
        "JP-42": {
          "displayValue": "Nagasaki",
          "stateValue": "JP-42"
        },
        "JP-43": {
          "displayValue": "Kumamoto",
          "stateValue": "JP-43"
        },
        "JP-44": {
          "displayValue": "Oita",
          "stateValue": "JP-44"
        },
        "JP-45": {
          "displayValue": "Miyazaki",
          "stateValue": "JP-45"
        },
        "JP-46": {
          "displayValue": "Kagoshima",
          "stateValue": "JP-46"
        },
        "JP-47": {
          "displayValue": "Okinawa",
          "stateValue": "JP-47"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "(\\d{3}-\\d{4})"
    },
    "JE": {
      "countryId": "JE",
      "displayValue": "Jersey",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "JE[0-9]{1}[\\s]([\\d][A-Z]{2})"
    },
    "JO": {
      "countryId": "JO",
      "displayValue": "Jordan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "KZ": {
      "countryId": "KZ",
      "displayValue": "Kazakhstan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "([A-Z]\\d{2}[A-Z]\\d[A-Z]\\d)|(\\d{6})"
    },
    "KE": {
      "countryId": "KE",
      "displayValue": "Kenya",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "KI": {
      "countryId": "KI",
      "displayValue": "Kiribati",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "KI\\d{4}"
    },
    "XK": {
      "countryId": "XK",
      "displayValue": "Kosovo",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^\\d{5}$"
    },
    "KW": {
      "countryId": "KW",
      "displayValue": "Kuwait",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "KG": {
      "countryId": "KG",
      "displayValue": "Kyrgyzstan",
      "displayFormat": "{zip} {city}_{address2}_{address1}_{company}_{firstName} {lastName}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "LA": {
      "countryId": "LA",
      "displayValue": "Laos",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "LV": {
      "countryId": "LV",
      "displayValue": "Latvia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "((LV)[\\-])?(\\d{4})"
    },
    "LB": {
      "countryId": "LB",
      "displayValue": "Lebanon",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}( \\d{4})?"
    },
    "LS": {
      "countryId": "LS",
      "displayValue": "Lesotho",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{3}"
    },
    "LR": {
      "countryId": "LR",
      "displayValue": "Liberia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "LY": {
      "countryId": "LY",
      "displayValue": "Libya",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "LI": {
      "countryId": "LI",
      "displayValue": "Liechtenstein",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "LT": {
      "countryId": "LT",
      "displayValue": "Lithuania",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "((LT)[\\-])?(\\d{5})"
    },
    "LU": {
      "countryId": "LU",
      "displayValue": "Luxembourg",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "((L)[\\-])?(\\d{4})"
    },
    "MO": {
      "countryId": "MO",
      "displayValue": "Macao SAR",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "MG": {
      "countryId": "MG",
      "displayValue": "Madagascar",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{3}"
    },
    "MW": {
      "countryId": "MW",
      "displayValue": "Malawi",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "MY": {
      "countryId": "MY",
      "displayValue": "Malaysia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "State/territory",
      "stateRequired": true,
      "states": {
        "JHR": {
          "displayValue": "Johor",
          "stateValue": "JHR"
        },
        "KDH": {
          "displayValue": "Kedah",
          "stateValue": "KDH"
        },
        "KTN": {
          "displayValue": "Kelantan",
          "stateValue": "KTN"
        },
        "KUL": {
          "displayValue": "Kuala Lumpur",
          "stateValue": "KUL"
        },
        "LBN": {
          "displayValue": "Labuan",
          "stateValue": "LBN"
        },
        "MLK": {
          "displayValue": "Malacca",
          "stateValue": "MLK"
        },
        "NSN": {
          "displayValue": "Negeri Sembilan",
          "stateValue": "NSN"
        },
        "PHG": {
          "displayValue": "Pahang",
          "stateValue": "PHG"
        },
        "PNG": {
          "displayValue": "Penang",
          "stateValue": "PNG"
        },
        "PRK": {
          "displayValue": "Perak",
          "stateValue": "PRK"
        },
        "PLS": {
          "displayValue": "Perlis",
          "stateValue": "PLS"
        },
        "PJY": {
          "displayValue": "Putrajaya",
          "stateValue": "PJY"
        },
        "SBH": {
          "displayValue": "Sabah",
          "stateValue": "SBH"
        },
        "SWK": {
          "displayValue": "Sarawak",
          "stateValue": "SWK"
        },
        "SGR": {
          "displayValue": "Selangor",
          "stateValue": "SGR"
        },
        "TRG": {
          "displayValue": "Terengganu",
          "stateValue": "TRG"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "MV": {
      "countryId": "MV",
      "displayValue": "Maldives",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "ML": {
      "countryId": "ML",
      "displayValue": "Mali",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "MT": {
      "countryId": "MT",
      "displayValue": "Malta",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[A-Z]{3} [0-9]{4}|[A-Z]{2}[0-9]{2}|[A-Z]{2} [0-9]{2}|[A-Z]{3}[0-9]{4}|[A-Z]{3}[0-9]{2}|[A-Z]{3} [0-9]{2}"
    },
    "MQ": {
      "countryId": "MQ",
      "displayValue": "Martinique",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^972\\d{2}$"
    },
    "MR": {
      "countryId": "MR",
      "displayValue": "Mauritania",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "MU": {
      "countryId": "MU",
      "displayValue": "Mauritius",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "([0-9A-R]\\d{4})"
    },
    "YT": {
      "countryId": "YT",
      "displayValue": "Mayotte",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^976\\d{2}$"
    },
    "MX": {
      "countryId": "MX",
      "displayValue": "Mexico",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "State",
      "stateRequired": true,
      "states": {
        "AGS": {
          "displayValue": "Aguascalientes",
          "stateValue": "AGS"
        },
        "BC": {
          "displayValue": "Baja California",
          "stateValue": "BC"
        },
        "BCS": {
          "displayValue": "Baja California Sur",
          "stateValue": "BCS"
        },
        "CAMP": {
          "displayValue": "Campeche",
          "stateValue": "CAMP"
        },
        "CHIS": {
          "displayValue": "Chiapas",
          "stateValue": "CHIS"
        },
        "CHIH": {
          "displayValue": "Chihuahua",
          "stateValue": "CHIH"
        },
        "DF": {
          "displayValue": "Ciudad de Mexico",
          "stateValue": "DF"
        },
        "COAH": {
          "displayValue": "Coahuila",
          "stateValue": "COAH"
        },
        "COL": {
          "displayValue": "Colima",
          "stateValue": "COL"
        },
        "DGO": {
          "displayValue": "Durango",
          "stateValue": "DGO"
        },
        "GTO": {
          "displayValue": "Guanajuato",
          "stateValue": "GTO"
        },
        "GRO": {
          "displayValue": "Guerrero",
          "stateValue": "GRO"
        },
        "HGO": {
          "displayValue": "Hidalgo",
          "stateValue": "HGO"
        },
        "JAL": {
          "displayValue": "Jalisco",
          "stateValue": "JAL"
        },
        "MEX": {
          "displayValue": "Mexico State",
          "stateValue": "MEX"
        },
        "MICH": {
          "displayValue": "Michoac├ín",
          "stateValue": "MICH"
        },
        "MOR": {
          "displayValue": "Morelos",
          "stateValue": "MOR"
        },
        "NAY": {
          "displayValue": "Nayarit",
          "stateValue": "NAY"
        },
        "NL": {
          "displayValue": "Nuevo Le├│n",
          "stateValue": "NL"
        },
        "OAX": {
          "displayValue": "Oaxaca",
          "stateValue": "OAX"
        },
        "PUE": {
          "displayValue": "Puebla",
          "stateValue": "PUE"
        },
        "QRO": {
          "displayValue": "Quer├®taro",
          "stateValue": "QRO"
        },
        "Q ROO": {
          "displayValue": "Quintana Roo",
          "stateValue": "Q ROO"
        },
        "SLP": {
          "displayValue": "San Luis Potos├¡",
          "stateValue": "SLP"
        },
        "SIN": {
          "displayValue": "Sinaloa",
          "stateValue": "SIN"
        },
        "SON": {
          "displayValue": "Sonora",
          "stateValue": "SON"
        },
        "TAB": {
          "displayValue": "Tabasco",
          "stateValue": "TAB"
        },
        "TAMPS": {
          "displayValue": "Tamaulipas",
          "stateValue": "TAMPS"
        },
        "TLAX": {
          "displayValue": "Tlaxcala",
          "stateValue": "TLAX"
        },
        "VER": {
          "displayValue": "Veracruz",
          "stateValue": "VER"
        },
        "YUC": {
          "displayValue": "Yucat├ín",
          "stateValue": "YUC"
        },
        "ZAC": {
          "displayValue": "Zacatecas",
          "stateValue": "ZAC"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "MD": {
      "countryId": "MD",
      "displayValue": "Moldova",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(MD[\\-]?)?(\\d{4})"
    },
    "MC": {
      "countryId": "MC",
      "displayValue": "Monaco",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^980\\d{2}$"
    },
    "MN": {
      "countryId": "MN",
      "displayValue": "Mongolia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "ME": {
      "countryId": "ME",
      "displayValue": "Montenegro",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "MS": {
      "countryId": "MS",
      "displayValue": "Montserrat",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "MSR\\d{4}"
    },
    "MA": {
      "countryId": "MA",
      "displayValue": "Morocco",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{4}"
    },
    "MZ": {
      "countryId": "MZ",
      "displayValue": "Mozambique",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "MM": {
      "countryId": "MM",
      "displayValue": "Myanmar (Burma)",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "NA": {
      "countryId": "NA",
      "displayValue": "Namibia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "NR": {
      "countryId": "NR",
      "displayValue": "Nauru",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(NRU68)"
    },
    "NP": {
      "countryId": "NP",
      "displayValue": "Nepal",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "NL": {
      "countryId": "NL",
      "displayValue": "Netherlands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{3} [A-Z]{2}|[1-9]\\d{3}[A-Z]{2}"
    },
    "NC": {
      "countryId": "NC",
      "displayValue": "New Caledonia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "988\\d{2}"
    },
    "NZ": {
      "countryId": "NZ",
      "displayValue": "New Zealand",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{province}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "AUK": {
          "displayValue": "Auckland",
          "stateValue": "AUK"
        },
        "BOP": {
          "displayValue": "Bay of Plenty",
          "stateValue": "BOP"
        },
        "CAN": {
          "displayValue": "Canterbury",
          "stateValue": "CAN"
        },
        "CIT": {
          "displayValue": "Chatham Islands",
          "stateValue": "CIT"
        },
        "GIS": {
          "displayValue": "Gisborne",
          "stateValue": "GIS"
        },
        "HKB": {
          "displayValue": "HawkeÔÇÖs Bay",
          "stateValue": "HKB"
        },
        "MWT": {
          "displayValue": "Manawatu-Wanganui",
          "stateValue": "MWT"
        },
        "MBH": {
          "displayValue": "Marlborough",
          "stateValue": "MBH"
        },
        "NSN": {
          "displayValue": "Nelson",
          "stateValue": "NSN"
        },
        "NTL": {
          "displayValue": "Northland",
          "stateValue": "NTL"
        },
        "OTA": {
          "displayValue": "Otago",
          "stateValue": "OTA"
        },
        "STL": {
          "displayValue": "Southland",
          "stateValue": "STL"
        },
        "TKI": {
          "displayValue": "Taranaki",
          "stateValue": "TKI"
        },
        "TAS": {
          "displayValue": "Tasman",
          "stateValue": "TAS"
        },
        "WKO": {
          "displayValue": "Waikato",
          "stateValue": "WKO"
        },
        "WGN": {
          "displayValue": "Wellington",
          "stateValue": "WGN"
        },
        "WTC": {
          "displayValue": "West Coast",
          "stateValue": "WTC"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "NI": {
      "countryId": "NI",
      "displayValue": "Nicaragua",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "NE": {
      "countryId": "NE",
      "displayValue": "Niger",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "NG": {
      "countryId": "NG",
      "displayValue": "Nigeria",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "State",
      "stateRequired": true,
      "states": {
        "AB": {
          "displayValue": "Abia",
          "stateValue": "AB"
        },
        "FC": {
          "displayValue": "Federal Capital Territory",
          "stateValue": "FC"
        },
        "AD": {
          "displayValue": "Adamawa",
          "stateValue": "AD"
        },
        "AK": {
          "displayValue": "Akwa Ibom",
          "stateValue": "AK"
        },
        "AN": {
          "displayValue": "Anambra",
          "stateValue": "AN"
        },
        "BA": {
          "displayValue": "Bauchi",
          "stateValue": "BA"
        },
        "BY": {
          "displayValue": "Bayelsa",
          "stateValue": "BY"
        },
        "BE": {
          "displayValue": "Benue",
          "stateValue": "BE"
        },
        "BO": {
          "displayValue": "Borno",
          "stateValue": "BO"
        },
        "CR": {
          "displayValue": "Cross River",
          "stateValue": "CR"
        },
        "DE": {
          "displayValue": "Delta",
          "stateValue": "DE"
        },
        "EB": {
          "displayValue": "Ebonyi",
          "stateValue": "EB"
        },
        "ED": {
          "displayValue": "Edo",
          "stateValue": "ED"
        },
        "EK": {
          "displayValue": "Ekiti",
          "stateValue": "EK"
        },
        "EN": {
          "displayValue": "Enugu",
          "stateValue": "EN"
        },
        "GO": {
          "displayValue": "Gombe",
          "stateValue": "GO"
        },
        "IM": {
          "displayValue": "Imo",
          "stateValue": "IM"
        },
        "JI": {
          "displayValue": "Jigawa",
          "stateValue": "JI"
        },
        "KD": {
          "displayValue": "Kaduna",
          "stateValue": "KD"
        },
        "KN": {
          "displayValue": "Kano",
          "stateValue": "KN"
        },
        "KT": {
          "displayValue": "Katsina",
          "stateValue": "KT"
        },
        "KE": {
          "displayValue": "Kebbi",
          "stateValue": "KE"
        },
        "KO": {
          "displayValue": "Kogi",
          "stateValue": "KO"
        },
        "KW": {
          "displayValue": "Kwara",
          "stateValue": "KW"
        },
        "LA": {
          "displayValue": "Lagos",
          "stateValue": "LA"
        },
        "NA": {
          "displayValue": "Nasarawa",
          "stateValue": "NA"
        },
        "NI": {
          "displayValue": "Niger",
          "stateValue": "NI"
        },
        "OG": {
          "displayValue": "Ogun",
          "stateValue": "OG"
        },
        "ON": {
          "displayValue": "Ondo",
          "stateValue": "ON"
        },
        "OS": {
          "displayValue": "Osun",
          "stateValue": "OS"
        },
        "OY": {
          "displayValue": "Oyo",
          "stateValue": "OY"
        },
        "PL": {
          "displayValue": "Plateau",
          "stateValue": "PL"
        },
        "RI": {
          "displayValue": "Rivers",
          "stateValue": "RI"
        },
        "SO": {
          "displayValue": "Sokoto",
          "stateValue": "SO"
        },
        "TA": {
          "displayValue": "Taraba",
          "stateValue": "TA"
        },
        "YO": {
          "displayValue": "Yobe",
          "stateValue": "YO"
        },
        "ZA": {
          "displayValue": "Zamfara",
          "stateValue": "ZA"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{5}"
    },
    "NU": {
      "countryId": "NU",
      "displayValue": "Niue",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(9974)"
    },
    "NF": {
      "countryId": "NF",
      "displayValue": "Norfolk Island",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(2899)"
    },
    "MK": {
      "countryId": "MK",
      "displayValue": "North Macedonia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "NO": {
      "countryId": "NO",
      "displayValue": "Norway",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "OM": {
      "countryId": "OM",
      "displayValue": "Oman",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{3}"
    },
    "PK": {
      "countryId": "PK",
      "displayValue": "Pakistan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{4}"
    },
    "PS": {
      "countryId": "PS",
      "displayValue": "Palestinian Territories",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": null
    },
    "PA": {
      "countryId": "PA",
      "displayValue": "Panama",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "PA-1": {
          "displayValue": "Bocas del Toro",
          "stateValue": "PA-1"
        },
        "PA-4": {
          "displayValue": "Chiriqu├¡",
          "stateValue": "PA-4"
        },
        "PA-2": {
          "displayValue": "Cocl├®",
          "stateValue": "PA-2"
        },
        "PA-3": {
          "displayValue": "Col├│n",
          "stateValue": "PA-3"
        },
        "PA-5": {
          "displayValue": "Dari├®n",
          "stateValue": "PA-5"
        },
        "PA-EM": {
          "displayValue": "Ember├í",
          "stateValue": "PA-EM"
        },
        "PA-6": {
          "displayValue": "Herrera",
          "stateValue": "PA-6"
        },
        "PA-KY": {
          "displayValue": "Guna Yala",
          "stateValue": "PA-KY"
        },
        "PA-7": {
          "displayValue": "Los Santos",
          "stateValue": "PA-7"
        },
        "PA-NB": {
          "displayValue": "Ng├Âbe-Bugl├®",
          "stateValue": "PA-NB"
        },
        "PA-8": {
          "displayValue": "Panam├í",
          "stateValue": "PA-8"
        },
        "PA-10": {
          "displayValue": "West Panam├í",
          "stateValue": "PA-10"
        },
        "PA-9": {
          "displayValue": "Veraguas",
          "stateValue": "PA-9"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "PG": {
      "countryId": "PG",
      "displayValue": "Papua New Guinea",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{3}"
    },
    "PY": {
      "countryId": "PY",
      "displayValue": "Paraguay",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "PE": {
      "countryId": "PE",
      "displayValue": "Peru",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "PE-AMA": {
          "displayValue": "Amazonas",
          "stateValue": "PE-AMA"
        },
        "PE-ANC": {
          "displayValue": "Ancash",
          "stateValue": "PE-ANC"
        },
        "PE-APU": {
          "displayValue": "Apur├¡mac",
          "stateValue": "PE-APU"
        },
        "PE-ARE": {
          "displayValue": "Arequipa",
          "stateValue": "PE-ARE"
        },
        "PE-AYA": {
          "displayValue": "Ayacucho",
          "stateValue": "PE-AYA"
        },
        "PE-CAJ": {
          "displayValue": "Cajamarca",
          "stateValue": "PE-CAJ"
        },
        "PE-CAL": {
          "displayValue": "El Callao",
          "stateValue": "PE-CAL"
        },
        "PE-CUS": {
          "displayValue": "Cusco",
          "stateValue": "PE-CUS"
        },
        "PE-HUV": {
          "displayValue": "Huancavelica",
          "stateValue": "PE-HUV"
        },
        "PE-HUC": {
          "displayValue": "Hu├ínuco",
          "stateValue": "PE-HUC"
        },
        "PE-ICA": {
          "displayValue": "Ica",
          "stateValue": "PE-ICA"
        },
        "PE-JUN": {
          "displayValue": "Jun├¡n",
          "stateValue": "PE-JUN"
        },
        "PE-LAL": {
          "displayValue": "La Libertad",
          "stateValue": "PE-LAL"
        },
        "PE-LAM": {
          "displayValue": "Lambayeque",
          "stateValue": "PE-LAM"
        },
        "PE-LIM": {
          "displayValue": "Lima Region",
          "stateValue": "PE-LIM"
        },
        "PE-LMA": {
          "displayValue": "Lima",
          "stateValue": "PE-LMA"
        },
        "PE-LOR": {
          "displayValue": "Loreto",
          "stateValue": "PE-LOR"
        },
        "PE-MDD": {
          "displayValue": "Madre de Dios",
          "stateValue": "PE-MDD"
        },
        "PE-MOQ": {
          "displayValue": "Moquegua",
          "stateValue": "PE-MOQ"
        },
        "PE-PAS": {
          "displayValue": "Pasco",
          "stateValue": "PE-PAS"
        },
        "PE-PIU": {
          "displayValue": "Piura",
          "stateValue": "PE-PIU"
        },
        "PE-PUN": {
          "displayValue": "Puno",
          "stateValue": "PE-PUN"
        },
        "PE-SAM": {
          "displayValue": "San Mart├¡n",
          "stateValue": "PE-SAM"
        },
        "PE-TAC": {
          "displayValue": "Tacna",
          "stateValue": "PE-TAC"
        },
        "PE-TUM": {
          "displayValue": "Tumbes",
          "stateValue": "PE-TUM"
        },
        "PE-UCA": {
          "displayValue": "Ucayali",
          "stateValue": "PE-UCA"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "PH": {
      "countryId": "PH",
      "displayValue": "Philippines",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "PH-ABR": {
          "displayValue": "Abra",
          "stateValue": "PH-ABR"
        },
        "PH-AGN": {
          "displayValue": "Agusan del Norte",
          "stateValue": "PH-AGN"
        },
        "PH-AGS": {
          "displayValue": "Agusan del Sur",
          "stateValue": "PH-AGS"
        },
        "PH-AKL": {
          "displayValue": "Aklan",
          "stateValue": "PH-AKL"
        },
        "PH-ALB": {
          "displayValue": "Albay",
          "stateValue": "PH-ALB"
        },
        "PH-ANT": {
          "displayValue": "Antique",
          "stateValue": "PH-ANT"
        },
        "PH-APA": {
          "displayValue": "Apayao",
          "stateValue": "PH-APA"
        },
        "PH-AUR": {
          "displayValue": "Aurora",
          "stateValue": "PH-AUR"
        },
        "PH-BAS": {
          "displayValue": "Basilan",
          "stateValue": "PH-BAS"
        },
        "PH-BAN": {
          "displayValue": "Bataan",
          "stateValue": "PH-BAN"
        },
        "PH-BTN": {
          "displayValue": "Batanes",
          "stateValue": "PH-BTN"
        },
        "PH-BTG": {
          "displayValue": "Batangas",
          "stateValue": "PH-BTG"
        },
        "PH-BEN": {
          "displayValue": "Benguet",
          "stateValue": "PH-BEN"
        },
        "PH-BIL": {
          "displayValue": "Biliran",
          "stateValue": "PH-BIL"
        },
        "PH-BOH": {
          "displayValue": "Bohol",
          "stateValue": "PH-BOH"
        },
        "PH-BUK": {
          "displayValue": "Bukidnon",
          "stateValue": "PH-BUK"
        },
        "PH-BUL": {
          "displayValue": "Bulacan",
          "stateValue": "PH-BUL"
        },
        "PH-CAG": {
          "displayValue": "Cagayan",
          "stateValue": "PH-CAG"
        },
        "PH-CAN": {
          "displayValue": "Camarines Norte",
          "stateValue": "PH-CAN"
        },
        "PH-CAS": {
          "displayValue": "Camarines Sur",
          "stateValue": "PH-CAS"
        },
        "PH-CAM": {
          "displayValue": "Camiguin",
          "stateValue": "PH-CAM"
        },
        "PH-CAP": {
          "displayValue": "Capiz",
          "stateValue": "PH-CAP"
        },
        "PH-CAT": {
          "displayValue": "Catanduanes",
          "stateValue": "PH-CAT"
        },
        "PH-CAV": {
          "displayValue": "Cavite",
          "stateValue": "PH-CAV"
        },
        "PH-CEB": {
          "displayValue": "Cebu",
          "stateValue": "PH-CEB"
        },
        "PH-NCO": {
          "displayValue": "Cotabato",
          "stateValue": "PH-NCO"
        },
        "PH-DVO": {
          "displayValue": "Davao Occidental",
          "stateValue": "PH-DVO"
        },
        "PH-DAO": {
          "displayValue": "Davao Oriental",
          "stateValue": "PH-DAO"
        },
        "PH-COM": {
          "displayValue": "Compostela Valley",
          "stateValue": "PH-COM"
        },
        "PH-DAV": {
          "displayValue": "Davao del Norte",
          "stateValue": "PH-DAV"
        },
        "PH-DAS": {
          "displayValue": "Davao del Sur",
          "stateValue": "PH-DAS"
        },
        "PH-DIN": {
          "displayValue": "Dinagat Islands",
          "stateValue": "PH-DIN"
        },
        "PH-EAS": {
          "displayValue": "Eastern Samar",
          "stateValue": "PH-EAS"
        },
        "PH-GUI": {
          "displayValue": "Guimaras",
          "stateValue": "PH-GUI"
        },
        "PH-IFU": {
          "displayValue": "Ifugao",
          "stateValue": "PH-IFU"
        },
        "PH-ILN": {
          "displayValue": "Ilocos Norte",
          "stateValue": "PH-ILN"
        },
        "PH-ILS": {
          "displayValue": "Ilocos Sur",
          "stateValue": "PH-ILS"
        },
        "PH-ILI": {
          "displayValue": "Iloilo",
          "stateValue": "PH-ILI"
        },
        "PH-ISA": {
          "displayValue": "Isabela",
          "stateValue": "PH-ISA"
        },
        "PH-KAL": {
          "displayValue": "Kalinga",
          "stateValue": "PH-KAL"
        },
        "PH-LUN": {
          "displayValue": "La Union",
          "stateValue": "PH-LUN"
        },
        "PH-LAG": {
          "displayValue": "Laguna",
          "stateValue": "PH-LAG"
        },
        "PH-LAN": {
          "displayValue": "Lanao del Norte",
          "stateValue": "PH-LAN"
        },
        "PH-LAS": {
          "displayValue": "Lanao del Sur",
          "stateValue": "PH-LAS"
        },
        "PH-LEY": {
          "displayValue": "Leyte",
          "stateValue": "PH-LEY"
        },
        "PH-MAG": {
          "displayValue": "Maguindanao",
          "stateValue": "PH-MAG"
        },
        "PH-MAD": {
          "displayValue": "Marinduque",
          "stateValue": "PH-MAD"
        },
        "PH-MAS": {
          "displayValue": "Masbate",
          "stateValue": "PH-MAS"
        },
        "PH-00": {
          "displayValue": "Metro Manila",
          "stateValue": "PH-00"
        },
        "PH-MSC": {
          "displayValue": "Misamis Occidental",
          "stateValue": "PH-MSC"
        },
        "PH-MSR": {
          "displayValue": "Misamis Oriental",
          "stateValue": "PH-MSR"
        },
        "PH-MOU": {
          "displayValue": "Mountain",
          "stateValue": "PH-MOU"
        },
        "PH-NEC": {
          "displayValue": "Negros Occidental",
          "stateValue": "PH-NEC"
        },
        "PH-NER": {
          "displayValue": "Negros Oriental",
          "stateValue": "PH-NER"
        },
        "PH-NSA": {
          "displayValue": "Northern Samar",
          "stateValue": "PH-NSA"
        },
        "PH-NUE": {
          "displayValue": "Nueva Ecija",
          "stateValue": "PH-NUE"
        },
        "PH-NUV": {
          "displayValue": "Nueva Vizcaya",
          "stateValue": "PH-NUV"
        },
        "PH-MDC": {
          "displayValue": "Occidental Mindoro",
          "stateValue": "PH-MDC"
        },
        "PH-MDR": {
          "displayValue": "Oriental Mindoro",
          "stateValue": "PH-MDR"
        },
        "PH-PLW": {
          "displayValue": "Palawan",
          "stateValue": "PH-PLW"
        },
        "PH-PAM": {
          "displayValue": "Pampanga",
          "stateValue": "PH-PAM"
        },
        "PH-PAN": {
          "displayValue": "Pangasinan",
          "stateValue": "PH-PAN"
        },
        "PH-QUE": {
          "displayValue": "Quezon",
          "stateValue": "PH-QUE"
        },
        "PH-QUI": {
          "displayValue": "Quirino",
          "stateValue": "PH-QUI"
        },
        "PH-RIZ": {
          "displayValue": "Rizal",
          "stateValue": "PH-RIZ"
        },
        "PH-ROM": {
          "displayValue": "Romblon",
          "stateValue": "PH-ROM"
        },
        "PH-WSA": {
          "displayValue": "Samar",
          "stateValue": "PH-WSA"
        },
        "PH-SAR": {
          "displayValue": "Sarangani",
          "stateValue": "PH-SAR"
        },
        "PH-SIG": {
          "displayValue": "Siquijor",
          "stateValue": "PH-SIG"
        },
        "PH-SOR": {
          "displayValue": "Sorsogon",
          "stateValue": "PH-SOR"
        },
        "PH-SCO": {
          "displayValue": "South Cotabato",
          "stateValue": "PH-SCO"
        },
        "PH-SLE": {
          "displayValue": "Southern Leyte",
          "stateValue": "PH-SLE"
        },
        "PH-SUK": {
          "displayValue": "Sultan Kudarat",
          "stateValue": "PH-SUK"
        },
        "PH-SLU": {
          "displayValue": "Sulu",
          "stateValue": "PH-SLU"
        },
        "PH-SUN": {
          "displayValue": "Surigao del Norte",
          "stateValue": "PH-SUN"
        },
        "PH-SUR": {
          "displayValue": "Surigao del Sur",
          "stateValue": "PH-SUR"
        },
        "PH-TAR": {
          "displayValue": "Tarlac",
          "stateValue": "PH-TAR"
        },
        "PH-TAW": {
          "displayValue": "Tawi-Tawi",
          "stateValue": "PH-TAW"
        },
        "PH-ZMB": {
          "displayValue": "Zambales",
          "stateValue": "PH-ZMB"
        },
        "PH-ZSI": {
          "displayValue": "Zamboanga Sibugay",
          "stateValue": "PH-ZSI"
        },
        "PH-ZAN": {
          "displayValue": "Zamboanga del Norte",
          "stateValue": "PH-ZAN"
        },
        "PH-ZAS": {
          "displayValue": "Zamboanga del Sur",
          "stateValue": "PH-ZAS"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "PN": {
      "countryId": "PN",
      "displayValue": "Pitcairn Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(PCR9 1ZZ)"
    },
    "PL": {
      "countryId": "PL",
      "displayValue": "Poland",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[0-9]{2}[-]([0-9]){3}"
    },
    "PT": {
      "countryId": "PT",
      "displayValue": "Portugal",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "PT-20": {
          "displayValue": "Azores",
          "stateValue": "PT-20"
        },
        "PT-01": {
          "displayValue": "Aveiro",
          "stateValue": "PT-01"
        },
        "PT-02": {
          "displayValue": "Beja",
          "stateValue": "PT-02"
        },
        "PT-03": {
          "displayValue": "Braga",
          "stateValue": "PT-03"
        },
        "PT-04": {
          "displayValue": "Bragan├ºa",
          "stateValue": "PT-04"
        },
        "PT-05": {
          "displayValue": "Castelo Branco",
          "stateValue": "PT-05"
        },
        "PT-06": {
          "displayValue": "Coimbra",
          "stateValue": "PT-06"
        },
        "PT-07": {
          "displayValue": "├ëvora",
          "stateValue": "PT-07"
        },
        "PT-08": {
          "displayValue": "Faro",
          "stateValue": "PT-08"
        },
        "PT-09": {
          "displayValue": "Guarda",
          "stateValue": "PT-09"
        },
        "PT-10": {
          "displayValue": "Leiria",
          "stateValue": "PT-10"
        },
        "PT-11": {
          "displayValue": "Lisbon",
          "stateValue": "PT-11"
        },
        "PT-30": {
          "displayValue": "Madeira",
          "stateValue": "PT-30"
        },
        "PT-12": {
          "displayValue": "Portalegre",
          "stateValue": "PT-12"
        },
        "PT-13": {
          "displayValue": "Porto",
          "stateValue": "PT-13"
        },
        "PT-14": {
          "displayValue": "Santar├®m",
          "stateValue": "PT-14"
        },
        "PT-15": {
          "displayValue": "Set├║bal",
          "stateValue": "PT-15"
        },
        "PT-16": {
          "displayValue": "Viana do Castelo",
          "stateValue": "PT-16"
        },
        "PT-17": {
          "displayValue": "Vila Real",
          "stateValue": "PT-17"
        },
        "PT-18": {
          "displayValue": "Viseu",
          "stateValue": "PT-18"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{3}((-)\\d{3})"
    },
    "QA": {
      "countryId": "QA",
      "displayValue": "Qatar",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "RE": {
      "countryId": "RE",
      "displayValue": "R├®union",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^974\\d{2}$"
    },
    "RO": {
      "countryId": "RO",
      "displayValue": "Romania",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "County",
      "stateRequired": true,
      "states": {
        "AB": {
          "displayValue": "Alba",
          "stateValue": "AB"
        },
        "AR": {
          "displayValue": "Arad",
          "stateValue": "AR"
        },
        "AG": {
          "displayValue": "Arge╚Ö",
          "stateValue": "AG"
        },
        "BC": {
          "displayValue": "Bac─âu",
          "stateValue": "BC"
        },
        "BH": {
          "displayValue": "Bihor",
          "stateValue": "BH"
        },
        "BN": {
          "displayValue": "Bistri┼úa-N─âs─âud",
          "stateValue": "BN"
        },
        "BT": {
          "displayValue": "Boto┼ƒani",
          "stateValue": "BT"
        },
        "BR": {
          "displayValue": "Br─âila",
          "stateValue": "BR"
        },
        "BV": {
          "displayValue": "Bra┼ƒov",
          "stateValue": "BV"
        },
        "B": {
          "displayValue": "Bucharest",
          "stateValue": "B"
        },
        "BZ": {
          "displayValue": "Buz─âu",
          "stateValue": "BZ"
        },
        "CS": {
          "displayValue": "Cara╚Ö-Severin",
          "stateValue": "CS"
        },
        "CJ": {
          "displayValue": "Cluj",
          "stateValue": "CJ"
        },
        "CT": {
          "displayValue": "Constan╚øa",
          "stateValue": "CT"
        },
        "CV": {
          "displayValue": "Covasna",
          "stateValue": "CV"
        },
        "CL": {
          "displayValue": "C─âl─âra╚Öi",
          "stateValue": "CL"
        },
        "DJ": {
          "displayValue": "Dolj",
          "stateValue": "DJ"
        },
        "DB": {
          "displayValue": "D├ómbovi╚øa",
          "stateValue": "DB"
        },
        "GL": {
          "displayValue": "Gala╚øi",
          "stateValue": "GL"
        },
        "GR": {
          "displayValue": "Giurgiu",
          "stateValue": "GR"
        },
        "GJ": {
          "displayValue": "Gorj",
          "stateValue": "GJ"
        },
        "HR": {
          "displayValue": "Harghita",
          "stateValue": "HR"
        },
        "HD": {
          "displayValue": "Hunedoara",
          "stateValue": "HD"
        },
        "IL": {
          "displayValue": "Ialomi╚øa",
          "stateValue": "IL"
        },
        "IS": {
          "displayValue": "Ia╚Öi",
          "stateValue": "IS"
        },
        "IF": {
          "displayValue": "Ilfov",
          "stateValue": "IF"
        },
        "MM": {
          "displayValue": "Maramure┼ƒ",
          "stateValue": "MM"
        },
        "MH": {
          "displayValue": "Mehedin╚øi",
          "stateValue": "MH"
        },
        "MS": {
          "displayValue": "Mure┼ƒ",
          "stateValue": "MS"
        },
        "NT": {
          "displayValue": "Neam┼ú",
          "stateValue": "NT"
        },
        "OT": {
          "displayValue": "Olt",
          "stateValue": "OT"
        },
        "PH": {
          "displayValue": "Prahova",
          "stateValue": "PH"
        },
        "SJ": {
          "displayValue": "S─âlaj",
          "stateValue": "SJ"
        },
        "SM": {
          "displayValue": "Satu Mare",
          "stateValue": "SM"
        },
        "SB": {
          "displayValue": "Sibiu",
          "stateValue": "SB"
        },
        "SV": {
          "displayValue": "Suceava",
          "stateValue": "SV"
        },
        "TR": {
          "displayValue": "Teleorman",
          "stateValue": "TR"
        },
        "TM": {
          "displayValue": "Timi╚Ö",
          "stateValue": "TM"
        },
        "TL": {
          "displayValue": "Tulcea",
          "stateValue": "TL"
        },
        "VL": {
          "displayValue": "V├ólcea",
          "stateValue": "VL"
        },
        "VS": {
          "displayValue": "Vaslui",
          "stateValue": "VS"
        },
        "VN": {
          "displayValue": "Vrancea",
          "stateValue": "VN"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "RU": {
      "countryId": "RU",
      "displayValue": "Russia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "ALT": {
          "displayValue": "Altai Krai",
          "stateValue": "ALT"
        },
        "AL": {
          "displayValue": "Altai",
          "stateValue": "AL"
        },
        "AMU": {
          "displayValue": "Amur",
          "stateValue": "AMU"
        },
        "ARK": {
          "displayValue": "Arkhangelsk",
          "stateValue": "ARK"
        },
        "AST": {
          "displayValue": "Astrakhan",
          "stateValue": "AST"
        },
        "BEL": {
          "displayValue": "Belgorod",
          "stateValue": "BEL"
        },
        "BRY": {
          "displayValue": "Bryansk",
          "stateValue": "BRY"
        },
        "CE": {
          "displayValue": "Chechen",
          "stateValue": "CE"
        },
        "CHE": {
          "displayValue": "Chelyabinsk",
          "stateValue": "CHE"
        },
        "CHU": {
          "displayValue": "Chukotka Okrug",
          "stateValue": "CHU"
        },
        "CU": {
          "displayValue": "Chuvash",
          "stateValue": "CU"
        },
        "IRK": {
          "displayValue": "Irkutsk",
          "stateValue": "IRK"
        },
        "IVA": {
          "displayValue": "Ivanovo",
          "stateValue": "IVA"
        },
        "YEV": {
          "displayValue": "Jewish",
          "stateValue": "YEV"
        },
        "KB": {
          "displayValue": "Kabardino-Balkar",
          "stateValue": "KB"
        },
        "KGD": {
          "displayValue": "Kaliningrad",
          "stateValue": "KGD"
        },
        "KLU": {
          "displayValue": "Kaluga",
          "stateValue": "KLU"
        },
        "KAM": {
          "displayValue": "Kamchatka Krai",
          "stateValue": "KAM"
        },
        "KC": {
          "displayValue": "Karachay-Cherkess",
          "stateValue": "KC"
        },
        "KEM": {
          "displayValue": "Kemerovo",
          "stateValue": "KEM"
        },
        "KHA": {
          "displayValue": "Khabarovsk Krai",
          "stateValue": "KHA"
        },
        "KHM": {
          "displayValue": "Khanty-Mansi",
          "stateValue": "KHM"
        },
        "KIR": {
          "displayValue": "Kirov",
          "stateValue": "KIR"
        },
        "KO": {
          "displayValue": "Komi",
          "stateValue": "KO"
        },
        "KOS": {
          "displayValue": "Kostroma",
          "stateValue": "KOS"
        },
        "KDA": {
          "displayValue": "Krasnodar Krai",
          "stateValue": "KDA"
        },
        "KYA": {
          "displayValue": "Krasnoyarsk Krai",
          "stateValue": "KYA"
        },
        "KGN": {
          "displayValue": "Kurgan",
          "stateValue": "KGN"
        },
        "KRS": {
          "displayValue": "Kursk",
          "stateValue": "KRS"
        },
        "LEN": {
          "displayValue": "Leningrad",
          "stateValue": "LEN"
        },
        "LIP": {
          "displayValue": "Lipetsk",
          "stateValue": "LIP"
        },
        "MAG": {
          "displayValue": "Magadan",
          "stateValue": "MAG"
        },
        "ME": {
          "displayValue": "Mari El",
          "stateValue": "ME"
        },
        "MOW": {
          "displayValue": "Moscow",
          "stateValue": "MOW"
        },
        "MOS": {
          "displayValue": "Moscow Province",
          "stateValue": "MOS"
        },
        "MUR": {
          "displayValue": "Murmansk",
          "stateValue": "MUR"
        },
        "NIZ": {
          "displayValue": "Nizhny Novgorod",
          "stateValue": "NIZ"
        },
        "NGR": {
          "displayValue": "Novgorod",
          "stateValue": "NGR"
        },
        "NVS": {
          "displayValue": "Novosibirsk",
          "stateValue": "NVS"
        },
        "OMS": {
          "displayValue": "Omsk",
          "stateValue": "OMS"
        },
        "ORE": {
          "displayValue": "Orenburg",
          "stateValue": "ORE"
        },
        "ORL": {
          "displayValue": "Oryol",
          "stateValue": "ORL"
        },
        "PNZ": {
          "displayValue": "Penza",
          "stateValue": "PNZ"
        },
        "PER": {
          "displayValue": "Perm Krai",
          "stateValue": "PER"
        },
        "PRI": {
          "displayValue": "Primorsky Krai",
          "stateValue": "PRI"
        },
        "PSK": {
          "displayValue": "Pskov",
          "stateValue": "PSK"
        },
        "AD": {
          "displayValue": "Adygea",
          "stateValue": "AD"
        },
        "BA": {
          "displayValue": "Bashkortostan",
          "stateValue": "BA"
        },
        "BU": {
          "displayValue": "Buryat",
          "stateValue": "BU"
        },
        "DA": {
          "displayValue": "Dagestan",
          "stateValue": "DA"
        },
        "IN": {
          "displayValue": "Ingushetia",
          "stateValue": "IN"
        },
        "KL": {
          "displayValue": "Kalmykia",
          "stateValue": "KL"
        },
        "KR": {
          "displayValue": "Karelia",
          "stateValue": "KR"
        },
        "KK": {
          "displayValue": "Khakassia",
          "stateValue": "KK"
        },
        "MO": {
          "displayValue": "Mordovia",
          "stateValue": "MO"
        },
        "SE": {
          "displayValue": "North Ossetia-Alania",
          "stateValue": "SE"
        },
        "TA": {
          "displayValue": "Tatarstan",
          "stateValue": "TA"
        },
        "ROS": {
          "displayValue": "Rostov",
          "stateValue": "ROS"
        },
        "RYA": {
          "displayValue": "Ryazan",
          "stateValue": "RYA"
        },
        "SPE": {
          "displayValue": "Saint Petersburg",
          "stateValue": "SPE"
        },
        "SA": {
          "displayValue": "Sakha",
          "stateValue": "SA"
        },
        "SAK": {
          "displayValue": "Sakhalin",
          "stateValue": "SAK"
        },
        "SAM": {
          "displayValue": "Samara",
          "stateValue": "SAM"
        },
        "SAR": {
          "displayValue": "Saratov",
          "stateValue": "SAR"
        },
        "SMO": {
          "displayValue": "Smolensk",
          "stateValue": "SMO"
        },
        "STA": {
          "displayValue": "Stavropol Krai",
          "stateValue": "STA"
        },
        "SVE": {
          "displayValue": "Sverdlovsk",
          "stateValue": "SVE"
        },
        "TAM": {
          "displayValue": "Tambov",
          "stateValue": "TAM"
        },
        "TOM": {
          "displayValue": "Tomsk",
          "stateValue": "TOM"
        },
        "TUL": {
          "displayValue": "Tula",
          "stateValue": "TUL"
        },
        "TVE": {
          "displayValue": "Tver",
          "stateValue": "TVE"
        },
        "TYU": {
          "displayValue": "Tyumen",
          "stateValue": "TYU"
        },
        "TY": {
          "displayValue": "Tuva",
          "stateValue": "TY"
        },
        "UD": {
          "displayValue": "Udmurt",
          "stateValue": "UD"
        },
        "ULY": {
          "displayValue": "Ulyanovsk",
          "stateValue": "ULY"
        },
        "VLA": {
          "displayValue": "Vladimir",
          "stateValue": "VLA"
        },
        "VGG": {
          "displayValue": "Volgograd",
          "stateValue": "VGG"
        },
        "VLG": {
          "displayValue": "Vologda",
          "stateValue": "VLG"
        },
        "VOR": {
          "displayValue": "Voronezh",
          "stateValue": "VOR"
        },
        "YAN": {
          "displayValue": "Yamalo-Nenets Okrug",
          "stateValue": "YAN"
        },
        "YAR": {
          "displayValue": "Yaroslavl",
          "stateValue": "YAR"
        },
        "ZAB": {
          "displayValue": "Zabaykalsky Krai",
          "stateValue": "ZAB"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "RW": {
      "countryId": "RW",
      "displayValue": "Rwanda",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": null
    },
    "WS": {
      "countryId": "WS",
      "displayValue": "Samoa",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": null
    },
    "SM": {
      "countryId": "SM",
      "displayValue": "San Marino",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(4789\\d)"
    },
    "ST": {
      "countryId": "ST",
      "displayValue": "S├úo Tom├® & Pr├¡ncipe",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "SA": {
      "countryId": "SA",
      "displayValue": "Saudi Arabia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-8]\\d{4}([\\-]\\d{4})?"
    },
    "SN": {
      "countryId": "SN",
      "displayValue": "Senegal",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-8]\\d{4}"
    },
    "RS": {
      "countryId": "RS",
      "displayValue": "Serbia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5,6}"
    },
    "SC": {
      "countryId": "SC",
      "displayValue": "Seychelles",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "SL": {
      "countryId": "SL",
      "displayValue": "Sierra Leone",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "SG": {
      "countryId": "SG",
      "displayValue": "Singapore",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "SX": {
      "countryId": "SX",
      "displayValue": "Sint Maarten",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "SK": {
      "countryId": "SK",
      "displayValue": "Slovakia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(\\d{3} \\d{2})|\\d{5}"
    },
    "SI": {
      "countryId": "SI",
      "displayValue": "Slovenia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{3}"
    },
    "SB": {
      "countryId": "SB",
      "displayValue": "Solomon Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "SO": {
      "countryId": "SO",
      "displayValue": "Somalia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "ZA": {
      "countryId": "ZA",
      "displayValue": "South Africa",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "EC": {
          "displayValue": "Eastern Cape",
          "stateValue": "EC"
        },
        "FS": {
          "displayValue": "Free State",
          "stateValue": "FS"
        },
        "GT": {
          "displayValue": "Gauteng",
          "stateValue": "GT"
        },
        "NL": {
          "displayValue": "KwaZulu-Natal",
          "stateValue": "NL"
        },
        "LP": {
          "displayValue": "Limpopo",
          "stateValue": "LP"
        },
        "MP": {
          "displayValue": "Mpumalanga",
          "stateValue": "MP"
        },
        "NW": {
          "displayValue": "North West",
          "stateValue": "NW"
        },
        "NC": {
          "displayValue": "Northern Cape",
          "stateValue": "NC"
        },
        "WC": {
          "displayValue": "Western Cape",
          "stateValue": "WC"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "GS": {
      "countryId": "GS",
      "displayValue": "South Georgia & South Sandwich Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(SIQQ 1ZZ)"
    },
    "KR": {
      "countryId": "KR",
      "displayValue": "South Korea",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "KR-26": {
          "displayValue": "Busan",
          "stateValue": "KR-26"
        },
        "KR-43": {
          "displayValue": "North Chungcheong",
          "stateValue": "KR-43"
        },
        "KR-44": {
          "displayValue": "South Chungcheong",
          "stateValue": "KR-44"
        },
        "KR-27": {
          "displayValue": "Daegu",
          "stateValue": "KR-27"
        },
        "KR-30": {
          "displayValue": "Daejeon",
          "stateValue": "KR-30"
        },
        "KR-42": {
          "displayValue": "Gangwon",
          "stateValue": "KR-42"
        },
        "KR-29": {
          "displayValue": "Gwangju City",
          "stateValue": "KR-29"
        },
        "KR-47": {
          "displayValue": "North Gyeongsang",
          "stateValue": "KR-47"
        },
        "KR-41": {
          "displayValue": "Gyeonggi",
          "stateValue": "KR-41"
        },
        "KR-48": {
          "displayValue": "South Gyeongsang",
          "stateValue": "KR-48"
        },
        "KR-28": {
          "displayValue": "Incheon",
          "stateValue": "KR-28"
        },
        "KR-49": {
          "displayValue": "Jeju",
          "stateValue": "KR-49"
        },
        "KR-45": {
          "displayValue": "North Jeolla",
          "stateValue": "KR-45"
        },
        "KR-46": {
          "displayValue": "South Jeolla",
          "stateValue": "KR-46"
        },
        "KR-50": {
          "displayValue": "Sejong",
          "stateValue": "KR-50"
        },
        "KR-11": {
          "displayValue": "Seoul",
          "stateValue": "KR-11"
        },
        "KR-31": {
          "displayValue": "Ulsan",
          "stateValue": "KR-31"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "SS": {
      "countryId": "SS",
      "displayValue": "South Sudan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": null
    },
    "ES": {
      "countryId": "ES",
      "displayValue": "Spain",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "C": {
          "displayValue": "A Coru├▒a",
          "stateValue": "C"
        },
        "VI": {
          "displayValue": "├ülava",
          "stateValue": "VI"
        },
        "AB": {
          "displayValue": "Albacete",
          "stateValue": "AB"
        },
        "A": {
          "displayValue": "Alicante",
          "stateValue": "A"
        },
        "AL": {
          "displayValue": "Almer├¡a",
          "stateValue": "AL"
        },
        "O": {
          "displayValue": "Asturias Province",
          "stateValue": "O"
        },
        "AV": {
          "displayValue": "├üvila",
          "stateValue": "AV"
        },
        "BA": {
          "displayValue": "Badajoz",
          "stateValue": "BA"
        },
        "PM": {
          "displayValue": "Balears Province",
          "stateValue": "PM"
        },
        "B": {
          "displayValue": "Barcelona",
          "stateValue": "B"
        },
        "BU": {
          "displayValue": "Burgos",
          "stateValue": "BU"
        },
        "CC": {
          "displayValue": "C├íceres",
          "stateValue": "CC"
        },
        "CA": {
          "displayValue": "C├ídiz",
          "stateValue": "CA"
        },
        "S": {
          "displayValue": "Cantabria Province",
          "stateValue": "S"
        },
        "CS": {
          "displayValue": "Castell├│n",
          "stateValue": "CS"
        },
        "CE": {
          "displayValue": "Ceuta",
          "stateValue": "CE"
        },
        "CR": {
          "displayValue": "Ciudad Real",
          "stateValue": "CR"
        },
        "CO": {
          "displayValue": "C├│rdoba",
          "stateValue": "CO"
        },
        "CU": {
          "displayValue": "Cuenca",
          "stateValue": "CU"
        },
        "GI": {
          "displayValue": "Girona",
          "stateValue": "GI"
        },
        "GR": {
          "displayValue": "Granada",
          "stateValue": "GR"
        },
        "GU": {
          "displayValue": "Guadalajara",
          "stateValue": "GU"
        },
        "SS": {
          "displayValue": "Gipuzkoa",
          "stateValue": "SS"
        },
        "H": {
          "displayValue": "Huelva",
          "stateValue": "H"
        },
        "HU": {
          "displayValue": "Huesca",
          "stateValue": "HU"
        },
        "J": {
          "displayValue": "Ja├®n",
          "stateValue": "J"
        },
        "LO": {
          "displayValue": "La Rioja Province",
          "stateValue": "LO"
        },
        "GC": {
          "displayValue": "Las Palmas",
          "stateValue": "GC"
        },
        "LE": {
          "displayValue": "Le├│n",
          "stateValue": "LE"
        },
        "L": {
          "displayValue": "Lleida",
          "stateValue": "L"
        },
        "LU": {
          "displayValue": "Lugo",
          "stateValue": "LU"
        },
        "M": {
          "displayValue": "Madrid Province",
          "stateValue": "M"
        },
        "MA": {
          "displayValue": "M├ílaga",
          "stateValue": "MA"
        },
        "ML": {
          "displayValue": "Melilla",
          "stateValue": "ML"
        },
        "MU": {
          "displayValue": "Murcia",
          "stateValue": "MU"
        },
        "NA": {
          "displayValue": "Navarra",
          "stateValue": "NA"
        },
        "OR": {
          "displayValue": "Ourense",
          "stateValue": "OR"
        },
        "P": {
          "displayValue": "Palencia",
          "stateValue": "P"
        },
        "PO": {
          "displayValue": "Pontevedra",
          "stateValue": "PO"
        },
        "SA": {
          "displayValue": "Salamanca",
          "stateValue": "SA"
        },
        "TF": {
          "displayValue": "Santa Cruz de Tenerife",
          "stateValue": "TF"
        },
        "SG": {
          "displayValue": "Segovia",
          "stateValue": "SG"
        },
        "SE": {
          "displayValue": "Seville",
          "stateValue": "SE"
        },
        "SO": {
          "displayValue": "Soria",
          "stateValue": "SO"
        },
        "T": {
          "displayValue": "Tarragona",
          "stateValue": "T"
        },
        "TE": {
          "displayValue": "Teruel",
          "stateValue": "TE"
        },
        "TO": {
          "displayValue": "Toledo",
          "stateValue": "TO"
        },
        "V": {
          "displayValue": "Valencia",
          "stateValue": "V"
        },
        "VA": {
          "displayValue": "Valladolid",
          "stateValue": "VA"
        },
        "BI": {
          "displayValue": "Biscay",
          "stateValue": "BI"
        },
        "ZA": {
          "displayValue": "Zamora",
          "stateValue": "ZA"
        },
        "Z": {
          "displayValue": "Zaragoza",
          "stateValue": "Z"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "LK": {
      "countryId": "LK",
      "displayValue": "Sri Lanka",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "BL": {
      "countryId": "BL",
      "displayValue": "St. Barth├®lemy",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^97133$"
    },
    "SH": {
      "countryId": "SH",
      "displayValue": "St. Helena",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(ASCN 1ZZ|TDCU 1ZZ|STHL 1ZZ)"
    },
    "KN": {
      "countryId": "KN",
      "displayValue": "St. Kitts & Nevis",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "KN\\d{4}(\\-\\d{4})?"
    },
    "LC": {
      "countryId": "LC",
      "displayValue": "St. Lucia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "LC\\d{2}  \\d{3}"
    },
    "MF": {
      "countryId": "MF",
      "displayValue": "St. Martin",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^97150$"
    },
    "PM": {
      "countryId": "PM",
      "displayValue": "St. Pierre & Miquelon",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^97500$"
    },
    "VC": {
      "countryId": "VC",
      "displayValue": "St. Vincent & Grenadines",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(VC)(\\d{4})"
    },
    "SD": {
      "countryId": "SD",
      "displayValue": "Sudan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "SR": {
      "countryId": "SR",
      "displayValue": "Suriname",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "SJ": {
      "countryId": "SJ",
      "displayValue": "Svalbard & Jan Mayen",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "^\\d{4}$"
    },
    "SE": {
      "countryId": "SE",
      "displayValue": "Sweden",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City/town",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{2} \\d{2}"
    },
    "CH": {
      "countryId": "CH",
      "displayValue": "Switzerland",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{3}"
    },
    "TW": {
      "countryId": "TW",
      "displayValue": "Taiwan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(\\d{3}\\-\\d{3})|(\\d{3}[-]\\d{2})|(\\d{6})|(\\d{3})"
    },
    "TJ": {
      "countryId": "TJ",
      "displayValue": "Tajikistan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "7\\d{5}"
    },
    "TZ": {
      "countryId": "TZ",
      "displayValue": "Tanzania",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "TH": {
      "countryId": "TH",
      "displayValue": "Thailand",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{province} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Province",
      "stateRequired": true,
      "states": {
        "TH-37": {
          "displayValue": "Amnat Charoen",
          "stateValue": "TH-37"
        },
        "TH-15": {
          "displayValue": "Ang Thong",
          "stateValue": "TH-15"
        },
        "TH-10": {
          "displayValue": "Bangkok",
          "stateValue": "TH-10"
        },
        "TH-38": {
          "displayValue": "Bueng Kan",
          "stateValue": "TH-38"
        },
        "TH-31": {
          "displayValue": "Buri Ram",
          "stateValue": "TH-31"
        },
        "TH-24": {
          "displayValue": "Chachoengsao",
          "stateValue": "TH-24"
        },
        "TH-18": {
          "displayValue": "Chai Nat",
          "stateValue": "TH-18"
        },
        "TH-36": {
          "displayValue": "Chaiyaphum",
          "stateValue": "TH-36"
        },
        "TH-22": {
          "displayValue": "Chanthaburi",
          "stateValue": "TH-22"
        },
        "TH-50": {
          "displayValue": "Chiang Mai",
          "stateValue": "TH-50"
        },
        "TH-57": {
          "displayValue": "Chiang Rai",
          "stateValue": "TH-57"
        },
        "TH-20": {
          "displayValue": "Chon Buri",
          "stateValue": "TH-20"
        },
        "TH-86": {
          "displayValue": "Chumphon",
          "stateValue": "TH-86"
        },
        "TH-46": {
          "displayValue": "Kalasin",
          "stateValue": "TH-46"
        },
        "TH-62": {
          "displayValue": "Kamphaeng Phet",
          "stateValue": "TH-62"
        },
        "TH-71": {
          "displayValue": "Kanchanaburi",
          "stateValue": "TH-71"
        },
        "TH-40": {
          "displayValue": "Khon Kaen",
          "stateValue": "TH-40"
        },
        "TH-81": {
          "displayValue": "Krabi",
          "stateValue": "TH-81"
        },
        "TH-52": {
          "displayValue": "Lampang",
          "stateValue": "TH-52"
        },
        "TH-51": {
          "displayValue": "Lamphun",
          "stateValue": "TH-51"
        },
        "TH-42": {
          "displayValue": "Loei",
          "stateValue": "TH-42"
        },
        "TH-16": {
          "displayValue": "Lopburi",
          "stateValue": "TH-16"
        },
        "TH-58": {
          "displayValue": "Mae Hong Son",
          "stateValue": "TH-58"
        },
        "TH-44": {
          "displayValue": "Maha Sarakham",
          "stateValue": "TH-44"
        },
        "TH-49": {
          "displayValue": "Mukdahan",
          "stateValue": "TH-49"
        },
        "TH-26": {
          "displayValue": "Nakhon Nayok",
          "stateValue": "TH-26"
        },
        "TH-73": {
          "displayValue": "Nakhon Pathom",
          "stateValue": "TH-73"
        },
        "TH-48": {
          "displayValue": "Nakhon Phanom",
          "stateValue": "TH-48"
        },
        "TH-30": {
          "displayValue": "Nakhon Ratchasima",
          "stateValue": "TH-30"
        },
        "TH-60": {
          "displayValue": "Nakhon Sawan",
          "stateValue": "TH-60"
        },
        "TH-80": {
          "displayValue": "Nakhon Si Thammarat",
          "stateValue": "TH-80"
        },
        "TH-55": {
          "displayValue": "Nan",
          "stateValue": "TH-55"
        },
        "TH-96": {
          "displayValue": "Narathiwat",
          "stateValue": "TH-96"
        },
        "TH-39": {
          "displayValue": "Nong Bua Lam Phu",
          "stateValue": "TH-39"
        },
        "TH-43": {
          "displayValue": "Nong Khai",
          "stateValue": "TH-43"
        },
        "TH-12": {
          "displayValue": "Nonthaburi",
          "stateValue": "TH-12"
        },
        "TH-13": {
          "displayValue": "Pathum Thani",
          "stateValue": "TH-13"
        },
        "TH-94": {
          "displayValue": "Pattani",
          "stateValue": "TH-94"
        },
        "TH-S": {
          "displayValue": "Pattaya",
          "stateValue": "TH-S"
        },
        "TH-82": {
          "displayValue": "Phang Nga",
          "stateValue": "TH-82"
        },
        "TH-93": {
          "displayValue": "Phatthalung",
          "stateValue": "TH-93"
        },
        "TH-56": {
          "displayValue": "Phayao",
          "stateValue": "TH-56"
        },
        "TH-67": {
          "displayValue": "Phetchabun",
          "stateValue": "TH-67"
        },
        "TH-76": {
          "displayValue": "Phetchaburi",
          "stateValue": "TH-76"
        },
        "TH-66": {
          "displayValue": "Phichit",
          "stateValue": "TH-66"
        },
        "TH-65": {
          "displayValue": "Phitsanulok",
          "stateValue": "TH-65"
        },
        "TH-14": {
          "displayValue": "Phra Nakhon Si Ayutthaya",
          "stateValue": "TH-14"
        },
        "TH-54": {
          "displayValue": "Phrae",
          "stateValue": "TH-54"
        },
        "TH-83": {
          "displayValue": "Phuket",
          "stateValue": "TH-83"
        },
        "TH-25": {
          "displayValue": "Prachin Buri",
          "stateValue": "TH-25"
        },
        "TH-77": {
          "displayValue": "Prachuap Khiri Khan",
          "stateValue": "TH-77"
        },
        "TH-85": {
          "displayValue": "Ranong",
          "stateValue": "TH-85"
        },
        "TH-70": {
          "displayValue": "Ratchaburi",
          "stateValue": "TH-70"
        },
        "TH-21": {
          "displayValue": "Rayong",
          "stateValue": "TH-21"
        },
        "TH-45": {
          "displayValue": "Roi Et",
          "stateValue": "TH-45"
        },
        "TH-27": {
          "displayValue": "Sa Kaeo",
          "stateValue": "TH-27"
        },
        "TH-47": {
          "displayValue": "Sakon Nakhon",
          "stateValue": "TH-47"
        },
        "TH-11": {
          "displayValue": "Samut Prakan",
          "stateValue": "TH-11"
        },
        "TH-74": {
          "displayValue": "Samut Sakhon",
          "stateValue": "TH-74"
        },
        "TH-75": {
          "displayValue": "Samut Songkhram",
          "stateValue": "TH-75"
        },
        "TH-19": {
          "displayValue": "Saraburi",
          "stateValue": "TH-19"
        },
        "TH-91": {
          "displayValue": "Satun",
          "stateValue": "TH-91"
        },
        "TH-17": {
          "displayValue": "Sing Buri",
          "stateValue": "TH-17"
        },
        "TH-33": {
          "displayValue": "Si Sa Ket",
          "stateValue": "TH-33"
        },
        "TH-90": {
          "displayValue": "Songkhla",
          "stateValue": "TH-90"
        },
        "TH-64": {
          "displayValue": "Sukhothai",
          "stateValue": "TH-64"
        },
        "TH-72": {
          "displayValue": "Suphanburi",
          "stateValue": "TH-72"
        },
        "TH-84": {
          "displayValue": "Surat Thani",
          "stateValue": "TH-84"
        },
        "TH-32": {
          "displayValue": "Surin",
          "stateValue": "TH-32"
        },
        "TH-63": {
          "displayValue": "Tak",
          "stateValue": "TH-63"
        },
        "TH-92": {
          "displayValue": "Trang",
          "stateValue": "TH-92"
        },
        "TH-23": {
          "displayValue": "Trat",
          "stateValue": "TH-23"
        },
        "TH-34": {
          "displayValue": "Ubon Ratchathani",
          "stateValue": "TH-34"
        },
        "TH-41": {
          "displayValue": "Udon Thani",
          "stateValue": "TH-41"
        },
        "TH-61": {
          "displayValue": "Uthai Thani",
          "stateValue": "TH-61"
        },
        "TH-53": {
          "displayValue": "Uttaradit",
          "stateValue": "TH-53"
        },
        "TH-95": {
          "displayValue": "Yala",
          "stateValue": "TH-95"
        },
        "TH-35": {
          "displayValue": "Yasothon",
          "stateValue": "TH-35"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "TL": {
      "countryId": "TL",
      "displayValue": "Timor-Leste",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "TG": {
      "countryId": "TG",
      "displayValue": "Togo",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "TK": {
      "countryId": "TK",
      "displayValue": "Tokelau",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "TO": {
      "countryId": "TO",
      "displayValue": "Tonga",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "TT": {
      "countryId": "TT",
      "displayValue": "Trinidad & Tobago",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "TA": {
      "countryId": "TA",
      "displayValue": "Tristan da Cunha",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": null
    },
    "TN": {
      "countryId": "TN",
      "displayValue": "Tunisia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{4}"
    },
    "TR": {
      "countryId": "TR",
      "displayValue": "Turkey",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "TM": {
      "countryId": "TM",
      "displayValue": "Turkmenistan",
      "displayFormat": "{zip}{city}_{country}_{firstName}{lastName}_{company}_{address1}_{address2}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "7\\d{5}"
    },
    "TC": {
      "countryId": "TC",
      "displayValue": "Turks & Caicos Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(TKCA 1ZZ)"
    },
    "TV": {
      "countryId": "TV",
      "displayValue": "Tuvalu",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "UM": {
      "countryId": "UM",
      "displayValue": "U.S. Outlying Islands",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": null
    },
    "UG": {
      "countryId": "UG",
      "displayValue": "Uganda",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "UA": {
      "countryId": "UA",
      "displayValue": "Ukraine",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "AE": {
      "countryId": "AE",
      "displayValue": "United Arab Emirates",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Emirate",
      "stateRequired": true,
      "states": {
        "AZ": {
          "displayValue": "Abu Dhabi",
          "stateValue": "AZ"
        },
        "AJ": {
          "displayValue": "Ajman",
          "stateValue": "AJ"
        },
        "DU": {
          "displayValue": "Dubai",
          "stateValue": "DU"
        },
        "FU": {
          "displayValue": "Fujairah",
          "stateValue": "FU"
        },
        "RK": {
          "displayValue": "Ras al-Khaimah",
          "stateValue": "RK"
        },
        "SH": {
          "displayValue": "Sharjah",
          "stateValue": "SH"
        },
        "UQ": {
          "displayValue": "Umm al-Quwain",
          "stateValue": "UQ"
        }
      },
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "GB": {
      "countryId": "GB",
      "displayValue": "United Kingdom",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": true,
      "states": {
        "BFP": {
          "displayValue": "British Forces",
          "stateValue": "BFP"
        },
        "ENG": {
          "displayValue": "England",
          "stateValue": "ENG"
        },
        "NIR": {
          "displayValue": "Northern Ireland",
          "stateValue": "NIR"
        },
        "SCT": {
          "displayValue": "Scotland",
          "stateValue": "SCT"
        },
        "WLS": {
          "displayValue": "Wales",
          "stateValue": "WLS"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "([G][I][R] 0[A]{2})|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2})"
    },
    "US": {
      "countryId": "US",
      "displayValue": "United States",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {province} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "State",
      "stateRequired": true,
      "states": {
        "AL": {
          "displayValue": "Alabama",
          "stateValue": "AL"
        },
        "AK": {
          "displayValue": "Alaska",
          "stateValue": "AK"
        },
        "AS": {
          "displayValue": "American Samoa",
          "stateValue": "AS"
        },
        "AZ": {
          "displayValue": "Arizona",
          "stateValue": "AZ"
        },
        "AR": {
          "displayValue": "Arkansas",
          "stateValue": "AR"
        },
        "CA": {
          "displayValue": "California",
          "stateValue": "CA"
        },
        "CO": {
          "displayValue": "Colorado",
          "stateValue": "CO"
        },
        "CT": {
          "displayValue": "Connecticut",
          "stateValue": "CT"
        },
        "DE": {
          "displayValue": "Delaware",
          "stateValue": "DE"
        },
        "DC": {
          "displayValue": "Washington DC",
          "stateValue": "DC"
        },
        "FM": {
          "displayValue": "Micronesia",
          "stateValue": "FM"
        },
        "FL": {
          "displayValue": "Florida",
          "stateValue": "FL"
        },
        "GA": {
          "displayValue": "Georgia",
          "stateValue": "GA"
        },
        "GU": {
          "displayValue": "Guam",
          "stateValue": "GU"
        },
        "HI": {
          "displayValue": "Hawaii",
          "stateValue": "HI"
        },
        "ID": {
          "displayValue": "Idaho",
          "stateValue": "ID"
        },
        "IL": {
          "displayValue": "Illinois",
          "stateValue": "IL"
        },
        "IN": {
          "displayValue": "Indiana",
          "stateValue": "IN"
        },
        "IA": {
          "displayValue": "Iowa",
          "stateValue": "IA"
        },
        "KS": {
          "displayValue": "Kansas",
          "stateValue": "KS"
        },
        "KY": {
          "displayValue": "Kentucky",
          "stateValue": "KY"
        },
        "LA": {
          "displayValue": "Louisiana",
          "stateValue": "LA"
        },
        "ME": {
          "displayValue": "Maine",
          "stateValue": "ME"
        },
        "MH": {
          "displayValue": "Marshall Islands",
          "stateValue": "MH"
        },
        "MD": {
          "displayValue": "Maryland",
          "stateValue": "MD"
        },
        "MA": {
          "displayValue": "Massachusetts",
          "stateValue": "MA"
        },
        "MI": {
          "displayValue": "Michigan",
          "stateValue": "MI"
        },
        "MN": {
          "displayValue": "Minnesota",
          "stateValue": "MN"
        },
        "MS": {
          "displayValue": "Mississippi",
          "stateValue": "MS"
        },
        "MO": {
          "displayValue": "Missouri",
          "stateValue": "MO"
        },
        "MT": {
          "displayValue": "Montana",
          "stateValue": "MT"
        },
        "NE": {
          "displayValue": "Nebraska",
          "stateValue": "NE"
        },
        "NV": {
          "displayValue": "Nevada",
          "stateValue": "NV"
        },
        "NH": {
          "displayValue": "New Hampshire",
          "stateValue": "NH"
        },
        "NJ": {
          "displayValue": "New Jersey",
          "stateValue": "NJ"
        },
        "NM": {
          "displayValue": "New Mexico",
          "stateValue": "NM"
        },
        "NY": {
          "displayValue": "New York",
          "stateValue": "NY"
        },
        "NC": {
          "displayValue": "North Carolina",
          "stateValue": "NC"
        },
        "ND": {
          "displayValue": "North Dakota",
          "stateValue": "ND"
        },
        "MP": {
          "displayValue": "Northern Mariana Islands",
          "stateValue": "MP"
        },
        "OH": {
          "displayValue": "Ohio",
          "stateValue": "OH"
        },
        "OK": {
          "displayValue": "Oklahoma",
          "stateValue": "OK"
        },
        "OR": {
          "displayValue": "Oregon",
          "stateValue": "OR"
        },
        "PW": {
          "displayValue": "Palau",
          "stateValue": "PW"
        },
        "PA": {
          "displayValue": "Pennsylvania",
          "stateValue": "PA"
        },
        "PR": {
          "displayValue": "Puerto Rico",
          "stateValue": "PR"
        },
        "RI": {
          "displayValue": "Rhode Island",
          "stateValue": "RI"
        },
        "SC": {
          "displayValue": "South Carolina",
          "stateValue": "SC"
        },
        "SD": {
          "displayValue": "South Dakota",
          "stateValue": "SD"
        },
        "TN": {
          "displayValue": "Tennessee",
          "stateValue": "TN"
        },
        "TX": {
          "displayValue": "Texas",
          "stateValue": "TX"
        },
        "UT": {
          "displayValue": "Utah",
          "stateValue": "UT"
        },
        "VT": {
          "displayValue": "Vermont",
          "stateValue": "VT"
        },
        "VI": {
          "displayValue": "U.S. Virgin Islands",
          "stateValue": "VI"
        },
        "VA": {
          "displayValue": "Virginia",
          "stateValue": "VA"
        },
        "WA": {
          "displayValue": "Washington",
          "stateValue": "WA"
        },
        "WV": {
          "displayValue": "West Virginia",
          "stateValue": "WV"
        },
        "WI": {
          "displayValue": "Wisconsin",
          "stateValue": "WI"
        },
        "WY": {
          "displayValue": "Wyoming",
          "stateValue": "WY"
        },
        "AA": {
          "displayValue": "Armed Forces Americas",
          "stateValue": "AA"
        },
        "AE": {
          "displayValue": "Armed Forces Europe",
          "stateValue": "AE"
        },
        "AP": {
          "displayValue": "Armed Forces Pacific",
          "stateValue": "AP"
        }
      },
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}(-\\d{4})?"
    },
    "UY": {
      "countryId": "UY",
      "displayValue": "Uruguay",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-9]\\d{4}"
    },
    "UZ": {
      "countryId": "UZ",
      "displayValue": "Uzbekistan",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "VU": {
      "countryId": "VU",
      "displayValue": "Vanuatu",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "VA": {
      "countryId": "VA",
      "displayValue": "Vatican City",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(00120)"
    },
    "VE": {
      "countryId": "VE",
      "displayValue": "Venezuela",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "[1-8]\\d{3}"
    },
    "VN": {
      "countryId": "VN",
      "displayValue": "Vietnam",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city} {zip}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{6}"
    },
    "WF": {
      "countryId": "WF",
      "displayValue": "Wallis & Futuna",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "(986)\\d{2}"
    },
    "EH": {
      "countryId": "EH",
      "displayValue": "Western Sahara",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": null
    },
    "YE": {
      "countryId": "YE",
      "displayValue": "Yemen",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    },
    "ZM": {
      "countryId": "ZM",
      "displayValue": "Zambia",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{zip} {city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": true,
      "postalCodeRegex": "\\d{5}"
    },
    "ZW": {
      "countryId": "ZW",
      "displayValue": "Zimbabwe",
      "displayFormat": "{firstName} {lastName}_{company}_{address1}_{address2}_{city}_{country}_{phone}",
      "cityDisplayValue": "City",
      "stateDisplayValue": "Region",
      "stateRequired": false,
      "states": {},
      "postalCodeRequired": false,
      "postalCodeRegex": ""
    }
  }
};
export default countriesAndStates

const {countries} = countriesAndStates
const countryEntries = Object.entries(countries)
countryEntries.sort(([_a, a], [_b, b]) => {
  if(a.displayValue < b.displayValue) { return -1 }
  if(a.displayValue > b.displayValue) { return 1 }
  return 0
})

export const ascCountryCodes = (() => {
  const countryCodes = countryEntries.map(e => e[0]);
  const topCountryCodes = ['SG', 'MY']
  _.pull(countryCodes, ...topCountryCodes);
  return [...topCountryCodes, ...countryCodes];
})();

function renderLineTemplate(countryFieldSettings: Country, fields: string, address: UserAddress) {
  const mapper = {
    fullName: 'firstName',
    address: 'address1',
    unitNumber: 'address2',
    city: 'city',
    country: 'country',
    phoneNumber: 'phone',
    postalCode: 'zip',
    state: 'province'
  };

  let line = fields
    .replace('{company}', '')
    .replace('{lastName}', '');
  for (const [key, formatKey] of Object.entries(mapper) as [keyof typeof mapper, string][]) {
    let value = address[key];

    switch (key) {
      case 'country':
        value = countriesAndStates.countries[address.country].displayValue;
        break;
      case 'state': {
        if (!address.state) {
          break;
        }

        const stateData = countriesAndStates.countries[address.country].states[address.state];
        if (stateData) {
          value = stateData.displayValue;
        }
        break;
      }
    }
    line = line.replace(`{${formatKey}}`, value || '');
  }

  return line;
}

export function formatAddress(address: UserAddress) {
  const countryFieldSettings = countriesAndStates.countries[address.country];
  return countryFieldSettings.displayFormat
    .split('_')
    .map(fields => renderLineTemplate(countryFieldSettings, fields, address).trim())
    .filter(line => line.length > 0);
}