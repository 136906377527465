import userErrorCodes from './user'
import authErrorCodes from './auth'
import addressErrorCodes from './address'
import generalErrorCodes from './general'

const errorCodes: Record<string, string> = {
	...userErrorCodes,
	...authErrorCodes,
	...addressErrorCodes,
	...generalErrorCodes
}

export default errorCodes