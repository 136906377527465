import {action} from "typesafe-actions";
import {actionTypes} from "../../actions";
import {CartItem, CartItemProductForCart, CartItemUpdate} from "../../../types/models/cart_item";

export function set(cartItems: CartItem[], products: Record<string, CartItemProductForCart>) {
  return action(actionTypes.cart.SET_CART, {
    cartItems,
    products
  });
}

export function removeItem(id: string) {
  return action(actionTypes.cart.CART_REMOVE_ITEM, {
    id
  });
}

export function addItem(cartItem: CartItem, product: CartItemProductForCart) {
  return action(actionTypes.cart.CART_ADD_ITEM, {
    cartItem,
    product
  });
}

export function setProduct(productId: string, product?: CartItemProductForCart) {
  return action(actionTypes.cart.CART_SET_PRODUCT, {
    productId,
    product
  });
}

export function setItem(update: CartItemUpdate, id: string) {
  return action(actionTypes.cart.CART_SET_ITEM, {
    update,
    id
  })
}

export function fetchCart() {
  return action(actionTypes.cart.SET_CART_ASYNC, null)
}

export function buyNow(productId: string, variantPath: undefined | null | string, quantity: number, message: string) {
  return action(actionTypes.cart.CART_BUY_NOW, {
    productId,
    variantPath,
    quantity,
    message,
  })
}

export function addItemAsync(productId: string, variantPath: undefined | null | string, quantity: number, message: string) {
  return action(actionTypes.cart.CART_ADD_ITEM_ASYNC, {
    productId,
    variantPath,
    quantity,
    message,
  })
}

export function removeItemAsync(id: string) {
  return action(actionTypes.cart.CART_REMOVE_ITEM_ASYNC, id)
}

export function setItemQuantityAsync(quantity: number, id: string) {
  return action(actionTypes.cart.CART_SET_ITEM_ASYNC, {
    set: {
      quantity,
    },
    id
  })
}

export function setItemMessageAsync(message: string, id: string) {
  return action(actionTypes.cart.CART_SET_ITEM_ASYNC, {
    set: {
      message,
    },
    id
  })
}

export function setAddItemError(message: string | null) {
  return action(actionTypes.cart.SET_ADD_ITEM_ERROR, message)
}

export function setRemoveItemError(message: string | null) {
  return action(actionTypes.cart.SET_REMOVE_ITEM_ERROR, message)
}

export function setSetItemError(message: string | null) {
  return action(actionTypes.cart.SET_SET_ITEM_ERROR, message)
}

export function setFetchCartError(message: string | null) {
  return action(actionTypes.cart.SET_FETCH_CART_ERROR, message)
}