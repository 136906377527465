import {action} from "typesafe-actions";
import {actionTypes} from "../../actions";
import {QuotationItem, QuotationItemUpdate} from "../../../types/models/quotation_item";
import {CartItemProductForCart} from "../../../types/models/cart_item";

export function set(quotationItems: QuotationItem[], products: Record<string, CartItemProductForCart>) {
  return action(actionTypes.quotationItems.SET_QUOTATION_ITEMS, {
    quotationItems,
    products
  });
}

export function removeItem(id: string) {
  return action(actionTypes.quotationItems.REMOVE_QUOTATION_ITEM, {
    id
  });
}

export function addItem(quotationItem: QuotationItem, product?: CartItemProductForCart) {
  return action(actionTypes.quotationItems.ADD_QUOTATION_ITEM, {
    quotationItem,
    product
  });
}

export function setItem(update: QuotationItemUpdate, id: string) {
  return action(actionTypes.quotationItems.SET_QUOTATION_ITEM, {
    update,
    id
  });
}

export function setProduct(productId: string, product?: CartItemProductForCart) {
  return action(actionTypes.quotationItems.SET_QUOTATION_ITEM_PRODUCT, {
    productId,
    product
  });
}

export function fetchQuotationItems() {
  return action(actionTypes.quotationItems.FETCH_QUOTATION_ITEMS, null);
}

export function addQuotationFromCartItem(cartItemId: string) {
  return action(actionTypes.quotationItems.ADD_QUOTATION_ITEM_INTERNAL_ASYNC, {
    cartItemId
  });
}

export function addQuotationFromProduct(productId: string, variantPath: undefined | null | string, quantity: number, instructions: string) {
  return action(actionTypes.quotationItems.ADD_QUOTATION_ITEM_INTERNAL_FROM_PRODUCT_ASYNC, {
    productId,
    variantPath,
    quantity,
    instructions
  });
}

export function removeItemAsync(id: string) {
  return action(actionTypes.quotationItems.REMOVE_QUOTATION_ITEM_ASYNC, {
    id
  });
}

export function setItemQuantityAsync(quantity: number, id: string) {
  return action(actionTypes.quotationItems.SET_QUOTATION_ITEM_ASYNC, {
    set: {
      quantity,
    },
    id
  });
}

export function setItemInstructionsAsync(instructions: string, id: string) {
  return action(actionTypes.quotationItems.SET_QUOTATION_ITEM_ASYNC, {
    set: {
      instructions
    },
    id
  });
}

export function setAddInternalItemError(message: string | null) {
  return action(actionTypes.quotationItems.SET_ADD_INTERNAL_QUOTATION_ITEM_ERROR, message);
}

export function setAddInternalItemFromProductError(message: string | null) {
  return action(actionTypes.quotationItems.SET_ADD_INTERNAL_QUOTATION_ITEM_FROM_PRODUCT_ERROR, message);
}

export function setRemoveItemError(message: string | null) {
  return action(actionTypes.quotationItems.SET_REMOVE_QUOTATION_ITEM_ERROR, message);
}

export function setSetItemError(message: string | null) {
  return action(actionTypes.quotationItems.SET_SET_QUOTATION_ITEM_ERROR, message);
}

export function setFetchQuotationItemsError(message: string | null) {
  return action(actionTypes.quotationItems.SET_FETCH_QUOTATION_ITEMS_ERROR, message);
}