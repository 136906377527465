interface PostLoginRedirectPath {

    save(postLoginPath: string): void;

    clear(): void;

    get(): string | null;
}

const KEY = 'indo4ward:store:post_login_redirect_path';

function factory(): PostLoginRedirectPath {
    if (typeof window !== "undefined" && window.sessionStorage) {
        return {
            save(path: string) {
                window.sessionStorage.setItem(KEY, path);
            },
            clear(): void {
                window.sessionStorage.removeItem(KEY);
            },
            get(): string | null {
                return window.sessionStorage.getItem(KEY);
            }
        };
    } else {
        return {
            save(_: string) {},
            clear(): void {},
            get(): string | null {
                return null;
            }
        };
    }
}

export const { save, clear, get } = factory();