export function isEmpty<T extends object>(obj: T) {
	return obj.constructor === Object && Object.keys(obj).length === 0
}

import { pick } from 'lodash';
import { omit } from 'lodash';
import { has } from 'lodash';

export const extractProperties = pick;
export const excludeProperties = omit;
export const checkNested = has;