import urljoin from 'url-join';

export function thumbUrl(relativePath: string) {
	const originalImageUrl = imageUrl(relativePath);
	if (!originalImageUrl) {
		return '';
	}

	return originalImageUrl + '_t';
}

export function imageUrl(relativePath: string) {
	const baseUrl = process.env.NEXT_PUBLIC_IMAGES_ENDPOINT;
	if (!baseUrl) {
		console.error('env.NEXT_PUBLIC_IMAGES_ENDPOINT is undefined');
		return '';
	}

	return urljoin(baseUrl, relativePath);
}