import {ActionType} from "typesafe-actions";
import {actionTypes} from '../actions'
import {CartItem, CartItemProductForCart} from "../../types/models/cart_item";
import * as cartActionsFactories from '../actions/cart/factories';
import {findCartItemIndexById} from "../../lib/cart_item";

type CartActions = ActionType<typeof cartActionsFactories>;

export interface CartState {
	data: CartItem[];
	productById: Record<string, CartItemProductForCart>;
	dataLoaded: boolean;
	addItemError: string | null;
	removeItemError: string | null;
	setItemError: string | null;
	fetchItemError: string | null;
}

const defaultState: CartState = {
	data: [],
	productById: {},
	dataLoaded: false,
	addItemError: null,
	removeItemError: null,
	setItemError: null,
	fetchItemError: null,
}

export default function cartReducer(state: CartState = defaultState, action: CartActions) {
	switch (action.type) {
		case actionTypes.cart.SET_CART:
			return Object.assign({}, state, {
				data: action.payload.cartItems,
				productById: action.payload.products,
				dataLoaded: true
			})
		case actionTypes.cart.CART_REMOVE_ITEM: {
			const {id} = action.payload;
			const removeItemNewData = state.data.slice();
			const index = findCartItemIndexById(removeItemNewData, id);
			removeItemNewData.splice(index, 1)
			return Object.assign({}, state, {
				data: removeItemNewData
			});
		}
		case actionTypes.cart.CART_ADD_ITEM:
			const {cartItem, product} = action.payload;
			const addItemNewData = state.data.slice()
			addItemNewData.push(cartItem);

			const {productId} = cartItem;

			const newProductById = {...state.productById};
			if (product) {
				newProductById[productId] = product;
			} else {
				delete newProductById[productId];
			}
			
			return Object.assign({}, state, {
				data: addItemNewData,
				productById: newProductById
			})
		case actionTypes.cart.CART_SET_ITEM: {
			const {id} = action.payload;
			const setItemNewData = state.data.slice();
			const index = findCartItemIndexById(setItemNewData, id);
			setItemNewData[index] = Object.assign({}, setItemNewData[index], action.payload.update);
			return Object.assign({}, state, {
				data: setItemNewData
			});
		}
		case actionTypes.cart.CART_SET_PRODUCT: {
			const {product, productId} = action.payload;
			const newProductById = {...state.productById};
			if (product) {
				newProductById[productId] = product;
			} else {
				delete newProductById[productId];
			}

			return {
				...state,
				productById: newProductById
			};
		}
		case actionTypes.cart.SET_ADD_ITEM_ERROR:
			return Object.assign({}, state, {
				addItemError: action.payload
			})
		case actionTypes.cart.SET_REMOVE_ITEM_ERROR:
			return Object.assign({}, state, {
				removeItemError: action.payload
			})
		case actionTypes.cart.SET_SET_ITEM_ERROR:
			return Object.assign({}, state, {
				setItemError: action.payload
			})
		case actionTypes.cart.SET_FETCH_CART_ERROR:
			return Object.assign({}, state, {
				fetchItemError: action.payload
			})
		default:
			return state
	}
}