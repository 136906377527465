export const SET_CART = 'SET_CART';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_SET_ITEM = 'CART_SET_ITEM';
export const CART_ADD_ITEM_ASYNC = 'CART_ADD_ITEM_ASYNC';
export const CART_REMOVE_ITEM_ASYNC = 'CART_REMOVE_ITEM_ASYNC';
export const CART_SET_ITEM_ASYNC = 'CART_SET_ITEM_ASYNC';
export const CART_BUY_NOW = 'CART_BUY_NOW';
export const CART_SET_PRODUCT = 'CART_SET_PRODUCT';
export const SET_CART_ASYNC = 'SET_CART_ASYNC';
export const SET_ADD_ITEM_ERROR = 'ADD_TO_CART_ERROR';
export const SET_REMOVE_ITEM_ERROR = 'REMOVE_FROM_CART_ERROR';
export const SET_SET_ITEM_ERROR = 'SET_CART_ITEM_QUANTITY_ERROR';
export const SET_FETCH_CART_ERROR = 'FETCH_CART_ERROR';