import {ActionType} from "typesafe-actions";
import {actionTypes} from '../actions'
import {ConfigMap} from "../../types/models/config";
import * as configActionFactories from '../actions/config/factories';

type ConfigActions = ActionType<typeof configActionFactories>;

interface ConfigState {
	data: ConfigMap | null;
	clientLoaded: boolean;
	fetchAllError: string | null;
}

const defaultState = {
	data: null,
	clientLoaded: false,
	fetchAllError: null
}

function configReducer(state: ConfigState = defaultState, action: ConfigActions) {
	switch (action.type) {
		case actionTypes.config.SET_FETCH_ALL_ERROR:
			return Object.assign({}, state, {
				fetchAllError: action.payload
			})
		case actionTypes.config.SET_ALL:
			return Object.assign({}, state, {
				data: action.payload,
				clientLoaded: true
			})
	}
	return state
}

export default configReducer