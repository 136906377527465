import * as userActionTypes from './actions/user/types';
import * as categoryActionTypes from './actions/category/types';
import * as cartActionTypes from './actions/cart/types';
import * as configActionTypes from './actions/config/types';
import * as regionActionTypes from './actions/region/types';
import * as quotationItemActionTypes from './actions/quotation_items/types';

import * as userActionFactories from './actions/user/factories';
import * as categoryActionFactories from './actions/category/factories';
import * as cartActionFactories from './actions/cart/factories';
import * as configActionFactories from './actions/config/factories';
import * as regionActionFactories from './actions/region/factories';
import * as quotationItemsActionFactories from './actions/quotation_items/factories'

export const actionTypes = {
	user: userActionTypes,
	category: categoryActionTypes,
	cart: cartActionTypes,
	config: configActionTypes,
	region: regionActionTypes,
	quotationItems: quotationItemActionTypes
}

export const actions = {
	user: userActionFactories,
	category: categoryActionFactories,
	cart: cartActionFactories,
	config: configActionFactories,
	region: regionActionFactories,
	quotationItem: quotationItemsActionFactories
}
