export const SET_QUOTATION_ITEMS = 'SET_QUOTATION_ITEMS';
export const REMOVE_QUOTATION_ITEM = 'REMOVE_QUOTATION_ITEM';
export const ADD_QUOTATION_ITEM = 'ADD_QUOTATION_ITEM';
export const ADD_QUOTATION_ITEM_INTERNAL_ASYNC = 'ADD_QUOTATION_ITEM_INTERNAL_ASYNC';
export const ADD_QUOTATION_ITEM_INTERNAL_FROM_PRODUCT_ASYNC = 'ADD_QUOTATION_ITEM_INTERNAL_FROM_PRODUCT_ASYNC';
export const SET_QUOTATION_ITEM = 'SET_QUOTATION_ITEM';
export const REMOVE_QUOTATION_ITEM_ASYNC = 'REMOVE_QUOTATION_ITEM_ASYNC';
export const SET_QUOTATION_ITEM_ASYNC = 'SET_QUOTATION_ITEM_ASYNC';
export const FETCH_QUOTATION_ITEMS = 'FETCH_QUOTATION_ITEMS';
export const SET_ADD_INTERNAL_QUOTATION_ITEM_ERROR = 'SET_ADD_INTERNAL_QUOTATION_ITEM_ERROR';
export const SET_ADD_INTERNAL_QUOTATION_ITEM_FROM_PRODUCT_ERROR = 'SET_ADD_INTERNAL_QUOTATION_ITEM_FROM_PRODUCT_ERROR';
export const SET_REMOVE_QUOTATION_ITEM_ERROR = 'SET_REMOVE_QUOTATION_ITEM_ERROR';
export const SET_SET_QUOTATION_ITEM_ERROR = 'SET_SET_QUOTATION_ITEM_ERROR';
export const SET_FETCH_QUOTATION_ITEMS_ERROR = 'SET_FETCH_QUOTATION_ITEMS_ERROR';
export const SET_QUOTATION_ITEM_PRODUCT = 'SET_QUOTATION_ITEM_PRODUCT';