import escapeRegExp from 'lodash/escapeRegExp';
import capitalize from 'lodash/capitalize'

export const toTitleCase = capitalize;

export function csFormat(text: string, ...args: string[]) {
  return text.replace(/{(\d+)}/g, function(match, number) {
    return typeof args[number] != 'undefined'
      ? args[number]
      : match
    ;
  });
}

export function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function isBlank(str?: string): boolean {
  if (!str) {
    return true;
  }

  return !/\S/.test(str);
}

export function isUpperCase(str: string): boolean {
  if (str) {
    const countryCodeUpperCase = str.toUpperCase()
    return str === countryCodeUpperCase
  }
  return false
}

export function isPositiveInteger(str: string): boolean {
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}

export function randomString(length: number) {
    let result           = '';
    const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}