import {action} from "typesafe-actions";
import * as configActionTypes from './types';
import {ConfigMap} from "../../../types/models/config";

export function fetchAll() {
  return action(configActionTypes.FETCH_ALL, null)
}

export function setAll(configs: ConfigMap) {
  return action(configActionTypes.SET_ALL, configs)
}

export function setFetchAllError(message: string) {
  return action(configActionTypes.SET_FETCH_ALL_ERROR, message)
}