import {actionTypes} from '../actions'
import * as userActionFactories from '../actions/user/factories';
import {ActionType} from "typesafe-actions";
import {UserProfile} from "../../types/models/user";
import {AxiosError} from "axios";
import {ApiAuthErrorResponse} from "../../types/api/general";
const {SIGN_IN, TFA_STEP, SET_PROFILE, SIGN_OUT, SET_FETCH_ME_ERROR, SET_ME, SET_SIGN_IN_ASYNC_ERROR, TFA_VERIFY_ASYNC_ERROR, SET_UPDATE_PROFILE_ERROR} = actionTypes.user

type UserActionFactories = ActionType<typeof userActionFactories>;

interface UserState {
	signedIn: boolean;
	tfaPassed: boolean;
	profile: UserProfile | null;
	fetchMeError: string | null;
	profileFetched: boolean;
	signInAsyncError: AxiosError<ApiAuthErrorResponse> | Error | null;
	tfaVerifyAsyncError: AxiosError<ApiAuthErrorResponse> | Error | null;
	updateProfileError: AxiosError<ApiAuthErrorResponse> | Error | null;
}

const defaultState: UserState = {
	signedIn: false,
	tfaPassed: false,
	profile: null,
	fetchMeError: null,
	profileFetched: false,
	signInAsyncError: null,
	tfaVerifyAsyncError: null,
	updateProfileError: null
}

function userReducer(state: UserState = defaultState, action: UserActionFactories) {
	switch (action.type) {
		case SIGN_IN:
			return Object.assign({}, state, {
				signedIn: true,
			})
		case TFA_STEP:
			return Object.assign({}, state, {
				tfaPassed: true,
			})
		case SET_ME:
			return Object.assign({}, state, {
				profile: action.payload.profile,
				signedIn: action.payload.signedIn,
				tfaPassed: action.payload.tfaPassed,
				profileFetched: true
			})
		case SET_PROFILE:
			return Object.assign({}, state, {
				profile: action.payload
			})
		case SIGN_OUT:
			return Object.assign({}, defaultState)
		case SET_FETCH_ME_ERROR:
			return Object.assign({}, state, {
				fetchMeError: action.payload
			})
		case SET_SIGN_IN_ASYNC_ERROR:
			return Object.assign({}, state, {
				signInAsyncError: action.payload
			})
		case TFA_VERIFY_ASYNC_ERROR:
			return Object.assign({}, state, {
				tfaVerifyAsyncError: action.payload
			})
		case SET_UPDATE_PROFILE_ERROR:
			return Object.assign({}, state, {
				updateProfileError: action.payload
			})
		default:
			return state
	}
}

export default userReducer